import config from "./config.js";
import { Modal } from 'ant-design-vue';
import { createVNode } from 'vue';
import { QuestionCircleOutlined,SyncOutlined } from '@ant-design/icons-vue';
import md5 from "md5";
const baseFunc = {
	/* 状态判断data:示例
		data={
			code:11001,
			message:"请充值"
		}
	*/
	checkState(data,tip) {
		if(!tip){tip="提示"}
		return new Promise(function(resolve, reject) {
			switch (data.code) {
				case 11000:
					new LoginRegistration({
						info: {type: "login"},
						extraData: {},
						success: function(info, extraData) {
							/*登录成功后回调*/
							resolve(info);
							// console.log('确认',info, extraData)
						},
						cancel: function(info, extraData) {
							reject(info);
							/*取消或者关闭时回调*/
							// console.log('取消',info, extraData)
						}
					});
				break;
				case 11001:
					Modal.confirm({
						title: tip,
						content: data.message,
						icon: createVNode(QuestionCircleOutlined),
						cancelText:"取消",
						okText:"去充值",
						onOk() {
							window.open(config.upgradeUrl)
							reject("error")
						},
						onCancel(){reject("error")},
					});
				break;
				case 102:
					Modal.confirm({
						title: tip,
						content: data.message,
						icon: createVNode(QuestionCircleOutlined),
						cancelText:"取消",
						okText:"升级会员",
						onOk() {
							window.open(config.upgradeUrl)
							reject("error")
						},
						onCancel(){reject("error")},
					});
				break;
				case 101:
					Modal.confirm({
						title: tip,
						content: data.message,
						icon: createVNode(QuestionCircleOutlined),
						cancelText:"取消",
						okText:"升级会员",
						onOk() {
							window.open(config.upgradeUrl)
							reject("error")
						},
						onCancel(){reject("error")},
					});
				break;
				case 11900:
					Modal.warning({
						title: tip,
						content: data.message,
						cancelText:"取消",
						okText:"确认",
						onOk() {reject("error")},
						onCancel(){reject("error")},
					});
				break;
				case 11901:
					Modal.warning({
						title: tip,
						content: data.message,
						cancelText:"取消",
						okText:"确认",
						onOk() {reject("error")},
						onCancel(){reject("error")},
					});
				break;
				default:
					Modal.warning({
						title: tip,
						content: data.message,
						cancelText:"取消",
						okText:"确认",
						onOk() {reject("error")},
						onCancel(){reject("error")},
					});
				break;
			}
		})
	},
	/*
	 *七牛云文件加密
	 *@url：视频地址
	 *@frame：视频帧-传此参数则返回的加密地址为图片
	 */
	getQiniusign(url, frame) {
		// 对 URL 进行解析
		var parse = parseUrl(url);
		var query = [];
		if (typeof parse['query'] != 'undefined' && parse['query']) {
			for (let item in parse['query']) {
				if (item != 'sign' && item != 't') {
					if (parse['query'][item]) {
						query.push(item + "=" + parse['query'][item]);
					} else {
						query.push(item);
					}
				}
			}
		}
		var timestamp = Date.parse(new Date()) / 1000;
		// 设置有效时间为2个小时
		var time = timestamp + 1 * 60 * 60;
		if (typeof frame == 'number' || typeof frame == 'string') {
			/*获取视频封面图*/
			time = timestamp + 20*60;
			query.push("vframe/jpg/offset/" + frame);
		}
		var t = time.toString(16);
		// 按规定格式拼接字符串
		var jointStr = config.qiniuTimeKey + parse['path'] + t;
		// 进行 md5 加密并进行小写转换
		query.push("sign=" + md5(jointStr).toLowerCase());
		query.push("t=" + t);
		return "https:" + config.qiniuMediaDomain + parse['path'] + "?" + query.join('&');
	},
	getAliOssAuthKeyLocal(url, extra){
		var parse = parseUrl(url);
		if(!parse['host']){
			return url;
		}
		return this.getAliOssAuthKey(url, extra)
	},
	/*
	 *阿里云oss图片加密
	 *@url：图片地址
	 */
	getAliOssAuthKey(url, clearQuery) {
		// 对 URL 进行解析
		var parse = parseUrl(url);
		var query = [];
		if (typeof parse['query'] != 'undefined' && parse['query'] && !clearQuery) {
			for (let item in parse['query']) {
				if (item != 'auth_key') {
					if (parse['query'][item]) {
						query.push(item + "=" + parse['query'][item]);
					} else {
						query.push(item);
					}
				}
			}
		}
		var timestamp = Date.parse(new Date()) / 1000;
		// 设置有效时间为1分钟
		var time = timestamp + 10 * 60;
		// 按规定格式拼接字符串
		var keyS = parse['path'] + '-' + time + '-0-0-' + config.aliMainKey;
		// 进行 md5 加密并进行小写转换
		var auth_key = time + '-0-0-' + md5(keyS).toLowerCase();
		// 进行 md5 加密并进行小写转换
		query.push("auth_key=" + auth_key);
		return "https:" + config.aliOSS.outDomain + parse['path'] + "?" + query.join('&');
	},
	handleInt(val){
		if(val){
			return parseInt(val);
		}else{
			return val;
		}
	},
	/*获取时间-返回格式 2022/02/09/*/
	getDateString(format) {
		if (!format) format = '/';
		var zero = function(value) {
			if (value < 10) {
				return '0' + value;
			}
			return value;
		};
		var myDate = new Date();
		var year = myDate.getFullYear();
		var month = zero(myDate.getMonth() + 1);
		var day = zero(myDate.getDate());
		return year + format + month + format + day + format;
	},
	/*获取唯一uuid*/
	gen_uuid() {
		var s = [];
		var hexDigits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (var i = 0; i < 36; i++) {
			s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
		}
		s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
		s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
		s[8] = s[13] = s[18] = s[23] = "_";
		var uuid = s.join("");
		return uuid;
	},
	/*获取文件后缀名*/
	getType(filename, dot) {
		if (!filename) return "";
		if (dot) {
			return filename.substring(filename.lastIndexOf(".") + 1).toLowerCase();
		} else {
			return filename.substring(filename.lastIndexOf("."), filename.length).toLowerCase();
		}

	},
	/*获取随机数字*/
	genID(length) {
		return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
	},
	/*size-转b*/
	converSize(size) {
		size = parseInt(size);
		var result = "";
		if (size < 1024) { //如果小于0.1KB转化成B
			result = size.toFixed(2) + "B";
		} else if (size < 1024 * 1024) { //如果小于0.1MB转化成KB
			result = (size / 1024).toFixed(2) + "KB";
		} else if (size < 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB
			result = (size / (1024 * 1024)).toFixed(2) + "MB";
		} else { //其他转化成GB
			result = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
		}
		var sizestr = result + "";
		var len = sizestr.indexOf("\.");
		var dec = sizestr.substr(len + 1, 2);
		if (dec == "00") { //当小数点后为00时 去掉小数部分
			return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
		}
		return sizestr;
	},
	/*秒转时间 示例：120 -> 02:00
	 * */
	converNumberToTime(time) {
		time = parseInt(time);
		let minute = 0,
			second = 0;
		if (time <= 60) {
			return '00:' + plusOne(time);
		} else {
			minute = parseInt(time / 60);
			second = parseInt(time % 60);
			return plusOne(minute) + ":" + plusOne(second);
		}
	},
	/*判断是否是base64*/
	isBase64(str) {
		if(str){
			if(str.indexOf('data:')!=-1 && str.indexOf('base64')!=-1 ){
			    return true;
			}else{
			    return false;
			}
		}else{
			return false;
		}
	    
	},
	/*检测文件格式*/
	checkFileExt(ext, type) {
		let result = '';
		let arr_qf = ['xls', 'xlsx', 'docx', 'doc', 'ppt', 'pptx', 'pdf', 'gif', 'jpg', 'jpeg', 'png', 'swf', 'psd',
			'bmp', 'tiff', 'tiff', 'jpc', 'jp2', 'jpx', 'jb2', 'swc', 'iff', 'wbmp', 'count'
		];
		switch (type) {
			case "img":
				/*验证图片*/
				arr_qf = ['gif', 'jpg', 'jpeg', 'png', 'swf', 'psd', 'bmp', 'tiff', 'tiff', 'jpc', 'jp2', 'jpx',
					'jb2', 'swc', 'iff', 'wbmp', 'count'
				];
				break;
			case "file":
				/*验证文件*/
				arr_qf = ['xls', 'xlsx', 'docx', 'doc', 'ppt', 'pptx', 'pdf'];
				break;
			case "shengma":
				/*验证生码*/
				arr_qf = ['xls', 'xlsx', 'csv'];
				break;
			case "video":
				/*验证视频*/
				arr_qf = ['mp4', 'flv', 'avi', 'mov'];
				break;
			case "audio":
				/*验证音频*/
				arr_qf = ['mp3', 'm4a', 'wav', 'ogg', 'aiff', 'svcd', 'vcd'];
				break;
			case "media":
				/*验证媒体(音视频)*/
				arr_qf = ['mp4', 'flv', 'avi', 'mov', 'mp3', 'm4a', 'wav', 'ogg', 'aiff', 'svcd', 'vcd'];
				break;
		}
		if (!containsUYTHSKJ(arr_qf, ext)) {
			switch (type) {
				case "img":
					/*验证图片*/
					result = '不支持“' + ext + '”格式文件，请上传正确的图片'
					break;
				case "file":
					/*验证文件*/
					result = '不支持“' + ext + '”格式文件，请上传正确的文件'
					break;
				case "shengma":
					/*验证生码*/
					result = '不支持“' + ext + '”格式文件，请上传正确的文件'
					break;
				case "video":
					/*验证视频*/
					result = '不支持“' + ext + '”格式文件，请上传正确的视频'
					break;
				case "audio":
					/*验证音频*/
					result = '不支持“' + ext + '”格式文件，请上传正确的音频'
					break;
				case "media":
					/*验证媒体(音视频)*/
					result = '不支持“' + ext + '”格式文件，请上传正确的媒体文件'
					break;
				default:
					result = '不支持“' + ext + '”格式文件，请上传正确的图片或文件'
					break;
			}
		}
		return result;
	},

		
	/*获取文字数量*/
	wordLengthFunc(str){
		if(str){
			return str.length;
		}else{
			return 0;
		}
	},
	/*检测用户是否登陆*/
	checkUserLogin(that,status,tipStatus){
		if(!that)that=this;
		return new Promise(function(resolve, reject) {
			if(that && that.userinfo){
				resolve();
			}else{
				// that.$apiRequest.post('/user/getUserInfo', {
				that.$apiRequest.post('/auth/home_api/getUserInfo', {
				}).then(res => {
					if(status&&that)that.userinfo = res.data.user;
					resolve();
				}).catch(err => {
					if(err.data&&tipStatus)baseFunc.checkState(err.data).then(res=>{}).catch(err=>{})
					reject();
				})
			}
		})
	},
	checkQiniuUrlSame(newVal,oldVal){
		try{
			let newParse = parseUrl(newVal);
			let oldParse = parseUrl(oldVal);
			if(parse['path']!=oldParse['path']){
				return true;
			}else{
				return false;
			}
		}catch(e){
			return false;
		}
	},
	/*播放量转换*/
	playbackConversion(virtual,real){
		if(virtual){
			virtual = parseFloat(virtual);
			if(isNaN(virtual))virtual=0;
		}else{
			virtual = 0;
		}
		if(real){
			real = parseFloat(real);
			if(isNaN(real))real=0;
		}else{
			real = 0;
		}
	    let number = real+virtual;
	    if(number<10000){
	        return number;
	    }else if(number<10000*1000){
	        return Math.floor(number*100/10000) / 100+'w';
	    }else{
	        return Math.floor(number*100/10000000) / 100+'kw';
	    }
	},
	colorDarkSet(color,way){
		let brightness=0,
			saturability=0,
			hue=0;
		if(this.depthColorJudge(color)){
			//浅色
			brightness = 0.85;
			saturability = 0.85;
			hue = 1;
		}else{
			//深色
			brightness = 0.2;
			saturability = 1;
			hue = 0.98;
		}
		return this.getDarkLuminanceColor(color,brightness,saturability,hue);
	},
	/*亮度提深-颜色亮度饱和度调整*/
	getDarkLuminanceColor(color,brightness,saturability,hue) {
	    color = colorConversion(color);
	    var colorHVB = RGBtoHSV(color);
		if(!brightness)brightness=0;
		if(!saturability)saturability=0;
		if(!hue)hue=0;
		let colorHue = colorHVB.h;
		if(hue>0)colorHue = colorHue*hue;
		if(colorHVB.h==0 && colorHVB.s==0){
			/*黑白区间*/
			brightness = 0;
			if(colorHVB.v<0.5){
				saturability = colorHVB.v+0.4
			}else{
				saturability = colorHVB.v-0.4
			}
		}else{
			if(brightness<=0)brightness = colorHVB.s;
			if(saturability<=0)saturability = colorHVB.v;
		}
		var convert_color = HSVtoRGB(colorHue,brightness,saturability);
		return RgbToHex(convert_color.r, convert_color.g, convert_color.b);
	  },
	/** 判断颜色深浅*/
	depthColorJudge(color) {
	    let rgbValueArry = getColorData(color);
	    let grayLevel = rgbValueArry[0] * 0.299 + rgbValueArry[1] * 0.587 + rgbValueArry[2] * 0.114;
	    if (grayLevel >= 192) {//浅色
	        return true;
	    } else {//深色
	        return false;
	    }
	},
}

export default baseFunc;

/*共用方法*/
function parseUrl(url) {
	let urlObj = {
		protocol: /^(.+)\:\/\//,
		host: /\/\/(.+?)[\?\#\s\/]/,
		path: /\w(\/.*?)[\?\#\s]/,
		query: /\?(.+?)[\#\/\s]/,
		hash: /\#(\w+)\s$/
	};
	url += ' ';

	function formatQuery(str) {
		return str.split('&').reduce((a, b) => {
			let arr = b.split('=');
			a[arr[0]] = arr[1];
			return a
		}, {})
	}
	for (let key in urlObj) {
		let pattern = urlObj[key];
		urlObj[key] = key === 'query' ? (pattern.exec(url) && formatQuery(pattern.exec(url)[1])) : (pattern.exec(url) &&
			pattern.exec(url)[1])
	}
	return urlObj
}

function containsUYTHSKJ(arr_qf, obj) {
	for (let i = 0; i < arr_qf.length; i++) {
		if (arr_qf[i] === obj) {
			return true;
		}
	}
	return false;
}
/*数字补0*/
function plusOne(time) {
	if (time < 10) {
		return '0' + time;
	} else {
		return time;
	}
}
//将输入的颜色字符串转换成十进制数组
function getColorData(color) {
  var re = RegExp;
  if (/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i.test(color)) {
      //#rrggbb
      return [parseInt(re.$1,16),parseInt(re.$2,16),parseInt(re.$3,16)];
  } else if (/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i.test(color)) {
      //#rgb
      return [parseInt(re.$1+re.$1,16),parseInt(re.$2+re.$2,16),parseInt(re.$3+re.$3,16)];
  } else if (/^rgb(.*),(.*),(.*)$/i.test(color)) {
      //rgb(n,n,n) or rgb(n%,n%,n%)
      if(re.$1.indexOf("%")>-1){
          return [parseInt(parseFloat(re.$1.replace('(',''), 10) * 2.55),
              parseInt(parseFloat(re.$2, 10) * 2.55),
              parseInt(parseFloat(re.$3, 10) * 2.55)];
      }else{
          return [parseInt(re.$1.replace('(','')),parseInt(re.$2),parseInt(re.$3)];
      }
  }
}
 //GRB颜色转Hex颜色
function RgbToHex(a, b, c) {
    var r = /^\d{1,3}$/;
    if (!r.test(a) || !r.test(b) || !r.test(c)) return null;
    var hexs = [a.toString(16), b.toString(16), c.toString(16)];
    for (var i = 0; i < 3; i++) if (hexs[i].length == 1) hexs[i] = "0" + hexs[i];
    return "#" + hexs.join("");
}

/**颜色转换 */
function colorConversion(color){
	color = color.toLowerCase();
	var rgb = color.split(',');
	let r,g,b;
	if(color.indexOf("rgba") >= 0) {//判断是否是rgba
		r = parseInt(rgb[0].split('(')[1]);
		g = parseInt(rgb[1]);
		b = parseInt(rgb[2]);
	}else if(color.indexOf("rgb") >= 0){
		r = parseInt(rgb[0].split('(')[1]);
		g = parseInt(rgb[1]);
		b = parseInt(rgb[2].split(')')[0]);
	}else if(color.indexOf("#") >= 0){
	  let val = color.slice(1);
	  if (val.length == 3) {
		return '#' + val[0] + val[0] + val[1] + val[1] + val[2] + val[2];
	  } else if (val.length == 6) {
		return color;
	  }else{
		return '';
	  }
	}else{
		return '';
	}
	return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toLowerCase();
}
  
function RGBtoHSV(color) {
  var rgbc = getColorData(color);
  var r=rgbc[0],g=rgbc[1],b=rgbc[2];
  var max = Math.max(r, g, b), min = Math.min(r, g, b),d = max - min,h,s = (max === 0 ? 0 : d / max),v = max / 255;
  switch (max) {
	  case min: h = 0; break;
	  case r: h = (g - b) + d * (g < b ? 6: 0); h /= 6 * d; break;
	  case g: h = (b - r) + d * 2; h /= 6 * d; break;
	  case b: h = (r - g) + d * 4; h /= 6 * d; break;
  }
  return {h: h,s: s,v: v};
}
/* accepts parameters
* h  Object = {h:x, s:y, v:z}
* OR
* h, s, v
*/
function HSVtoRGB(h, s, v) {
  var r, g, b, i, f, p, q, t;
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
	  case 0: r = v, g = t, b = p; break;
	  case 1: r = q, g = v, b = p; break;
	  case 2: r = p, g = v, b = t; break;
	  case 3: r = p, g = q, b = v; break;
	  case 4: r = t, g = p, b = v; break;
	  case 5: r = v, g = p, b = q; break;
  }
  return {
	  r: Math.round(r * 255),
	  g: Math.round(g * 255),
	  b: Math.round(b * 255)
  };
}
//得到hex颜色值为color的加深颜色值，level为加深的程度，限0-1之间
function getDarkColor(color, level) {
	if(!level){
		level = 0.2;
	}
	var rgbc = getColorData(color);
	//floor 向下取整
	for (var i = 0; i < 3; i++) rgbc[i] = Math.floor(rgbc[i] * (1 - level));
	return RgbToHex(rgbc[0], rgbc[1], rgbc[2]);
}