<template>
	<div class="caseviewContainer">
		<div class="caseviewMain">
			<img class="phone" src="/img/top.png" alt="">
			<div class="caseinfo">
				<iframe height="657px" width="315px" :src="casedata.qrcode_url" frameborder="0"></iframe>
			</div>
			<img class="phonebottom" src="/img/bottom.png" alt="">
			<div class="qrcode">
				<div>
					<img :src="casedata.qrcode_img" alt="">
					<p>扫码预览</p>
				</div>
			</div>
		</div>
		<div @click="close" class="caseview_preview_close"><span>×</span></div>
	</div>
</template>

<script>
	export default{
		name: 'caseView',
		components: {
		},
		props:{
			casedata:Object
		},
		data(){
			return{
				
			}
		},
		methods:{
			close(){
				this.$emit('viewCaseClose',false)
			}
		}
	}
</script>

<style scoped>
	
	.qrcode p{
		color: #2e2e2e;
		font-size: 16px;
		line-height: 22px;
		margin-top: 10px;
		width: 100%;
		text-align: center;
		margin-bottom: 0;
	}
	.qrcode img{
		width: 152px;
		height: 152px;
	}
	.qrcode{
		width: 192px;
		height: 224px;
		background-color: #fff;
		position: absolute;
		left: -213px;
		top: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	.caseinfo{
		width:312px;
		height: 100%;
		margin: 0 auto;
		border: 2px solid #f1f1f1;
		border-radius: 40px;
		padding: 80px 0 0px;
		overflow: hidden;
	}
	.phone{
		left: 0;
		top: 0;
	}
	.phonebottom{
		left: 0;
		bottom: 0;
	}
	.phone,.phonebottom{
		width:100%;
		position: absolute;
	}
	.caseviewMain{
		width: 324px;
		height: 737px;
		background-color: white;
		position: relative;
		border-radius: 40px;
		margin:30px auto 0;
	}
	.close{
		color:#fff !important;
		font-size:48px;
		position: absolute;
		right: 60px;
		top: 45px;
		line-height:48px;
	}
	.caseviewContainer{
		height: 100vh;
		width: 100vw;
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, .7);
		z-index:900;
	}
	.caseview_preview_close{
		width: 100px;
		position: fixed;
		top: 20px;
		right: 20px;
		z-index: 99999;
		font-size: 48px;
		color: #fff;
		float: none;
		opacity: 1;
		font-weight: 200;
		display: flex;
		align-items: center;
		justify-content: center;
		text-shadow: 0 1px 0 #fff;
		cursor: pointer;
	}
</style>