<template>
	<a-config-provider :locale="lang">
		<a-modal v-model:visible="visible" width="1000px" title="活码应用" cancelText="" okText="确认" centered :destroyOnClose="true" :keyboard="false" :maskClosable="false" :footer="null">
			<div class="">
				<div class="tpl-use-search">
					<div class="tpl-use-search__l">
						<div>
							<span class="main-title-k1">活码</span><text class="sub-title-k2">(共{{lists.total}}个)</text>
						</div>
					</div>
					<div>
						<a-input-search
							v-model:value="filtrate.keyword"
							placeholder="输入标题搜索"
							enter-button
							allowClear
							@search="filtrate.onSearch"/>
					</div>
				</div>
				<div class="tpl-use-box">
					<div v-if="lists.load" class="tpl-use-box__load" style="background-color: rgba(203,203,203,.5)"><a-spin /></div>
					<div v-if="lists.total>0">
						<div class="tpl-use-box__item" v-for="(item,index) in lists.data" @click="previewInfo.open(item)">
							<div class="tpl-use-box__item__l">
								<div class="tpl-use-box__item__l__title">{{item.title}}</div>
								<div class="tpl-use-box__item__l__count"><span>{{item.updated_at}}</span><span class="tpl-use-box__item__l__count__static">今日扫描:{{item.today_statistic}}次, 昨日扫描:{{item.yesterday_statistic}}次</span></div>
							</div>
							<div class="tpl-use-box__item__r"><img :src="item.qrcode_path"/></div>
						</div>
					</div>
					<div v-else-if="!lists.load" class="tpl-use-box__load"><a-empty :image="simpleImage" /></div>
				</div>
				
				<div class="tpl-use-pagination">
					<a-pagination show-quick-jumper v-model:current="lists.current_page" :pageSize="lists.size" :total="lists.total" @change="filtrate.onChange" />
				</div>
				
			</div>
		</a-modal>
		
		<previewCode :info="previewInfo.info" ref="previewCodeChildRef"></previewCode>
	</a-config-provider>
</template>

<script>
	import zhCN from 'ant-design-vue/es/locale/zh_CN';
	import { Empty } from 'ant-design-vue';
	import previewCode from '/src/components/previewCode';
	export default {
			name:'tplUse',
			components: {
				previewCode
			},
			props:{
				tplId:Number,
				dateType:String,
			},
			created(){
			},
			mounted(){
			},
			data(){
				return {
					lang:zhCN,
					simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
					visible:false,
					previewInfo:{
						info:{},
						open:(info)=>{
							this.previewInfo.info={
								qrcode_path:info.qrcode_path,
								qrcode_url:info.qrcode_url,
							};
							this.$refs.previewCodeChildRef.open();
						}
					},
					filtrate:{
						keyword:'',
						onSearch:()=>{
							this.lists.getLists(1);
						},
						onChange:(page, pageSize)=>{
							this.lists.getLists(page);
						}
					},
					lists:{
						load:false,
						current_page:1,
						last_page:2,
						total:0,
						size:10,
						data:[],
						getLists:(page)=>{
							if(this.lists.load)return false;
							this.lists.load=true;
							this.$apiRequest.post("/template/getActiceCodeLists", {
								id:this.tplId,
								date_type:this.dateType,
								page:page,
								size:this.lists.size,
								keyword:this.filtrate.keyword,
							}).then(res => {
								this.lists.current_page = res.data.data.current_page;
								this.lists.total = res.data.data.total;
								this.lists.data = res.data.data.data;
								this.lists.load=false;
							}).catch(err => {
								this.lists.load=false;
							});
						}
					}
				};
			},
			methods:{
				open(){
					this.visible=true;
				},
			},
			watch:{
				tplId:{
				    handler(newVal,oldVal){
						if(newVal!=oldVal){
							this.lists.getLists(1);
						}
				    },
				    deep:true// 深度监听父组件传过来对象变化
				}
			}
		}
		
</script>

<style scoped lang="less">
	.tpl-use-search{
		width: 100%;
		padding: 0 8px;
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&__l{
			display: flex;
			align-items: flex-end;
			.main-title-k1{
				font-size: 18px;
				font-weight: 400;
				color: #000000;
				margin-right: 4px;
			}
			.sub-title-k2{
				font-size: 12px;
				color: #999999;
			}
		}
	}
	.tpl-use-box{
		width: calc(100% + 12px);
		position: relative;
		height: 540px;
		overflow-y: auto;
		padding-right: 12px;
		&__load{
			position: absolute;
			z-index: 2;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__item{
			width: 100%;
			border: 2px solid transparent;
			transition: all 0.2s;
			justify-content: space-between;
			display: flex;
			align-items: center;
			height: 70px;
			cursor: pointer;
			border-radius: 8px;
			padding:0 6px;
			margin-bottom: 16px;
			&__l{
				flex: 1;
				&__title{
					height: 20px;
					font-size: 14px;
					font-weight: 550;
					color: #333333;
					line-height: 1.2;
					max-width: 800px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				&__count{
					color: #999;
					font-size: 12px;
					margin-top: 6px;
					&__static{
						margin-left: 6px;
						&:hover{
							color: #09bd71;
						}
					}
				}
			}
			&__r{
				width: 60px;
				height: 60px;
				img{
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
			
			&:hover{
				border-color: #09bd71;
				.tpl-use-box__item__l__count__static{
					color: #ffad13;
				}
			}
		}
		
	}
	.tpl-use-pagination{
		width: 100%;
		text-align: right;
		margin-top: 16px;
		padding-right: 8px;
		height: 32px;
	}
</style>