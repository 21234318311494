<template>
	<a-config-provider :locale="lang">
		<div class="mbm_jay_comtentStyle">
			<div class="mbm_jay_contentFlex">
				<span class="mbm_jay_statis">扫码量统计</span>
			</div>
			<div class="mbm_jay_contentFlex mbm_jay_statisticsData">
				<div class="mbm_jay_marginStyle" v-for="(items,ids) in menu.titleMenu">
					<div class="mbm_jay_marginStyleDiv">
						<p class="mbm_jay_marginStyleDivFirstP">{{items.title}}</p>
						<p class="mbm_jay_marginStyleDivSecendP">
							<text v-if="items.number>=0">{{items.number}}</text>
							<a-spin v-else size="small" class="default-spin-color" />
						</p>
					</div>
				</div>
			</div>
			
			<div class="sweep-default-static-warp">
				<div class="sweep-default-static-title">指定日期统计：</div>
				<div class="sweep-default-static-time">
					<a-range-picker dropdownClassName="sweep-range-default-cur" format="YYYY/MM/DD" 
						v-model:value="defaultStaticInfo.time"
						:disabled-date="defaultStaticInfo.disabledDate" 
						:ranges="defaultStaticInfo.ranges"
						@change="defaultStaticInfo.onChange"/>
				</div>
				<div class="sweep-default-static-desc">
					<a-spin v-if="defaultStaticInfo.load" size="small" class="default-spin-color" />
					<span v-if="!defaultStaticInfo.load">扫码次数：<text>{{defaultStaticInfo.data.pv}}</text></span>
					<span v-if="!defaultStaticInfo.load">扫码人数：<text>{{defaultStaticInfo.data.uv}}</text></span>
				</div>
			</div>
			<div class="mbm_jay_dataComtentWarp">
				<div class="mbm_jay_dataComtentDiv">
					<div>
						<div class="mbm_jay_contentFlex">
							<span class="mbm_dataAlwes">日期范围：</span>
						</div>
					</div>
					<div v-for="(item,idx) in menu.list" class="mbm_jay_clickMenu">
						<div :class="idx==menu.index?'mbm_jay_active':''" class="mbm_jay_menu" @click="isActive(idx)">
							<span class="mbm_jay_sameSize" :style="idx==menu.index?'color:#09bd71':''">{{item.title}}</span>
						</div>
					</div>
					<div v-if="menu.index==4" class="mbm_jay_defult_time">
						<a-range-picker dropdownClassName="sweep-range-default-cur" format="YYYY/MM/DD" 
							v-model:value="defaultTimeInfo.time"
							:disabled-date="defaultTimeInfo.disabledDate" 
							:ranges="defaultTimeInfo.ranges"
							@change="defaultTimeInfo.onChange" 
						    @openChange="defaultTimeInfo.onOpenChange"
						    @calendarChange="defaultTimeInfo.onCalendarChange"/>
					</div>
					<div v-if="menu.index==4" class="mbm_jay_defult_time_tips">选择时间范围3个月</div>
				</div>
				<div id="myChart" class="mbm_jay_chart_warp">
					<div>
						<div class="mbm_jay_chart" ref="chart_2"></div>
						<div v-if="loading" class="mbm_jay_loading"><a-spin class="default-spin-color" size="large" /></div>
					</div>
				</div>
			</div>
		</div>
	</a-config-provider>

</template>

<script>
	import * as echarts from 'echarts';
	import moment from 'moment';
	import { message } from 'ant-design-vue';
	import zhCN from 'ant-design-vue/es/locale/zh_CN';
	export default {
		name: 'statisticModel',
		props: {
			info: Object,
		},
		data() {
			return {
				lang:zhCN,
				loading:true,
				chart: null,
				//扫码来源
				scenes:{
					scenesIndex:0,//默认选中
					list:[
						{
							id:0,
							title:'全部来源',
						}
					]
				},
				menu:{
					index:1,//默认选中
					titleMenu:[
						{
							title:'昨日',
							number:-1,
						},
						{
							title:'今日',
							number:-1,
						},
						{
							title:'近7日',
							number:-1,
						},
						{
							title:'近30日',
							number:-1,
						},
						{
							title:'累计扫描次数',
							number:-1,
						},
					],//扫码量统计
					list: [
						{
							title: "昨日",
							parameter: 'yesterday',//参数
						},
						{
							title: "今日",
							parameter: 'today',//参数
						},
						{
							title: "近7日",
							parameter: 'last7d',//参数
						},
						{
							title: "近30日",
							parameter: 'last30d',//参数
						},
						{
							title: "自定义",
							parameter: 'default',//参数
						}
					]
				},
				defaultStaticInfo:{
					time:'',
					timeData:'',
					load:false,
					ranges:{
						"本季度":  [moment().startOf('quarter'), moment()],
						"上季度":  [moment().quarter(moment().quarter() - 1).startOf('quarter'), moment().quarter(moment().quarter() - 1).endOf('quarter')],
						"近三个月": [moment().subtract(3, "M").startOf('day'), moment()],
						"近半年":  [moment().subtract(6, "M").startOf('day'), moment()],
						"今年": [moment().year(moment().year()).startOf('year'), moment()],
						"去年": [moment().subtract(1,'year').startOf('year'), moment().subtract(1,'year').endOf('year')],
					},/*预设日期*/
					data:{
						pv:"…",
						uv:"…",
					},
					disabledDate:(current)=>{
						return current && current > moment().endOf('day');
					},
					onChange:(date,dateString)=>{
						this.defaultStaticInfo.timeData = dateString.join("-");
						this.getSweepQrCodeTotal()
					},
				},
				defaultTimeInfo:{
					time:'',
					timeData:'',
					currentData:null,
					ranges:{
						"本周": [moment().startOf('week') .add(1, 'day'), moment()],
						"本月": [moment().startOf('month'), moment()],
						"上个月":  [moment().subtract(1,'month').startOf('month'), moment().subtract(1,'month').endOf('month')],
						"本季度":  [moment().startOf('quarter'), moment()],
						"上季度":  [moment().quarter(moment().quarter() - 1).startOf('quarter'), moment().quarter(moment().quarter() - 1).endOf('quarter')],
					},
					disabledDate:(current)=>{
						if (!this.defaultTimeInfo.currentData) return current > moment()
						return (current && current < moment(this.defaultTimeInfo.currentData).subtract(3, 'M').startOf('day')) || current > moment(this.defaultTimeInfo.currentData).add(3, 'M').endOf('day') || current > moment()
					},
					onOpenChange:()=>{
						/* 清空禁用时间段的设置 */
						this.defaultTimeInfo.currentData=null;
					},
					onCalendarChange:(dates)=>{
						// 获取手动选择的时间段起始值
						this.defaultTimeInfo.currentData = dates[0]
					},
					onChange:(date,dateString)=>{
						this.defaultTimeInfo.timeData = dateString.join("-");
						//初始化获取全局扫码量图表
						this.getDetailChartInfo();
					}
				},
			}
		},
		mounted() {
			// //初始化获取全局扫码量
			this.getStatisticsInfo();
			// //初始化获取全局扫码量图表
			this.getDetailChartInfo();
			const that = this
			window.addEventListener("resize", () => {
				try{
					if(that.chart_2)that.chart_2.resize();
				}catch (e) {}
				
			}); //让图表实现响应式布局
		},
		watch:{
			info:{
			    handler(newVal,oldVal){
					if(newVal!=oldVal){
						// //初始化获取全局扫码量
						this.getStatisticsInfo();
						// //初始化获取全局扫码量图表
						this.getDetailChartInfo();
					}
			    },
			    deep:true// 深度监听父组件传过来对象变化
			}
		},
		methods: {
			//获取指定时间段的pv+uv
			getSweepQrCodeTotal(){
				if(this.defaultStaticInfo.load)return false;
				this.defaultStaticInfo.load = true;
				this.$apiRequest.post("/api/sweepQuantity/getSweepQrCodeTotal", {
					id_key:this.info.id_key,
					default_time:this.defaultStaticInfo.timeData,
				}).then(res => {
					this.defaultStaticInfo.data = res.data.data
					this.defaultStaticInfo.load = false;
				}).catch(err => {
					this.defaultStaticInfo.load = false;
					if(typeof err.data !='undefined'){
						message.error(err.data.message);
					}
				});
			},
			//获取全局扫码量统计
			getStatisticsInfo() {
				const that = this
				var postStr='';
				that.scenes.list=[{id:0,title:'全部来源'}];
				this.$apiRequest.post("/api/sweepQuantity/getSweepQrCodeStatistics", {
					id_key:that.info.id_key,
				}).then(res => {
					that.menu.titleMenu=[
						{
							title:'昨日',
							number:res.data.data.yesterday,
						},
						{
							title:'今日',
							number:res.data.data.today,
						},
						{
							title:'近7日',
							number:res.data.data.sevendays,
						},
						{
							title:'近30日',
							number:res.data.data.thirtydays,
						},
						{
							title:'累计扫描次数',
							number:res.data.data.total,
						},
					];
				}).catch(err => {
				});
			},
			//获取全局扫码量图表
			getDetailChartInfo:function(){
				const that = this
				var postStr='';
				this.loading=true;
				this.$apiRequest.post("/api/sweepQuantity/getSweepQrCodeCharts", {
					id_key:that.info.id_key,
					type:this.menu.list[this.menu.index].parameter,
					default_time:this.defaultTimeInfo.timeData,
				}).then(res => {
					var p=0;
					var timeDatas=[],pvDatas=[],uvDatas=[];
					for(let i in res.data.data){
					  timeDatas[p]=i; //时间段
					  pvDatas[p]=res.data.data[i].pv //扫描量
					  uvDatas[p]=res.data.data[i].uv //扫描量
					  p++;
					}
					this.init(timeDatas,pvDatas,uvDatas);
					this.loading=false;
				}).catch(err => {
				});
			},
			//初始化折线图
			init(timeDatas,pvDatas,uvDatas) {
				var that =this;
				//2.初始化
				this.chart_2 = echarts.init(this.$refs.chart_2);
				//3.配置数据
				let option2 = {
					title: {
						text: "",
					},
					tooltip: {
						trigger: 'axis',
					},
					legend: {
						show: true,
						top: "8%",
						data: ["扫描人数", "扫描次数"],
					},
					grid: {
						left: "3%",
						right: "4%",
						bottom: "3%",
						containLabel: true,
					},
					// toolbox: {//保存图片
					// 	feature: {
					// 		saveAsImage: {},
					// 	},
					// },
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: timeDatas,
					},
					yAxis: {
						type: "value",
					},
					series: [
						{
							name: '扫描人数',
							type: 'line',
							stack: 'Total',
							itemStyle : {  
								normal : {  
									color:'#285af9',  
									lineStyle:{  
										color:'#285af9',
									}  
								},
							},
							data: uvDatas,
						},
						{
							name: '扫描次数',
							type: 'line',
							stack: 'Totals',
							itemStyle : {  
								normal : {  
									color:'#09bd71',  
									lineStyle:{  
										color:'#09bd71',
									}  
								},
							},
							data: pvDatas,
						},
					],
				};
				// 4.传入数据
				this.chart_2.setOption(option2);
			},
			//切换日期
			isActive: function(idx) {
				var that = this;	
				that.menu.index = idx;
				if(Number(idx) != 4){
					this.getDetailChartInfo();
				}
			}
		}
	}
</script>

<style>
	.default-spin-color .ant-spin-dot-item{background: #09bd71;}
	.sweep-range-default-cur .ant-tag{cursor: pointer;}
</style>
<style scoped>
	.sweep-default-static-warp{
		width: 100%;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		margin: 40px 0 20px;
		height: 44px;
	}
	.sweep-default-static-warp .sweep-default-static-time{
		padding: 0 10px;
		width: 230px;
	}
	.sweep-default-static-warp .sweep-default-static-title,.sweep-default-static-warp  .sweep-default-static-desc{
		display: flex;
		display: -webkit-flex;
		align-items: center;
		height: 100%;
	}
	.sweep-default-static-warp .sweep-default-static-title{
		font-size: 14px;
		color: #999999;
	}
	.sweep-default-static-desc span{margin-right: 10px;color: #333333;min-width: 150px;}
	.sweep-default-static-desc span text{color: #09bd71;font-weight: 500;}
	
	.mbm_jay_marginStyleDivFirstP{
		font-size: 14px;color: #999999;margin-bottom: 0;
	}
	.mbm_jay_marginStyleDivSecendP{
		font-size: 24px;color: #333333;margin-bottom: 0;
	}
	.mbm_jay_marginStyle{
		flex: 1;
		padding-left: 20px;
		border-left: 1px solid #e7e7e7;
		box-sizing: border-box;
		display: flex;
		justify-content: flex-start;
	}
	.mbm_jay_statisticsData .mbm_jay_marginStyle:first-child{
		padding-left: 0;
		border-left: 0;
	}
	.mbm_jay_statis{
		margin-bottom: 35px;color: #000000;font-size: 18px;font-weight: bold;
	}
	.mbm_jay_contentFlex{
		display: flex;
	}
	.mbm_jay_dataComtentDiv{
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
	}
	.mbm_jay_dataComtentDiv>div{margin-bottom: 10px;}
	.mbm_dataAlwes{
		margin-top: 5px;font-size: 14px;color: #999999;
	}
	.mbm_jay_dropdown{
		padding-left: 5px;
	}
	.mbm_jay_scanningDivSpan{
		font-size: 14px;color: #999999;margin-top: 5px;
	}
	.mbm_jay_defult_time{
		padding: 0 10px;
		width: 230px;
		box-sizing: border-box;
	}
	.mbm_jay_defult_time_tips{
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		color: #999999;
	}
	.mbm_jay_scanning{
		display: flex;
	}
	.mbm_jay_scanningDiv{
		display: flex;
	}
	.mbm_jay_selectStyle{
		border-radius: 4px!important;
		display: flex!important;
		width: 110px;
	}
	.mbm_jay_selectStyle_span{
		color: #333333;margin-left: -4px;
	}
	.mbm_jay_selectStyle_outl{
		margin-left: 6px;
	}
	.mbm_jay_chart{
		width: 100%; height: 300px;
	}
	.mbm_jay_chart_warp{
		position: relative;
	}
	.mbm_jay_comtentStyle {
		-webkit-touch-callout: auto;
		-webkit-user-select: auto;
		-khtml-user-select: auto;
		-moz-user-select: auto;
		-ms-user-select: auto;
		user-select: auto;
	}

	.mbm_jay_active {
		border-radius: 5px;
		border: 1px solid #09bd71 !important;
	}

	.mbm_jay_menu {
		border-radius: 5px;
		display: flex;
		cursor: pointer;
		border: 1px solid rgba(0, 0, 0, 0);
	}

	.ant-table-thead>tr>th {
		background: #f5f5f5 !important;
		padding: 5px 16px !important;
	}

	.ant-table-tbody>tr>td {
		padding: 10px 16px !important;
	}

	.mbm_jay_sameSize {
		margin: auto;
		font-size: 14px;
		color: #333333;
		padding: 4px 12px;
	}
	.mbm_jay_sameSize:hover{
		color: #09bd71;
	}
	.mbm_jay_loading {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(255,255,255,0.5);
		z-index: 10;
		left: 0;
		top: 0;
	}
</style>
