<template>
	<div class="mbm_wenjian_item mbm_defult_style_ji81" :class="[firm_type!=1?'ww_floader_warp_j2hys':'']"
	 v-for="(item,index) in floader_data" :key="item.id">
		<div class="mbm_wenjian_item_parent mbm_user_select_none"
			:class="{'mbm_wenjian_item_active':((currentId==item.id))}" :data-id="item.id"
			:data-index="item.index" @click="selectChlid(item.child?item.child.length:0,item,$event)">
			<span v-show="item.child && item.child.length>0" class="mbm-iconfont mbm-daosanjiao "
				:class="{'mbm_childOpen':item.openStatus,'mbm_childClose':item.openStatus==false}" :data-id="item.id"
				:data-index="item.index"
				@click.stop="openCloseChlid(item.child?item.child.length:0,item,$event)"></span>

			<div v-if="!((currentId==item.id)&renameShow)"
				style="position: relative;width: 100%;height: 100%;">
				<div
					style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;display: flex;align-items: center;justify-content: space-between;">
					<div class="mbm_wenjian_item_left">
						<span class="mbm-iconfont"
							:class="{'mbm-muludakai':item.openStatus,'mbm-muluguanbi':item.openStatus==false,}"></span>
						{{item.title}}
					</div>

					<span class="mbm_child_count">{{item.total}}</span>
					<!-- <span class="mbm_child_count" v-else>0</span> -->

					<a-popover v-if="firm_type==1" overlayClassName="mbm_defult_style_ji81" placement="bottom"
						:visible="item.id==selectItem.id&&antPopoverShow" title=""
						trigger="click">
						<template #content style="padding: 0;">
							<!-- <a @click="hideAntPopover">Close</a> -->
							<div @click="hideAntPopover" class="mbm_handle_box_content_list"
								style="width: 108px;position: unset;">
								<div v-if="item.index <= maxLevel" @click="newChild($event,1)">
									新建子目录
								</div>
								<!-- <div v-if="floaderType==1&&(selectItem.id!=0|selectItem.index!=1)"
									:data-id="selectItem.id" :data-index="selectItem.index"
									@click="glQRcode($event,selectItem)">
									关联二维码
								</div> -->
								<div v-if="selectItem.id!=0|selectItem.index!=1" :data-id="selectItem.id"
									:data-index="selectItem.index" @click="openRenameChild($event,selectItem)">
									重命名
								</div>
								<div v-if="selectItem.id!=0|selectItem.index!=1" :data-id="selectItem.id"
									:data-index="selectItem.index" @click="openRemoveModel($event,parentItem,item)">
									移动
								</div>
								<div v-if="selectItem.id!=0|selectItem.index!=1" :data-id="selectItem.id"
									:data-index="selectItem.index" @click="openDeleteModel($event,selectItem.title)">
									删除
								</div>
							</div>
						</template>
						<a-button v-if="topChange==1 && firm_type==1" v-show="item.id==selectItem.id&&antPopoverShow"
							class="ant_open_handle_box" type="default" @click.stop='openAntPopover($event,item)'>
							<span class="mbm-iconfont mbm-sangediandian" style="font-size: 12px;"></span>
						</a-button>
					</a-popover>

					<!-- <div class="mbm_open_handle_box" @click.stop="openCloseHandle($event,item)">
						<span class="mbm-iconfont mbm-sangediandian" style="font-size: 12px;"></span>
					</div> -->


				</div>


			</div>
			<!-- 重命名input -->

			<input v-focus v-if="(currentId==item.id)&renameShow" class="mbm_reName_input"
				@click.stop="" ref="renameInput" :value="item.title" @blur="renameChild($event,item)" />


		</div>

		<transition v-if="item.child" name="panel-fade" @before-enter="beforeEnter" @enter="enter($event,item.child.length)"
			@after-enter="afterEnter" @before-leave="beforeLeave($event,item.child.length)" @leave="leave"
			@after-leave="afterLeave">
			<div class="collapse-wrap" v-show="item.openStatus" style="padding-left: 20px;" transition="panel-fade">
				<floader :floader_data="item.child" :maxLevel="maxLevel" :floaderType="floaderType" :allData="allData" :currentId="currentId"
					:currentIndex="currentIndex" :parentItem="item" :selectItem="selectItem"
					:topChange="parseInt(topChange)" :firm_type="firm_type"></floader>
			</div>
		</transition>

	</div>

	<!-- 移动model -->
	<a-modal class="mbm_defult_style_ji81" :title="'将当前文件夹移动至“'+removeCurrentName+'”'" v-model:visible="removeModelShow"
		:confirm-loading="confirmLoading" :ok="removeCatalogue" :footer="null" :width="600">
		<div class="mbm_move_model_center_body">
			<div>
				<removeFloader :maxLevel="maxLevel" :remove_floader_data="allData" :currentId="parseInt(removeCurrentId)"
					:currentIndex="parseInt(removeCurrentIndex)" :noSelectList="noSelectList" :parentItem="{}">
				</removeFloader>
			</div>
		</div>
		<div class="mbm_move_model_center_footer">
			<div class="mbm_move_model_center_footer_left">
				<a-button class="mbm_move_model_center_footer_btn" @click.stop="newChild($event,2)"><span
						class="mbm-iconfont mbm-jia" style="font-size: 14px;"></span><span>新建目录</span></a-button>
			</div>
			<div class="mbm_move_model_center_footer_right">
				<a-button class="mbm_move_model_center_footer_btn" @click.stop="closeRemoveModel">取消</a-button>
				<a-button type="primary" :loading="confirmLoading"
					class="mbm_move_model_center_footer_btn mbm_move_define" @click.stop="removeCatalogue">确定</a-button>
			</div>
		</div>
	</a-modal>

	<loading :loadingShow="loadingShow" :type="4" title="数据加载中..." style="position: absolute;"></loading>


</template>

<script>
	import {
		Empty,
		Modal
	} from 'ant-design-vue';
	import floader from '/src/components/floader.vue';
	// import delete_model from '/src/components/deleteModel.vue';
	// import remove_model from '/src/components/removeModel.vue';
	import removeFloader from '/src/components/removeFloader.vue';
	import loading from '/src/components/loading.vue';
	import bus from '/src/assets/js/bus.js';

	import openCloseAnim from '/src/assets/js/openCloseAnim.js';

	export default {
		name: 'floader',
		components: {
			floader,
			// delete_model,
			// remove_model,
			removeFloader,
			loading,
			openCloseAnim
		},
		data() {
			return {
				antPopoverShow: false,
				confirmLoading: false,
				visible: false,

				selectItemToTop: 0,
				selectItemToLeft: 186,
				childShow: false, //文件夹的子目录显隐
				handleShow: false, //三个点操作box显隐
				handleIndex: 0,
				/*当前操作层级*/
				handleId: 0,
				/*当前操作id*/
				noSelectList: [],
				renameShow: false, //重命名input显隐
				deleteModelShow: false, //删除弹窗的显隐
				deleteName: '', //删除文件夹名字的显隐

				removeModelShow: false,

				// 移动弹窗ID
				removeCurrentId: 0,
				removeCurrentIndex: 1,
				removeCurrentName: '全部活码',

				elTransition: '0.3s height ease-in-out, 0.3s padding-top ease-in-out, 0.3s padding-bottom ease-in-out',
			}
		},
		props: {
			maxLevel:{
				type:Number,
				default:5,
			},
			firm_type:Number,
			floaderType: Number,
			allData: Object,
			floader_data: Object,
			currentId: Number,
			currentIndex: Number,
			parentItem: Object,
			loadingShow: Boolean,
			selectItem: Object,
			topChange: Number,
		},
		created() {
			bus.$on('openRenameChild', e => {

				let item = e.item;
				this.popoverSelect(item);

				if (item.pid == this.parentItem.id) {

					this.openRenameChild({}, item);

				}
			});

			//监听当前选中的文件夹
			bus.$on('removeCatalogueChange', e => {
				this.removeCurrentId = parseInt(e.id);
				this.removeCurrentIndex = parseInt(e.index);
				this.removeCurrentName = e.name;
			});
		},
		methods: {
			//transition展开收起动画-start
			beforeEnter(el) {
				el.style.transition = this.elTransition
				if (!el.dataset) el.dataset = {}

				el.dataset.oldPaddingTop = el.style.paddingTop
				el.dataset.oldPaddingBottom = el.style.paddingBottom

				el.style.height = 0
				el.style.paddingTop = 0
				el.style.paddingBottom = 0
			},
			enter(el, length) {
				el.dataset.oldOverflow = el.style.overflow

				if (el.scrollHeight !== 0) {
					el.style.height = length * 36 + 'px'
					el.style.paddingTop = el.dataset.oldPaddingTop
					el.style.paddingBottom = el.dataset.oldPaddingBottom
				} else {
					el.style.height = ''
					el.style.paddingTop = el.dataset.oldPaddingTop
					el.style.paddingBottom = el.dataset.oldPaddingBottom
				}

				el.style.overflow = 'hidden'
			},
			afterEnter(el) {
				el.style.transition = ''
				el.style.height = ''
				el.style.overflow = el.dataset.oldOverflow
			},
			beforeLeave(el, length) {
				if (!el.dataset) el.dataset = {}
				el.dataset.oldPaddingTop = el.style.paddingTop
				el.dataset.oldPaddingBottom = el.style.paddingBottom
				el.dataset.oldOverflow = el.style.overflow
				el.style.height = length * 36 + 'px'
				el.style.overflow = 'hidden'
			},
			leave(el, done) {

				if (el.scrollHeight !== 0) {
					el.style.transition = this.elTransition;
					el.style.height = 0;
					el.style.paddingTop = 0;
					el.style.paddingBottom = 0;
				}
			},
			afterLeave(el) {
				el.style.transition = ''
				el.style.height = ''
				el.style.overflow = el.dataset.oldOverflow
				el.style.paddingTop = el.dataset.oldPaddingTop
				el.style.paddingBottom = el.dataset.oldPaddingBottom
			},
			//transition展开收起动画-end
			//气泡框选择的文件夹
			popoverSelect(item) {
				bus.$emit("popoverSelect", {
					"item": item,
				})
			},
			//隐藏Ant气泡框
			hideAntPopover() {
				this.antPopoverShow = false;
			},
			//显示Ant气泡框
			openAntPopover(e, item) {

				let arr = Object.keys(this.selectItem);
				let that = this;

				if (item.id == this.selectItem.id && item.index == this.selectItem.index && this.antPopoverShow == true) {
					this.hideAntPopover();
				} else {
					this.popoverSelect(item);
					this.antPopoverShow = true;

					function emojiDisShow() {
						//改变数据 重要的是在body上绑定事件, 让数据变成false, 最重要的是阻止点击emoji标签时候禁止冒泡到body上,所以用stop,  
						that.hideAntPopover();
						document.body.removeEventListener('click', emojiDisShow) //消失后,为了性能,取消body这个事件就可以了
					}
					document.body.addEventListener('click', emojiDisShow);
				}


			},
			//目录切换
			catalogueChange(index, id, item) {
				if (this.currentId != id) {
					bus.$emit('catalogueChange', {
						'index': index,
						'id': id,
						'title': item.title,
						'child': item.child,
					});
				}

			},
			//打开关闭文件夹
			openCloseChlid(length, item, e) {
				try {
					let index = e.currentTarget.dataset.index;
					let id = e.currentTarget.dataset.id;
					if (length && length != 0) {
						if (item.openStatus) {
							item.openStatus = false;
						} else {
							item.openStatus = true;
						}
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			//选中文件夹
			selectChlid(length, item, e) {
				try {
					let index = e.currentTarget.dataset.index;
					let id = e.currentTarget.dataset.id;
					item.openStatus = true;
					this.catalogueChange(index, id, item); //选中目录改变
				} catch (e) {
					//TODO handle the exception
				}
			},
			// 打开关闭目录操作框
			openCloseHandle(e, item) {
				var that = this;
				this.handleId = item.id;
				this.handleIndex = item.index;
				this.selectItem = item;
				if (this.handleShow) {
					this.handleShow = false;

				} else {
					this.handleShow = true;

					this.selectItemToTop = e.y + 20; //计算当前点击距离顶部的位置



					function emojiDisShow() {
						//改变数据 重要的是在body上绑定事件, 让数据变成false, 最重要的是阻止点击emoji标签时候禁止冒泡到body上,所以用stop,  
						that.handleShow = false;
						document.body.removeEventListener('click', emojiDisShow) //消失后,为了性能,取消body这个事件就可以了
					}
					document.body.addEventListener('click', emojiDisShow);
				}
			},
			//气泡框新建子目录(1:气泡框；2：移动弹窗)
			newChild(e, type) {

				let index = 1;
				let id = 0;
				if (type == 1) {
					index = this.selectItem.index;
					id = this.selectItem.id;
				} else {
					index = this.removeCurrentIndex;
					id = this.removeCurrentId;
				}

				bus.$emit('newChild', {
					'id': id,
					'index': index,
					'type': type,
				})

			},
			//关联二维码
			glQRcode(e, item) {
				bus.$emit('glqrcode', item);
				this.handleShow = false;
			},
			//打开重命名
			openRenameChild(e, item) {

				let that = this;
				let index = item.index;
				let id = item.id;
				this.catalogueChange(index, id, item); //选中目录改变
				this.handleShow = false;

				that.renameShow = true;

			},
			//重命名
			renameChild(e, item) {
				this.renameShow = false;
				let value = e.target.value;
				let id = this.currentId;
				let index = this.currentIndex;
				if (item.title != value) {
					item.title = value;
					bus.$emit('rename', {
						'id': id,
						'index': index,
						'value': value,
						'dom': this,
					})
				}

			},
			//打开移动目录
			openRemoveModel(e, parentItem, item) {

				if (item.id == this.selectItem.id && item.index == this.selectItem.index) {
					this.removeModelShow = true;
				}

				this.handleIndex = item.index;
				this.handleId = item.id;
				// 初始化移动目标文件夹
				this.removeCurrentId = 0;
				this.removeCurrentIndex = 1;
				this.removeCurrentName = '全部活码';
				//组合无法移动文件夹列表
				this.noSelectList = [];
				this.getNoSelectList(parentItem, item);

			},
			getNoSelectList(parentItem, item) {
				//组合无法移动文件夹列表
				//添加父元素
				if (parentItem.index != 1) {
					this.noSelectList.push({
						id: parseInt(parentItem.id),
						index: parseInt(parentItem.index)
					})
				}
				//添加当前元素
				this.noSelectList.push({
					id: parseInt(this.handleId),
					index: parseInt(this.handleIndex)
				})

				//添加当前元素的子元素
				this.getChildInfo(item);
			},
			//获取当前文件下所有的子文件夹
			getChildInfo(item) {
				if (item !== undefined && item !== null) {

					if (item.child !== undefined && item.child !== null) {

						for (let i = 0; i < item.child.length; i++) {
							let child = item.child[i];
							this.noSelectList.push({
								id: child.id,
								index: child.index
							});
							this.getChildInfo(child);
						};

					}
				}
			},
			//打开删除目录model
			openDeleteModel(e, name) {
				// this.deleteModelShow = true;
				let that = this;
				this.handleIndex = e.currentTarget.dataset.index;
				this.handleId = e.currentTarget.dataset.id;
				this.deleteName = name;

				Modal.confirm({
					title: '确认删除该文件夹吗？',
					content: h => < div style = "color:red;" > 一旦删除,
					文件夹下的所有文件都将一起删除,
					请谨慎操作! < /div>,
					okText: '确认',
					okType: 'danger',
					cancelText: '取消',
					icon: h => < span class = "mbm-anticon mbm-iconfont mbm-wenhao-xianxingyuankuang" > < /span>,
					onOk() {
						bus.$emit('deleteCatalogue', {
							'id': that.handleId,
							'type': 1,
						});
					},
					onCancel() {

					},
				});
			},
			//关闭移动目录model
			closeRemoveModel() {
				this.removeModelShow = false;

			},

			//移动文件夹
			removeCatalogue() {
				let that = this;
				let currentIndex = this.handleIndex;
				let currentId = this.handleId;

				let toIndex = this.removeCurrentIndex;
				let pid = this.removeCurrentId;

				this.confirmLoading = true;
				setTimeout(function() {
					that.removeModelShow = false;
					that.confirmLoading = false;
				}, 1000)

				bus.$emit('moveCatalogue', {
					'id': currentId,
					'pid': pid,
				})
			}


		}
	}
</script>

<style>
	.sub-comments-leave-active,
	.sub-comments-enter-active {
		transition: max-height 0.3s;
	}

	.sub-comments-enter,
	.sub-comments-leave-to {
		max-height: 0;
	}

	.sub-comments-enter-to,
	.sub-comments-leave {
		max-height: 4rem;
	}


	.mbm_defult_style_ji81 .ant-modal-confirm-body .ant-modal-confirm-title {
		display: inline;
	}

	.mbm_defult_style_ji81 .ant_open_handle_box {
		width: 20px !important;
		height: 20px !important;
		padding: 0 !important;
		transform: rotate(90deg);
		display: none !important;
		align-items: center;
		justify-content: center;
		background: unset !important;
		border: unset !important;
	}

	.mbm_defult_style_ji81 .ant_open_handle_box:hover {
		background: #E7E7E7 !important;
	}

	.mbm_defult_style_ji81 .ant-popover-inner-content {
		padding: 0;
	}

	.mbm_wenjian_item_active:hover {
		color: #09bd71 !important;
	}

	.mbm_wenjian_item_parent:hover .ant_open_handle_box {
		display: flex !important;
	}

	.mbm_wenjian_item_active .ant_open_handle_box {
		display: flex !important;
	}

	.mbm_wenjian_item_parent {
		padding: 0 16px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 14px;
		color: #333333;
		height: 36px;
		cursor: pointer;
		border-radius: 2px;
		min-width: 104px;
	}

	.mbm_wenjian_item_parent:hover {
		color: #09bd71;
	}

	.mbm_wenjian_item_parent:hover .mbm_child_count {
		display: none;
	}

	/* 	.mbm_wenjian_item_parent:hover .mbm_open_handle_box {
		display: flex;
	} */

	.mbm_wenjian_item_parent>span {
		z-index: 2;
	}

	.mbm_wenjian_item_parent>div {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.mbm_wenjian_item_left {
		box-sizing: border-box;
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		-ms-flex: 1 1;
		flex: 1 1;
		-webkit-line-clamp: 1;
		font-size: 14px;
		margin-right: 16px;
		z-index: 2;
	}

	.mbm_wenjian_item_parent>span {
		margin-right: 4px;

	}

	.mbm_childClose {
		transform: rotate(270deg);
	}

	.mbm_childOpen {
		transform: rotate(0deg);
	}

	.mbm_wenjian_item_parent>span:nth-child(1) {
		font-size: 10px;
		color: #333333;
	}

	.mbm_wenjian_item_left>span:nth-child(1) {
		font-size: 10px;
		color: #09bd71;
	}

	.mbm_wenjian_item_left>div {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.mbm_wenjian_item_active {
		background: #d0f7e6;
		color: #09bd71;
		position: relative;
	}

	.mbm_wenjian_item_active .mbm_child_count {
		display: none;
	}
	.ww_floader_warp_j2hys .mbm_child_count {
		display: block !important;
	}

	/* .mbm_wenjian_item_active .mbm_open_handle_box {
		display: flex;
	} */

	/* .mbm_wenjian_item_active::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 36px;
		top: 0;
		left: -80px;
		background-color: #EBEFFF;
		z-index: 1;
	} */

	/* .mbm_wenjian_item_active::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 36px;
		top: 0;
		right: -4px;
		background-color: #EBEFFF;
		z-index: 1;
	} */

	.mbm_open_handle_box {
		height: 20px;
		width: 20px;
		border-radius: 2px;
		transform: rotate(90deg);
		display: none;
		align-items: center;
		justify-content: center;

		position: relative;
	}

	.mbm_open_handle_box:hover {
		background: #E7E7E7;
	}

	.mbm-sangediandian {
		z-index: 1;
	}

	/* 气泡框 */
	.mbm_handle_box {
		position: fixed;
		bottom: -60px;
		left: 36px;
		width: 108px;
		min-height: 130px;
		padding-top: 10px;
		box-sizing: border-box;
		z-index: 1000;
	}

	.mbm_handle_box_content {
		width: 100%;
		height: 100%;
		position: relative;
		box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
	}

	.mbm_single_top {
		position: absolute;
		left: calc(50% - 4px);
		top: -4px;
		-ms-transform: translateX(-50%) rotate(45deg);
		transform: translateX(-50%) rotate(45deg);
		/* 	box-shadow: -2px -2px 5px rgba(0, 0, 0, .06); */
		background: #fff;
		width: 8.48528137px;
		height: 8.48528137px;
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		display: block;
		border-color: transparent;
		border-style: solid;
	}

	.mbm_handle_box_content_list {
		width: 100%;
		background-color: #fff;
		background-clip: padding-box;
		border-radius: 4px;

		font-family: Microsoft YaHei UI, Microsoft YaHei, PingFang SC, Helvetica Neue, Hiragino Sans GB, Noto Sans, Tahoma, Arial, simsun, "sans-serif";
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5;
		color: rgba(0, 0, 0, .85);
		box-sizing: border-box;
		margin: 0;
		padding: 4px 0;
		list-style: none;
		cursor: auto;
		-webkit-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
		white-space: normal;
		font-weight: 400;
		text-align: left;
		position: absolute;
		top: 0;
		left: 0;
	}

	.mbm_divider_box {
		padding: 0 24px !important;
	}

	.mbm_handle_box_content_list>div {
		padding: 14px 24px;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: #585858;
		line-height: 1;
	}

	.mbm_handle_box_content_list>div:hover {
		background: #d0f7e6;
		cursor: pointer;
	}



	/* 气泡框 */

	.mbm_reName_input {
		width: 100%;
		height: 24px;
		padding: 0 6px;
		border: 1px solid #09bd71;
		border-radius: 4px;
		outline: none;
		flex: 1;
		z-index: 2000;
	}
</style>
