<template>
	<div class="bill_info_box">
		<div class="bill_info_box_top">
			<div class="bill_check_box">
				<div v-if="!isModel" class="bill_select_item">
					<span>审核状态</span>
					<a-select class="bill_select" v-model:value="checkStatusValue" label-in-value style="width: 120px"
						:options="checkStatusOptions" @change="checkStatusHandleChange">
					</a-select>
				</div>
				<!-- <div class="bill_select_item">
					<span>发票类型</span>
					<a-select class="bill_select" v-model:value="billTypeValue" label-in-value style="width: 176px;"
						:options="billTypeOptions" @change="billTypeHandleChange">
					</a-select>
				</div> -->
			</div>
			<div class="bill_btn_box">

				<a-input-search class="bill_search_input" size="default" v-model:value="searchValue"
					placeholder="搜索发票抬头" @search="onSearch" />
				<a-button class="bill_btn" style="margin-left: 20px;" type="primary" @click="openCreateModal(1)">添加开票信息
				</a-button>
			</div>
		</div>


		<div v-if="isChecked==2&&!isModel" class="bill_ischecked_box" style="margin-bottom:10px;">
			<div>
				已选中{{this.selectedRowKeys.length}}条数据
			</div>
			<div>
				<a-button type="primary" class="mbm_check_btn" style="background: #304596;"
					@click="openCheckModel('more')">不通过</a-button><!-- checkInfo('check',2) -->
				<a-button type="primary" class="mbm_check_btn" style="background: #09bd71;"
					@click="openCheckModel('more')">通过</a-button><!-- checkInfo('check',1) -->
			</div>

		</div>

		<div class="sub_table_box"
			:style="isChecked==2?'height: calc(100% - 32px - 10px - 32px - 10px);':'height: calc(100% - 32px - 10px);'">
			<a-config-provider :locale="configProvider.lang">
				<template #renderEmpty>
					<a-empty :description="'空空如也'" :image="configProvider.emptyImage" />
					<!-- <a-button v-if="!filterData.keywords" type="primary">添加域名</a-button> -->
				</template>
				<a-table class="bill_mytable" :scroll="{x:600,y:'calc(100% - 55px)'}"
					:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange ,type:isModel?'radio':'checkbox',}"
					:rowKey="(record,index)=>{return record.id}" :pagination="pagination" :data-source="tableData"
					@change="tableChange" :loading="tableLoading">

					<a-table-column v-if="isChecked==2" key="uid" title="UID" data-index="uid">
						<template #default="{text:uid}">
							{{uid}}
						</template>
					</a-table-column>

					<a-table-column key="invoice_header" title="发票信息" data-index="invoice_header">
						<template #default="{text:invoice_header,record:record}">
							<span style="display: flex;align-items: center;">
								{{ "发票抬头："+record.invoice_header }}
							</span>
							<span style="display: flex;align-items: center;">
								{{ "发票类型："+record.invoice_type }}
							</span>
							<span style="display: flex;align-items: center;">
								{{ "公司纳税凭证类型："+record.voucher_type }}
							</span>
							<span style="display: flex;align-items: center;">
								{{ "公司纳税凭证号："+record.voucher_code }}
							</span>
						</template>
					</a-table-column>


					<a-table-column key="invoice_type" title="发票类型" data-index="invoice_type">
						<template #default="{text:invoice_type,record:record}">
							<span style="display: flex;align-items: center;">
								{{invoice_type}}
							</span>
						</template>
					</a-table-column>

					<a-table-column key="status" data-index="status" title="状态">
						<template #default="{text:status,record:record}">
							<span :style="status == '审核未通过'?'color:red;':'color:green;'">
								{{status}}
								<a-tooltip v-if="status=='审核未通过'" placement="top" :title="record.status_msg">
									<span class="mbm-iconfont mbm-wenhao bill_fail_icon"></span>
								</a-tooltip>
							</span>
						</template>
					</a-table-column>

					<a-table-column key="default_information" data-index="default_information" title="设为默认开票信息">
						<template #default="{text:default_information,record:record}">
							<a-button class="bill_set_moren_btn" shape="round" @click="setMorenBillInfo(record)"
								:disabled="default_information == 1?true:false">
								{{default_information == 1?'已设为默认开票信息':'设为默认开票信息'}}
							</a-button>
						</template>
					</a-table-column>

					<a-table-column key="action" title="操作">
						<template #default="{ record }">
							<span>
								<span class="sub_operation_btn mbm-iconfont mbm-bianji"
									@click="openCreateModal(2,record)"></span>
								<a-divider type="vertical" />
								<!-- 	<span class="sub_operation_btn" @click="toDetail(record)">变更</span> -->
								<span class="sub_operation_btn mbm-iconfont mbm-ashbin"
									@click="deleteMember(record)"></span>
								<a-divider v-if="isChecked==2" type="vertical" />
								<!-- 	<span class="sub_operation_btn" @click="toDetail(record)">变更</span> -->
								<span v-if="isChecked==2" class="sub_operation_btn "
									@click="openCheckModel('one',record)">审核</span>
							</span>
						</template>
					</a-table-column>
				</a-table>
			</a-config-provider>
		</div>
	</div>
	<!-- 审核modal -->
	<a-modal v-model:visible="checkModelShow" class="asdasd" title="" :footer="null" :closable="false" :centered="false"
		:destroyOnClose="true">
		<p v-if="selectItem" class="domain-check-warp-title">发票抬头：<span>{{selectItem.invoice_header}}</span></p>
		<div v-if="selectItem">
			<p>发票类型：{{selectItem.invoice_type}}</p>
			<p>公司纳税凭证号：{{selectItem.voucher_code}}</p>
			<p>公司纳税凭证类型：{{selectItem.voucher_type}}</p>
		</div>
		<p class="domain-check-warp-desc">若审核不通过，请输入不通过原因</p>
		<a-input v-model:value="checkDomain.status_msg" placeholder="请输入不通过原因" />
		<div class="domain-check-warp-hhus">
			<a-button @click="closeCheckModel">取消</a-button>
			<a-button @click="checkInfo( 2);" :loading="checkDomain.btn_load" type="danger">不通过</a-button>
			<a-button @click="checkInfo( 1);" :loading="checkDomain.btn_load" type="primary">通过</a-button>
		</div>
	</a-modal>


	<!-- 创建和修改开票信息 -->
	<a-modal class="bill_createModal" :centered="true" v-model:visible="createBillModalShow"
		:title="isCreateOrEdit==1?'创建开票信息':'修改开票信息'" :ok-text="isCreateOrEdit==1?'确认创建':'确认修改'" cancel-text="取消"
		@ok="sureNewBillInfo" :width="818" :destroyOnClose="true">
		<div class="bill_create_content">
			<a-form ref="formRef" :model="createBillInfo" :rules="createBillRules" style="padding: 0 40px 0;">
				<div class="bill_create_input_item">
					<div class="bill_create_input_item_center">
						<div class="bill_create_input_item_title">
							发票类型
						</div>

						<a-form-item ref="invoice_type" label="" name="invoice_type">
							<a-radio-group name="radioGroup" v-model:value="createBillInfo.invoice_type">
								<a-radio :value="1">增值税普通电子发票</a-radio>
								<!-- <a-radio :value="2">增值税专用发票</a-radio> -->
							</a-radio-group>
						</a-form-item>
					</div>
				</div>
				<div class="bill_create_input_item">
					<div class="bill_create_input_item_center">
						<div class="bill_create_input_item_title">
							发票抬头
						</div>
						<div>
							<div class="bill_content_title">
								普通发票的抬头可以是个人姓名或者公司名称</div>
							<a-form-item ref="invoice_header" label="" name="invoice_header">

								<a-input v-model:value="createBillInfo.invoice_header" placeholder="请输入发票抬头"
									style="width: 340px;" />
							</a-form-item>
						</div>


					</div>

				</div>

				<div class="bill_create_input_item">
					<div class="bill_create_input_item_center">
						<div class="bill_create_input_item_title">
							公司信息
						</div>
						<div style="width: 83%;">
							<div class="bill_content_title">
								据《国家税务总局关于增值税发票开具有关问题的公告》，自2017年7月1日起，企业购买索取增值税普通发票时，应向销售方提供纳税人识别号或统一社会信用代码，否则不得作为税收凭证。
							</div>
							<a-form-item ref="voucher_type" label="" name="voucher_type">
								<div class="bill_radio_box">
									<a-radio-group name="radioGroup" v-model:value="createBillInfo.voucher_type">
										<a-radio :value="1">纳税人识别号</a-radio>
										<a-radio :value="2">统一社会信用代码</a-radio>
									</a-radio-group>
								</div>
							</a-form-item>
							<a-form-item ref="voucher_code" label="" name="voucher_code">
								<a-input v-model:value="createBillInfo.voucher_code" placeholder="请填写税务登记证编号或社会信用代码"
									style="width: 340px;" />
							</a-form-item>
						</div>

					</div>

				</div>

				<div class="bill_create_input_item">
					<div class="bill_create_input_item_center">
						<div class="bill_create_input_item_title">
							公司地址
						</div>
						<a-form-item ref="company_address" label="" name="company_address">
							<!-- 	<div class="bill_content_title">
								公司地理名称</div> -->
							<a-input v-model:value="createBillInfo.company_address" placeholder="请输入公司地址"
								style="width: 340px;" />
						</a-form-item>
					</div>
				</div>

				<div class="bill_create_input_item">
					<div class="bill_create_input_item_center">
						<div class="bill_create_input_item_title">
							公司电话
						</div>
						<a-form-item ref="company_telephone" label="" name="company_telephone">
							<!-- <div class="bill_content_title">
								公司地理名称</div> -->
							<a-input v-model:value="createBillInfo.company_telephone" placeholder="请输入公司电话"
								style="width: 340px;" />
						</a-form-item>
					</div>
				</div>

				<div class="bill_create_input_item">
					<div class="bill_create_input_item_center">
						<div class="bill_create_input_item_title">
							开户行
						</div>
						<a-form-item ref="company_deposit" label="" name="company_deposit">
							<!-- <div class="bill_content_title">
								公司地理名称</div> -->
							<a-input v-model:value="createBillInfo.company_deposit" placeholder="请输入开户行"
								style="width: 340px;" />
						</a-form-item>
					</div>
				</div>
				<div class="bill_create_input_item" style="padding-bottom:29px;">
					<div class="bill_create_input_item_center">
						<div class="bill_create_input_item_title">
							账号
						</div>
						<a-form-item ref="company_account" label="" name="company_account">
							<!-- <div class="bill_content_title">
								公司地理名称</div> -->
							<a-input v-model:value="createBillInfo.company_account" placeholder="请输入账号"
								style="width: 340px;" />
						</a-form-item>
					</div>
				</div>
			</a-form>
		</div>
	</a-modal>

</template>

<script>
	import $ from "jquery";
	import base_func from '/src/config/base_func.js';
	import {
		Empty,
		Modal,
		message,
	} from 'ant-design-vue';
	import {
		computed,
		defineComponent,
		reactive,
		toRefs,
		ref,
		watch
	} from 'vue';
	import {
		ColumnProps
	} from 'ant-design-vue/es/table/interface';
	import {
		PlusOutlined
	} from '@ant-design/icons-vue';
	/* 国际化 */
	import zh_CN from 'ant-design-vue/es/date-picker/locale/zh_CN';
	import moment from 'moment';
	import 'moment/locale/zh-cn';
	moment.locale('zh-cn');
	/* 	目录 */
	import bus from '/src/assets/js/bus.js';
	import floader_sub from '/src/components/floader_sub.vue';
	import livecode from '/src/pages/livecode/App.vue';
	export default {
		name: 'billinfo',
		components: {
			VNodes: (_, {
				attrs
			}) => {
				return attrs.vnodes;
			},
			floader_sub,
			livecode,
			PlusOutlined
		},
		emits: ['getlistsfromLib'],
		props: {
			selectedOne: {
				type: Object,
				default: [],
			},
			isModel: {
				type: Boolean,
				default: false,
			}
		},
		watch: {
			isModel: function(nVal, oVal) {
				if (nVal) {
					this.selectedType = 'radio';
				} {
					this.selectedType = 'checkbox';
				}

			},
		},
		data() {
			return {
				isChecked: 1,
				selectedType: 'checkbox',
				configProvider: {
					lang: zh_CN,
					emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
				},
				pagination: {
					current: 1,
					pageSize: 20,
					total: 0,
				},
				tableData: [],
				tableLoading: false,
				searchValue: '',
				checkStatusValue: {
					value: -1,
					label: '全部'
				},
				checkStatusOptions: [{
						value: -1,
						label: '全部'
					}, {
						value: 0,
						label: '待审核'
					},
					{
						value: 1,
						label: '审核通过'
					},
					{
						value: 2,
						label: '审核未通过'
					},
				],
				billTypeValue: {
					value: -1,
					label: '全部'
				},
				billTypeOptions: [{
						value: -1,
						label: '全部'
					}, {
						value: 1,
						label: '增值税普通电子发票'
					}, {
						value: 2,
						label: '增值税专用发票'
					},

				],
				// 添加开票信息
				createBillInfo: {
					invoice_header: '', //发票抬头
					invoice_type: 1, //发票类型
					voucher_type: 1, //纳税凭证类型
					voucher_code: '', //纳税凭证号
					company_address: '', //公司地址
					company_telephone: '', //公司电话
					company_deposit: '', //开户行
					company_account: '', //账号
				},
				createBillRules: {
					invoice_header: [{
						required: true,
						message: '请输入发票抬头',
						trigger: 'blur'
					}, ],
					voucher_code: [{
						required: true,
						message: '请输入纳税凭证号',
						trigger: 'blur'
					}, ],
				},
				createBillModalShow: false,
				isCreateOrEdit: 1, //1:创建,2:修改

				selectedRowKeys: this.selectedOne,
				checkModelShow: false,
				selectItem: "",
				checkType: '', //'more':多个；'one':单个
				checkDomain: {
					status_msg: '',
					btn_load: false,
				},
			}
		},
		created() {
			this.isChecked = isChecked;
			if (this.isModel) {
				this.checkStatusValue = {
					value: 1,
					label: '审核通过'
				};
			}
			this.getTableData(true);


		},
		methods: {
			onSelectChange(e) {
				this.selectedRowKeys = e;
				this.$emit('getlistsfromLib', this.selectedRowKeys);
			},
			closeCheckModel() {
				this.checkModelShow = false;
			},
			openCheckModel(checkType, record) {
				this.checkType = checkType;
				this.checkDomain.status_msg = '';
				if (checkType == 'more') {
					if (this.selectedRowKeys.length != 0) {
						this.checkModelShow = true;
					} else {
						message.error('未选择');
					}

				} else if (checkType == 'one') {
					this.checkModelShow = true;
				}

				if (record) {
					this.selectItem = record;
				} else {
					this.selectItem = '';
				}
			},
			checkInfo(status) {
				let that = this;
				// let invoice_ids = [];
				// for (let i = 0; i < this.tableData.length; i++) {
				// 	for (let j = 0; j < this.selectedRowKeys.length; j++) {
				// 		if (i == this.selectedRowKeys[j]) {
				// 			invoice_ids.push(this.tableData[i].id);
				// 		}
				// 	}
				// }
				that.checkDomain.btn_load = true;
				let invoice_id = this.selectedRowKeys;
				if (this.checkType == 'one') {
					invoice_id = this.selectItem.id;
				}
				that.$apiRequest.post('/invoice/setInvoiceInformation', {
					operation: 'examine',
					invoice_id: invoice_id,
					status: status,
					status_msg: that.checkDomain.status_msg,
				}).then(res => {
					message.success(res.data.message);
					that.getTableData(true);
					that.checkModelShow = false;
					that.selectedRowKeys = [];
					that.checkDomain.btn_load = false;
				}).catch(err => {
					message.error(err.data.message);
					that.checkDomain.btn_load = false;
					that.selectedRowKeys = [];
				});

			},
			setMorenBillInfo(record) {
				if (record.default_information == 1) {

				} else {
					let that = this;
					that.$apiRequest.post('/invoice/setInvoiceInformation', {
						operation: 'set',
						invoice_id: record.id,
					}).then(res => {
						message.success(res.data.message);
						that.getTableData(true);
					}).catch(err => {
						message.error(err.data.message);
					});
				}

			},
			deleteMember(record) {
				let that = this;
				let confirmModal = Modal.confirm;
				confirmModal({
					title: '确认删除该开票信息',
					content: h => < div style = "color:red;" > 删除后将无法恢复， 请谨慎操作! < /div>,
					okText: '确认',
					okType: 'danger',
					cancelText: '取消',
					icon: h => < span class =
						"mbm-anticon mbm-iconfont mbm-wenhao-xianxingyuankuang" > <
						/span>,
					onOk() {
						that.$apiRequest.post('/invoice/setInvoiceInformation', {
							operation: 'del',
							invoice_id: record.id,
						}).then(res => {
							message.success(res.data.message);
							that.getTableData(true);
						}).catch(err => {
							message.error(err.data.message);
						});
					},
					onCancel() {

					},
				});

			},
			sureNewBillInfo() {
				let that = this;
				if (that.createBillInfo.invoice_header == '') {
					message.error('请输入发票抬头');
				} else if (that.createBillInfo.voucher_code == '') {
					message.error('请输入纳税凭证号');
				} else {
					let api = "/invoice/addInvoiceInformation";
					if (this.isCreateOrEdit == 2) {
						api = "/invoice/setInvoiceInformation";
					}
					that.$apiRequest.post(api, that.createBillInfo).then(res => {
						message.success(res.data.message);
						that.getTableData(true);
						that.createBillModalShow = false;
						if (this.isCreateOrEdit == 1) {
							that.pagination.current = 1;
						}

					}).catch(err => {
						message.error(err.data.message);
					});
				}

			},
			openCreateModal(type, record) {
				let that = this;
				if(record){
					record = JSON.parse(JSON.stringify(record));
				}
				
				this.isCreateOrEdit = type;
				if (type == 1) {
					this.createBillInfo = {
						invoice_header: '', //发票抬头
						invoice_type: 1, //发票类型
						voucher_type: 1, //纳税凭证类型
						voucher_code: '', //纳税凭证号
						company_address: '', //公司地址
						company_telephone: '', //公司电话
						company_deposit: '', //开户行
						company_account: '', //账号
					};
				} else {
					this.createBillInfo = record;
					that.createBillInfo.operation = 'edit';
					that.createBillInfo.invoice_id = record.id;
					if (record.invoice_type == "增值税专用发票") {
						this.createBillInfo.invoice_type = 2;
					} else {
						this.createBillInfo.invoice_type = 1;
					}
					if (record.voucher_type == "统一社会信用代码") {
						this.createBillInfo.voucher_type = 2;
					} else {
						this.createBillInfo.voucher_type = 1;
					}
					// this.createBillInfo.invoice_type = ( ? 1 : 2);
					// this.createBillInfo.voucher_type = (record.voucher_type != "统一社会信用代码" ? 1 : 2);
				}
				this.createBillModalShow = true;
			},
			onSearch(e) {
				this.pagination.current = 1;
				this.getTableData(true);
			},
			billTypeHandleChange(e) {
				this.pagination.current = 1;
				this.getTableData(true);
			},
			checkStatusHandleChange(e) {
				this.pagination.current = 1;
				this.getTableData(true);
			},
			getTableData(isRefash) {
				let that = this;
				that.tableLoading = true;
				if (!isRefash) {
					that.tableLoading = false;
				}

				that.$apiRequest.post("/invoice/getInvoiceInformation", {
					page: that.pagination.current,
					size: that.pagination.pageSize,
					status: that.checkStatusValue.value,
					keywords: that.searchValue,
					invoice_type: that.billTypeValue.value,
				}).then(res => {
					that.tableData = res.data.data.data;
					that.pagination.total = res.data.data.total;
					that.tableLoading = false;
				}).catch(err => {
					message.error(err.data.message)
					that.tableLoading = false;
				});
			},
			tableChange(e) {
				this.pagination = e;
				this.getTableData(true);
			},
		},
	}
</script>

<style>
	/* #app {
		width: 100%;
		height: 100vh;
	} */

	.bill_info_box {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.bill_info_box_top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.bill_btn_box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	.bill_btn_box .bill_btn {

		background: #09bd71;
		border-color: #09bd71;
	}

	.bill_btn_box .bill_btn:hover {
		background: #09bd71;
		border-color: #09bd71;
		opacity: 0.7;
	}

	.bill_btn_box .bill_search_input {
		width: 240px;
	}

	.bill_btn_box .bill_search_input:hover {
		border-color: #09bd71;
	}

	.bill_btn_box .bill_search_input:focus {
		border-color: #09bd71;
		box-shadow: unset;
	}

	.bill_btn_box .ant-input-affix-wrapper-focused {
		border-color: #09bd71;
		box-shadow: unset;
	}

	.bill_check_box {
		display: flex;
		margin-bottom: 10px;
	}

	.bill_select_item {
		margin-right: 40px;
	}

	.bill_select_item>span {
		margin-right: 20px;
	}

	.bill_mytable {
		height: 100%;
	}

	.bill_mytable .ant-spin-nested-loading {
		height: 100%;
	}

	.bill_mytable .ant-spin-nested-loading>div>.ant-spin {
		position: unset;
	}

	.bill_mytable .ant-spin-container {
		height: calc(100% - 64px);
	}

	.bill_mytable .ant-table {
		height: 100%;
	}

	.bill_mytable .ant-table-content {
		height: 100%;
	}

	.bill_mytable .ant-table-scroll {
		height: 100%;
	}

	.bill_mytable .ant-table-body {}

	.bill_mytable .ant-table-placeholder {
		height: calc(100% - 55px);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bill_mytable .bill_set_moren_btn,.bill_mytable .bill_set_moren_btn:hover,.bill_mytable .bill_set_moren_btn:focus {
		color: #09bd71;
		border-color: #09bd71;
		background: #edfbf5;
	}
	.bill_mytable .bill_set_moren_btn:hover {
		opacity: 0.7;
	}
	.bill_mytable .bill_set_moren_btn[disabled],.bill_mytable .bill_set_moren_btn[disabled]:hover,.bill_mytable .bill_set_moren_btn[disabled]:focus{
		color: rgba(0,0,0,.25);
		background: #f5f5f5;
		border-color: #d9d9d9;
	}

	.sub_operation_btn {
		font-size: 14px;
		color: #09bd71;
		cursor: pointer;
	}

	.sub_operation_btn:hover {
		opacity: 0.7;
	}


	.bill_full_modal .ant-modal {
		max-width: 100%;
		top: 0;
		padding-bottom: 0;
		margin: 0;
	}

	.bill_full_modal .ant-modal-content {
		display: flex;
		flex-direction: column;
		height: calc(100vh);
	}

	.bill_full_modal .ant-modal-body {
		flex: 1;
		overflow: auto;
	}

	.bill_create_content {
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.bill_create_input_item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-bottom: 30px;
		box-sizing: border-box;
	}

	.bill_create_input_item_center {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.bill_create_input_item_title {
		width: 17%;
		line-height: 32px;
		color: #2f3033;
		font-size: 16px;
	}

	.bill_content_title {
		color: #8d9199;
		line-height: 2;
		flex: 1;
		width: 91%;
	}

	.bill_radio_box {
		line-height: 32px;
		margin-bottom: 30px;
	}

	.bill_create_input_item .ant-form-item {
		margin-bottom: 0;
	}

	.bill_ischecked_box {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.bill_check_box .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-color: #09bd71;
	}

	.bill_check_box .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #09bd71;
	}

	.domain-check-warp-title {
		margin-bottom: 10px;
		font-size: 16px;
		word-break: break-all;
	}

	.domain-check-warp-title span {
		color: red;
		font-weight: 600;
	}

	.domain-check-warp-desc {
		color: #E88C00;
		margin-bottom: 10px;
	}

	.domain-check-warp-hhus {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 24px;
	}

	.domain-check-warp-hhus>button {
		margin-left: 8px;
	}

	.bill_fail_icon {
		font-size: 14px;
		margin-left: 2px;
		cursor: pointer;
	}
</style>
