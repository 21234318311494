<template>
	<div>
		<div class="qrcode_beautify_container">
			<div class="qrcode_beautify_containerL">
				<div class="qrcode_beautify_containerL_top">
					<div @click="setbeautystep(1)" :class="beautystep==1?'qrcode_beautify_containerL_top1 qrcode_beautify_containerL_top_active':'qrcode_beautify_containerL_top1'">1 选择预设</div>
					<div @click="setbeautystep(2)" :class="beautystep==2?'qrcode_beautify_containerL_top1 qrcode_beautify_containerL_top_active':'qrcode_beautify_containerL_top1'">2 图标文字</div>
					<div @click="setbeautystep(3)" :class="beautystep==3?'qrcode_beautify_containerL_top1 qrcode_beautify_containerL_top_active':'qrcode_beautify_containerL_top1'">3 前景色设置</div>
					<div @click="setbeautystep(4)" :class="beautystep==4?'qrcode_beautify_containerL_top1 qrcode_beautify_containerL_top_active':'qrcode_beautify_containerL_top1'">4 背景色设置</div>
					<div @click="setbeautystep(5)" :class="beautystep==5?'qrcode_beautify_containerL_top1 qrcode_beautify_containerL_top_active':'qrcode_beautify_containerL_top1'">5 码点与码眼</div>
				</div>
				<div class="qrcode_beautify_containerL_center">
					<div class="qrcode_beautify_step0" v-show="beautystep==1">
						<div class="presetLists">
							<div @click="setpreset(item.id,index)" :class="preset.presetActive==item.id?'presetList presetListActive':'presetList'" v-for="(item,index) in preset.presetLists">
								<img :src="item.cover"/>
							</div>
						</div>
						<div class="presetTypes">
							<ul>
								<li @click="setpresetType(index)" v-for="(item,index) in preset.presetTypelists" :class="preset.presetTypeActive==index?'presetType presetTypeActive':'presetType'">{{item.title}}</li>
							</ul>
						</div>
					</div>
					<div class="qrcode_beautify_step1" v-show="beautystep==2">
						<div class="iconAndfont">
							<p class="iconAndfont_title">选择图标</p>
							<div class="iconContainer">
								<ul class="iconContainer1 clearfloat">
									<li @click="seticon(-1)" :class="iconfont.listsActive==-1?'iconContainer11 iconContainer11Active':'iconContainer11'">
										<div class="logo-custom-jxiuhauh">
											<img src="/img/beautify/empty.png" />
											<p>无图标</p>
										</div>
									</li>
									<li @click="seticon(-2)" :class="iconfont.listsActive==-2?'iconContainer11 iconContainer11Active':'iconContainer11'">
										<div v-if="!iconfont.baseiconurl" class="logo-custom-jxiuhauh">
											<img src="/img/beautify/upload.png" />
											<p>本地上传</p>
										</div>
										<div v-else style="width:100%;height:100%;display:flex;align-items: center;justify-content: center;position:relative;">
											<img style="width:40px;height:40px;margin:0;" :src="iconfont.baseiconurl" />
											<CloseCircleFilled @click="delicon" class="getimgcolor21delImg" />
										</div>
										<input ref="iconfontIcon"  v-if="!iconfont.baseiconurl" @change="setuploadicon" class="uploadlogoinput" type="file" accept="image/jpg,image/png,image/jpeg">
									</li>
									<li @click="seticon(index)" v-for="(item,index) in iconfont.lists" :class="iconfont.listsActive==index?'iconContainer12 iconContainer11Active':'iconContainer12'">
										<img :src="item.url" />
									</li>
								</ul>
							</div>
						</div>
						<div class="iconAndfont" style="display: flex;align-items: center;margin-top: 10px;">
							<p class="iconAndfont_title" style="margin-right: 20px;">图标效果</p>
							<div class="iconContainer" style="margin-bottom: 15px;">
								<a-radio-group @change="seticonfontLogotype" name="radioGroup" v-model:value="iconfont.logoType">
								    <a-radio value="default">原图</a-radio>
								    <a-radio value="icon">矩形</a-radio>
								    <a-radio value="ellipse">圆角</a-radio>
								    <a-radio value="border">白底</a-radio>
									<a-radio value="stroke">描边</a-radio>
								</a-radio-group>
							</div>
						</div>
						<div class="iconAndfont"  style="display: flex;align-items: center;margin-bottom: 10px;">
							<p class="iconAndfont_title" style="margin-right: 20px;">图标位置</p>
							<div class="iconContainer" style="margin-bottom: 15px;">
								<a-radio-group @change="seticonfontLogoposition"  name="radioGroup" v-model:value="iconfont.logoPosition">
								    <a-radio value="center">码中间</a-radio>
								    <a-radio value="rb">码右下角</a-radio>
								</a-radio-group>
							</div>
						</div>
						<div class="iconAndfont">
							<p class="iconAndfont_title">文字内容</p>
							<div class="iconAndfont_text_container">
								<div class="iconAndfont_text">
									<input v-model="iconfont.text" placeholder="添加需要的文案" class="iconAndfont_text1" type="text">
									<span @click="seticonfonttext" class="iconAndfont_confirm">确定</span>
								</div>
								<div class="iconAndfont_text">
									<input v-model="iconfont.text2" placeholder="再添加一行" class="iconAndfont_text1" type="text">
									<span @click="seticonfonttext2" class="iconAndfont_confirm">确定</span>
								</div>
							</div>
							<div class="iconAndfont_text_container1 iconAndfont_text_container">
								<a-select @change="seticonfontEffect" ref="select1" v-model:value="iconfont.effect" style="width: 120px;margin-right:4px;">
									<template #suffixIcon>
										<Icon>
											<template #component>
												<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10">
													<path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path>
												</svg>
											</template>
									    </Icon>
									</template>
									<a-select-option value="default">描边</a-select-option>
								    <a-select-option value="in">内融合</a-select-option>
								    <a-select-option value="out">外融合</a-select-option>
								    <a-select-option value="stretch">拉伸</a-select-option>
									<a-select-option value="3d">3d效果</a-select-option>
									<a-select-option value="light">发光</a-select-option>
								</a-select>
								<a-select @change="seticonfontFont" ref="select2" v-model:value="iconfont.font" style="width: 120px;margin-right:4px;">
									<template #suffixIcon>
										<Icon>
											<template #component>
												<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10">
													<path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path>
												</svg>
											</template>
									    </Icon>
									</template>
									<a-select-option value="宋体">宋体</a-select-option>
								    <a-select-option value="隶书">隶书</a-select-option>
								    <a-select-option value="黑体">黑体</a-select-option>
								    <a-select-option value="KaiTi_GB2312">KaiTi_GB2312</a-select-option>
									<a-select-option value="幼圆">幼圆</a-select-option>
									<a-select-option value="微软雅黑">微软雅黑</a-select-option>
									<a-select-option value="华文彩云">华文彩云</a-select-option>
									<a-select-option value="方正舒体">方正舒体</a-select-option>
								</a-select>
								<a-select @change="seticonfontFontsize" ref="select3" v-model:value="iconfont.fontsize" style="width: 120px;margin-right:4px;">
									<template #suffixIcon>
										<Icon>
											<template #component>
												<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10">
													<path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path>
												</svg>
											</template>
									    </Icon>
									</template>
									<a-select-option value="16">字号16</a-select-option>
								    <a-select-option value="20">字号20</a-select-option>
								    <a-select-option value="22">字号22</a-select-option>
								    <a-select-option value="24">字号24</a-select-option>
								    <a-select-option value="28">字号28</a-select-option>
									<a-select-option value="32">字号32</a-select-option>
									<a-select-option value="36">字号36</a-select-option>
									<a-select-option value="40">字号40</a-select-option>
								</a-select>
								<div class="setfontcolor" :style="'color:'+iconfont.fontcolor">
									<span>文字颜色</span>
									<!-- <input type="color" @change="seticonfontcolor"> -->
									<color-input class="fontcolorColorpicker"  v-model="iconfont.fontcolor"  :disable-alpha="true" @affirmBtn="seticonfontFontcolor" />
									<!-- <DownOutlined style="position:absolute;font-size: 12px;color: #d9d9d9;right: 11px;top: 9px;" /> -->
									
										<Icon style="position:absolute;font-size: 12px;color: #d9d9d9;right: 11px;top: 9px;">
											<template #component>
												<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10">
													<path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path>
												</svg>
											</template>
									    </Icon>
								</div>
								<a-select v-if="false" ref="select5" v-model:value="iconfont.fontspace" style="width: 120px;margin-right:4px;">
									<template #suffixIcon>
										<Icon>
											<template #component>
												<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10">
													<path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path>
												</svg>
											</template>
									    </Icon>
									</template>
									<a-select-option value="1">16</a-select-option>
								    <a-select-option value="1">字间距1</a-select-option>
								    <a-select-option value="2">字间距2</a-select-option>
								    <a-select-option value="3">字间距3</a-select-option>
								</a-select>
							</div>
						</div>
					</div>
					<div class="qrcode_beautify_step2" v-show="beautystep==3">
						<div class="qrcode_beautify_step21 clearfloat">
							<div class="qrcode_beautify_step21_left">图片取色：</div>
							<div class="qrcode_beautify_step21_right" style="display:flex;align-items:flex-end">
								<div class="getimgcolor" v-if="!frontcolor.imgcolor.img">
									<PictureOutlined style="margin-right:5px" />从图片取色
									<input @change="getcolorfromImgF" ref="colorfromimgF" class="getcolorformImg" type="file" accept="image/jpg,image/png,image/jpeg">
								</div>
								<div v-else class="getimgcolor2">
									<ul>
										<li  class="getimgcolor21" style="position: relative;">
											<img :src="frontcolor.imgcolor.img" alt="">
											<CloseCircleFilled @click="delcolorFromImgF" class="getimgcolor21delImg" />
											<LoadingOutlined v-if="frontcolor.imgcolor.uploading" class="getcolorloading" />
										</li>
										<li v-show="index<7" @click="chooseImgcolorF(index)" :class="frontcolor.imgcolor.active==index?'getimgcolor21 colorpickActive':'getimgcolor21'" v-for="(item,index) in frontcolor.imgcolor.colors">
											<div :style="'background:'+item.hex"></div>
										</li>
									</ul>
								</div>
								<div class="colorpickerContainer">
									<span style="margin-right:10px;">调色板：</span>
									<div :class="frontcolor.colorpickeractive?'frontcolor_colorinputContainer colorpickActive':'frontcolor_colorinputContainer'">
										<img v-if="!frontcolor.color_pure1" src="/img/beautify/color.png" alt="">
										<div :style="'background:'+frontcolor.color_pure1" class="frontcolor_colorinputContainer_color"></div>
										<color-input class="colorpickerstyle" v-model="frontcolor.color_pure" :disable-alpha="true" @affirmBtn="setcolorpickerF" />
									</div>
								</div>
							</div>
						</div>
						<div class="qrcode_beautify_step21 clearfloat">
							<div class="qrcode_beautify_step21_left">
								<a-radio style="margin-top: -1px;" @click="setfrontcolorType(1)" :checked="frontcolor.colorType==1?true:false">纯色：</a-radio>
								
							</div>
							<div class="qrcode_beautify_step21_right">
								<ul class="purecolorlist clearfloat">
									<li @click="setcolorF(index,item)" :class="frontcolor.purecolor.colorlistActive==index?'colorpickActive':''" v-for="(item,index) in frontcolor.purecolor.colorlist">
										<div :style="'background:'+item"></div>
									</li>
								</ul>
								
							</div>
						</div>
						<div class="qrcode_beautify_step21 clearfloat">
							<div class="qrcode_beautify_step21_left">
								<a-radio style="margin-top: -1px;" @click="setfrontcolorType(2)" :checked="frontcolor.colorType==2?true:false">渐变：</a-radio>
								
							</div>
							<div class="qrcode_beautify_step21_right">
								<div class="colorcontainer">
									<span style="margin-right: 10px;">颜色1</span>
									<div class="frontcolor_colorinputContainer">
										<div :style="'background:'+frontcolor.gradient.color1" class="frontcolor_colorinputContainer_color"></div>
										<!-- <color-input class="colorpickerstyle" v-model="frontcolor.gradient.color1" :disable-alpha="true" @affirmBtn="setGradientColor1F" /> -->
										<color-input class="colorpickerstyle" :disable-alpha="true" @affirmBtn="setGradientColor1F" />
									</div>
									
								</div>
								<div class="colorcontainer">
									<span style="margin-right: 10px;">颜色2</span>
									<div class="frontcolor_colorinputContainer">
										<!-- <img v-if="!frontcolor.gradient.color2" src="/img/beautify/test.jpg" alt=""> -->
										<div :style="'background:'+frontcolor.gradient.color2" class="frontcolor_colorinputContainer_color"></div>
										<!-- <color-input class="colorpickerstyle" v-model="frontcolor.gradient.color2" :disable-alpha="true" @affirmBtn="setGradientColor2F" /> -->
										<color-input class="colorpickerstyle" :disable-alpha="true" @affirmBtn="setGradientColor2F" />
									</div>
								</div>
								<span>渐变方式</span>
								<a-select @change="setfrontcolorGradientType"  v-model:value="frontcolor.gradientType" style="width: 120px;margin-left:10px;">
									<template #suffixIcon>
										<Icon>
											<template #component>
												<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10">
													<path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path>
												</svg>
											</template>
									    </Icon>
									</template>
									<a-select-option value="0">无</a-select-option>
								    <a-select-option value="circular">圆形</a-select-option>
								    <a-select-option value="backslash">反斜线</a-select-option>
								    <a-select-option value="horizontal">水平</a-select-option>
									<a-select-option value="vertical">垂直</a-select-option>
								</a-select>
							</div>
						</div>
						<div class="qrcode_beautify_step21 clearfloat">
							<div class="qrcode_beautify_step21_left">
								<a-radio style="margin-top: -1px;" @click="setfrontcolorType(3)" :checked="frontcolor.colorType==3?true:false">图片：</a-radio>
							</div>
							<div class="qrcode_beautify_step21_right">
								<ul class="clearfloat imgContainer">
									<li @click="setfrontcolorImg({},-1)" :class="frontcolor.imglists.listactive==-1?'beautystyle1 beautystyle1avtive':'beautystyle1'" style="display:flex;align-items:center;justify-content: center;">
										<img v-if="frontcolor.imglists.uploadimg" :src="frontcolor.imglists.uploadimg" alt="">
										<input ref="frontcolorImg" @change="addfrontimg" class="addfrontimg" type="file" accept="image/jpg,image/png,image/jpeg">
										<PlusOutlined v-if="!frontcolor.imglists.uploadimg" />
										<CloseCircleFilled v-if="frontcolor.imglists.uploadimg" @click.stop="delfrontcolorImg" class="getimgcolor21delImg" />
									</li>
									<li @click="setfrontcolorImg(item,index)" :class="frontcolor.imglists.listactive==index?'beautystyle1 beautystyle1avtive':'beautystyle1'" v-for="(item,index) in frontcolor.imglists.list">
										<img :src="item.url" alt="">
									</li>
								</ul>
							</div>
						</div>
						
						<div class="qrcode_beautify_step21 clearfloat">
							<div class="qrcode_beautify_step21_left">码眼：</div>
							<div class="qrcode_beautify_step21_right" style="display: flex;align-items: center;height:32px;">
								<a-checkbox @change="setEyeusecolor" style="margin-right: 30px;" v-model:checked="frontcolor.colorTocodeeye">单独设置码眼颜色</a-checkbox>
								
								<div v-if="frontcolor.colorTocodeeye" class="colorcontainer">
									<span style="margin-right: 10px;">码眼外</span>
									<div class="frontcolor_colorinputContainer">
										<div :style="'background:'+frontcolor.codeEye.colorOut" class="frontcolor_colorinputContainer_color"></div>
										<!-- <color-input class="colorpickerstyle" v-model="frontcolor.codeEye.colorOut" :disable-alpha="true" @affirmBtn="setcodeeyecolorOut" /> -->
										<color-input class="colorpickerstyle" :disable-alpha="true" @affirmBtn="setcodeeyecolorOut" />
									</div>
								</div>
								<div v-if="frontcolor.colorTocodeeye" class="colorcontainer">
									<span style="margin-right: 10px;">码眼内</span>
									<div class="frontcolor_colorinputContainer">
										<div :style="'background:'+frontcolor.codeEye.colorInside" class="frontcolor_colorinputContainer_color"></div>
										<!-- <color-input class="colorpickerstyle" v-model="frontcolor.codeEye.colorInside" :disable-alpha="true" @affirmBtn="setcodeeyecolorInside" /> -->
										<color-input class="colorpickerstyle" :disable-alpha="true" @affirmBtn="setcodeeyecolorInside" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="qrcode_beautify_step3" v-show="beautystep==4">
						<div class="qrcode_beautify_step21 clearfloat">
							<div class="qrcode_beautify_step21_left">图片取色：</div>
							<div class="qrcode_beautify_step21_right"  style="display:flex;align-items:flex-end">
								<div class="getimgcolor" v-if="!backcolor.imgcolor.img">
									<PictureOutlined style="margin-right:5px" />从图片取色
									<input @change="getcolorfromImgB" ref="colorfromimgB" class="getcolorformImg" type="file" accept="image/jpg,image/png,image/jpeg">
								</div>
								<div v-else class="getimgcolor2">
									<ul>
										<li class="getimgcolor21" style="position: relative;">
											<img :src="backcolor.imgcolor.img" alt="">
											<CloseCircleFilled @click="delcolorFromImgB" class="getimgcolor21delImg" />
											<LoadingOutlined v-if="backcolor.imgcolor.uploading" class="getcolorloading" />
										</li>
										<li v-show="index<7" @click="chooseImgcolorB(index)" :class="backcolor.imgcolor.active==index?'getimgcolor21 colorpickActive':'getimgcolor21'" v-for="(item,index) in backcolor.imgcolor.colors">
											<div :style="'background:'+item.hex"></div>
										</li>
									</ul>
								</div>
								<div class="colorpickerContainer">
									<span style="margin-right:10px;">调色板：</span>
									<div :class="backcolor.colorpickeractive?'frontcolor_colorinputContainer colorpickActive':'frontcolor_colorinputContainer'">
										<img v-if="!backcolor.color_pure1" src="/img/beautify/color.png" alt="">
										<div :style="'background:'+backcolor.color_pure1" class="frontcolor_colorinputContainer_color"></div>
										<color-input class="colorpickerstyle" v-model="backcolor.color_pure" :disable-alpha="true" @affirmBtn="setcolorpickerB" />
									</div>
								</div>
							</div>
						</div>
						<div class="qrcode_beautify_step21 clearfloat">
							<div class="qrcode_beautify_step21_left">
								<a-radio style="margin-top: -1px;margin-right: 10px;" @click="setbackcolorType(1)" :checked="backcolor.colorType==1?true:false">纯色：</a-radio>
								
							</div>
							<div class="qrcode_beautify_step21_right">
								<ul class="purecolorlist clearfloat">
									<li @click="setcolorB(-1,{})" :class="backcolor.purecolor.colorlistActive==-1?'colorpickActive backgroundTm':'backgroundTm'" style="margin-bottom:0;">透明</li>
									
									<li style="margin-bottom:0;" @click="setcolorB(index,item)" :class="backcolor.purecolor.colorlistActive==index?'colorpickActive':''" v-for="(item,index) in backcolor.purecolor.colorlist">
										<div :style="'background:'+item"></div>
									</li>
								</ul>
							</div>
						</div>
						
						<div class="qrcode_beautify_step21 clearfloat">
							<div class="qrcode_beautify_step21_left">
								<a-radio style="margin-top: -1px;" @click="setbackcolorType(2)" :checked="backcolor.colorType==2?true:false">图片：</a-radio>
								
							</div>
							<div class="qrcode_beautify_step21_right">
								<ul class="clearfloat imgContainer">
									<li  @click="setbackcolorImg({},-1)" :class="backcolor.imglists.listactive==-1?'beautystyle1 beautystyle1avtive':'beautystyle1'" style="display:flex;align-items:center;justify-content: center;">
										<img v-if="backcolor.imglists.uploadimg" :src="backcolor.imglists.uploadimg" alt="">
										<input  ref="backcolorImg" @change="addbackimg" class="addfrontimg" type="file" accept="image/jpg,image/png,image/jpeg">
										<PlusOutlined v-if="!backcolor.imglists.uploadimg" />
										<CloseCircleFilled v-if="backcolor.imglists.uploadimg" @click.stop="delbackcolorImg" class="getimgcolor21delImg" />
									</li>
									<li @click="setbackcolorImg(item,index)" :class="backcolor.imglists.listactive==index?'beautystyle1 beautystyle1avtive':'beautystyle1'" v-for="(item,index) in backcolor.imglists.list">
										<img :src="item.url" alt="">
									</li>
								</ul>
							</div>
						</div>
					</div>	
					<div class="qrcode_beautify_step4" v-show="beautystep==5">
						<div class="qrcode_beautify_step41 clearfloat">
							<div class="qrcode_beautify_step41_left">
								<span>码眼样式：</span>
							</div>
							
							<div class="qrcode_beautify_step41_right">
								<ul class="clearfloat codeeyeContainer">
									<li @click="setcodeeyeStyle(item,index)" :class="codedotAndEye.codeeyelists.listactive==index?'codeeyeContainer1 colorpickActive':'codeeyeContainer1'" v-for="(item,index) in codedotAndEye.codeeyelists">
										<img :src="item.url" alt="">
									</li>
								</ul>
							</div>
						</div>
						<div class="qrcode_beautify_step41 clearfloat">
							<div class="qrcode_beautify_step41_left">
								<span>码点样式：</span>
							</div>
							<div class="qrcode_beautify_step41_right">
								<ul class="clearfloat codedotContainer">
									<li @click="setcodedotStyle(item,index)" :class="codedotAndEye.codedotlists.listactive==index?'codedotContainer1 colorpickActive':'codedotContainer1'" v-for="(item,index) in codedotAndEye.codedotlists">
										<img :src="item.url" alt="">
									</li>
								</ul>
							</div>
						</div>
						<div class="qrcode_beautify_step41 clearfloat">
							<div class="qrcode_beautify_step41_left">
								<span>容错率：</span>
							</div>
							<div class="qrcode_beautify_step41_right">
								<div class="qrcode_beautify_step41_right_faultNum">
									<a-radio-group @change="setcodedotAndEyeFaultnum" v-model:value="codedotAndEye.faultNum">
										<a-radio value="L">最低（可遮7%）</a-radio>
										<a-radio value="M">低（可遮15%）</a-radio>
										<a-radio value="Q">中等（可遮25%）</a-radio>
										<a-radio value="H">高（可遮30%）</a-radio>
									</a-radio-group>
								</div>
								<p class="faultNumtips">二维码被遮挡后仍能被正常扫描，而这个能被遮挡的最大面积就是容错率。</p>
							</div>
						</div>
						<div class="qrcode_beautify_step41 clearfloat">
							<div class="qrcode_beautify_step41_left">
								<span>其他参数：</span>
							</div>
							<div class="qrcode_beautify_step41_right qrcode_beautify_step41_right1">
								<!-- <a-slider @afterChange="setcodedotAndEyeRoundness" :step="0.1" :min="-0.5" :max="0.5" class="roundnessContainer" :marks="codedotAndEye.roundness.marks" v-model:value="codedotAndEye.roundness.value" /> -->
								<span style="">外边距</span>
								<a-select @change="setcodedotAndEyePadding" ref="select3" v-model:value="codedotAndEye.padding" style="width: 100px;margin-left:10px;">
									<template #suffixIcon>
										<Icon>
											<template #component>
												<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10">
													<path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path>
												</svg>
											</template>
									    </Icon>
									</template>
									<a-select-option value="0">0</a-select-option>
								    <a-select-option value="5">5</a-select-option>
								    <a-select-option value="10">10</a-select-option>
								    <a-select-option value="15">15</a-select-option>
									<a-select-option value="20">20</a-select-option>
									<a-select-option value="30">30</a-select-option>
									<a-select-option value="40">40</a-select-option>
									<a-select-option value="50">50</a-select-option>
									<a-select-option value="80">80</a-select-option>
									<a-select-option value="100">100</a-select-option>
									<a-select-option value="120">120</a-select-option>
									<a-select-option value="140">140</a-select-option>
									<a-select-option value="160">160</a-select-option>
									<a-select-option value="180">180</a-select-option>
									<a-select-option value="200">200</a-select-option>
									
								</a-select>
								<span style="margin-left: 20px;">旋转角度</span>
								<a-select @change="codedotAndEyeRotate" ref="select3" v-model:value="codedotAndEye.rotate" style="width: 100px;margin-left:10px;">
									<template #suffixIcon>
										<Icon>
											<template #component>
												<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10">
													<path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path>
												</svg>
											</template>
									    </Icon>
									</template>
									<a-select-option value="0">0</a-select-option>
								    <a-select-option value="30">30</a-select-option>
								    <a-select-option value="45">45</a-select-option>
									<a-select-option value="60">60</a-select-option>
								    <a-select-option value="90">90</a-select-option>
									<a-select-option value="180">180</a-select-option>
								</a-select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="qrcode_beautify_containerR">
				<div class="qrcode_beautify_containerR1">
					<div class="qrcode_beautify_containerR1_left">
						<div @click="uploadimgTogetcode" v-if="!qrcodeInfo.path" style="position:relative;">
							<CloudUploadOutlined class="iconstyle" />
							<span class="marginleft5">上传取码</span>
							<input @change="getcodeFromimg" ref="uploadimggetcode" class="qrcode_beautify_containerR1_left_uploadimg" type="file" accept="image/jpg,image/png,image/jpeg" >
						</div>
						<div v-else @click="clearBeautyStyle">
							<!-- <DeleteOutlined class="iconstyle" /> -->
							<span class="mbm-iconfont mbm-shanchu"></span>
							<span class="marginleft5">清除样式</span>
						</div>
					</div>
					<div class="qrcode_beautify_containerR1_right">
						<div v-if="!qrcodeInfo.path" @click="showtextarea">
							<EditOutlined class="iconstyle" />
							<span class="marginleft5">输入文字</span>
						</div>
						<div v-else @click="saveAemplate">
							<!-- <SaveOutlined class="iconstyle" /> -->
							<span class="mbm-iconfont mbm-baocun"></span>
							<span v-if="qrcodeContentData.moban_id && qrcodeContentData.moban_id>0" class="marginleft5">修改模板</span>
							<span v-else class="marginleft5">存为模板</span>
						</div>
					</div>
				</div>
				<div  class="qrcode_beautify_containerR2">
					<div v-if="!qrcodeInfo.path" class="qrcode_beautify_containerR21">
						<div class="qrcodeEmpty">
							<span class="mbm-iconfont mbm-xiazai42"></span>
							<p>此处预览二维码</p>
						</div>
					</div>
					<div v-else class="qrcode_beautify_containerR22">
						<img :src="qrcodeInfo.path" alt="">
					</div>
					<div class="staticText" v-if="textareashow">
						<textarea v-model="qrcodeInfo.textareaValue" name="" class="staticTextArea" placeholder="请输入文字"></textarea>
						<div class="staticTextBtn">
							<a-button @click="hidetextarea" class="staticTextBtn1" size="mini">取消</a-button>
							<a-button @click="textareaconfirm" class="staticTextBtn1" type="primary">确认</a-button>
						</div>
					</div>
				</div>
				<div v-if="qrcodeInfo.path" class="qrcode_beautify_containerR33">
					<a-button class="saveQrcode" @click="downloadBase64ToLocal(qrcodeInfo.path)" type="primary">保存二维码</a-button>
					<a-button v-if="qrcodeContentData.content && (!qrcodeContentData.moban_id || qrcodeContentData.moban_id<1)" class="saveQrcode" @click="beautifyComplete('complete')" >完成编辑</a-button>
					<div class="download-orther-btn-warp">
						<div @click="downloadOrtherExtData.openDownloadOrtherExt" class="download-orther-ext-jxuhs">下载其他格式</div>
						<div style="margin-left:24px;" @click="templateSet" class="download-orther-ext-jxuhs">模板设置</div>
					</div>
					
				</div>
				
			</div>
		</div>
		
		<!-- 下载其他格式 -->
		<a-modal width="1000" wrapClassName="downloadImgModal" :mask="true" :closable="false" :destroyOnClose="true" :footer="null" :centered="true" :visible="downloadOrtherExtData.visible"  title="">
			<downloadImg @closedownloadimg="downloadOrtherExtData.closeDownloadOrtherExt" :qrcodeInfo="downloadOrtherExtData.qrcodeInfo"></downloadImg>
		</a-modal>
	</div>
	
</template>

<script>
	import { message } from 'ant-design-vue';
	import { ColorPicker } from "vue3-colorpicker";
	import { Download } from '../assets/js/download.js';
	import ColorInput from '/src/components/color-picker/color-input.vue';
	import baseFunc from '../config/base_func.js';
	import "vue3-colorpicker/style.css";
	import { v4 as uuidv4 } from 'uuid';
	import downloadImg from './downloadImg';
	import {LoadingOutlined,PictureOutlined,PlusOutlined,DownOutlined,CloseCircleFilled,CloudUploadOutlined,EditOutlined,DeleteOutlined,SaveOutlined} from '@ant-design/icons-vue'
	import Icon from '@ant-design/icons-vue';
	import config from "/src/config/config.js";
	export default {
		name: 'beautifyMod',
		props: {
			beautyStepInit:{/*美化步骤*/
				type:Number,
				default:1,
			},
			qrcodeContentData:{
				type:Object,
				default:{
					qrcode_type:"text",/*二维码类型*/
					id_key:"",/*二维码id_key*/
					content:"",/*二维码内容*/
					config:null,/*二维码参数*/
					moban_id:0,/*模板id*/
				}
			},
		},
		components: {
			Icon,ColorInput,ColorPicker,LoadingOutlined,PictureOutlined,PlusOutlined,DownOutlined,CloseCircleFilled,CloudUploadOutlined,EditOutlined,DeleteOutlined,SaveOutlined,downloadImg
		},
		data() {
			return {
				downloadOrtherExtData:{
					visible:false,
					qrcodeInfo:{
						config:null,
						content:"",
						id_key:"",
						base64:null,
						name:"",
					},
					openDownloadOrtherExt:()=>{
						if(!this.qrcodeInfo.textareaValue){
							message.warning('请先生成二维码');
							return false;
						}
						this.downloadOrtherExtData.qrcodeInfo.config = JSON.parse(JSON.stringify(this.qrcodeInfo.config));
						this.downloadOrtherExtData.qrcodeInfo.content = this.qrcodeInfo.textareaValue;
						this.downloadOrtherExtData.visible = true;
					},
					closeDownloadOrtherExt:()=>{
						this.downloadOrtherExtData.visible = false;
					},
				},
				download : new Download(),
				tplSaveLoad:false,
				textareashow:false,//文本输入显示
				qrcodeBeautifyFunc:null,
				beautystep:this.beautyStepInit,/**/
				qrcodeInfo:{
					qrcodeContent:{
						qrcode_type:"text",/*二维码类型*/
						id_key:"",/*二维码id_key*/
						content:"",/*二维码内容*/
						config:null,/*二维码参数*/
					},
					contentInput:false,
					createLoad:false,
					path:'',/*二维码图片地址*/
					textareaValue:"",/*二维码内容*/
					config:{
						transparent:false,/*透明度 false:不透明 true:透明*/
						background: null,/*背景图片*/
						backgroundkey: "",/*背景图片请求秘钥*/
						backgroundold: null, /*背景图片 本地上传或者本地选择*/
						bgColor: "#ffffff",/*二维码背景色*/
						bgwidth: 400,/*背景图片尺寸*/
						width: 400,/*二维码大小*/
						point_type:0,/*码点样式*/
						content: null,/*中心部分 文字内容*/
						two_text:null,
						ewmleft: 0,/*二维码放入框距离左边距离*/
						ewmtop: 0,/*二维码放入框距离顶部距离*/
						fgColor: "#000000",/*二维码颜色*/
						fontEffect: "default",/*文字特效*/
						fontFamily: "KaiTi_GB2312",/*文字字体*/
						fontSize: 22,/*文字大小*/
						fontleft: 0,/*文字坐标 距离左边的位置*/
						fonttop: 0,/*文字左边 距离顶部的位置*/
						foreground: null,/*前景图片*/
						foregroundold: null,/*前景图片 本地上传logo*/
						ftColor: '#000000',/*文字颜色*/
						gcColor: null,/*二维码渐变色*/
						gradientWay: "0",/*二维码渐变方式*/
						level: "M",/*容错率*/
						logoType: "default",/*logo效果*/
						logoPlace:"center",/*logo位置 center:居中 rb：右下*/
						logoimgold: null,/*本地上传logo Or 选择本地*/
						logoimg: null,/*logo图片地址*/
						logoleft: -100,/*logo位置 距离左边*/
						logotop: -100,/*logo位置 距离右边*/
						logowidth: 0, /*logo宽度*/
						logoheight: 0,/*logo高度*/
						margin: 10, /*二维码外边距*/
						max: 2000,
						maxwidth: 2000,
						nowwidth: 0,
						paintColor: null,
						eys_use_color:true,/*是否图片填充码眼*/
						pinType: 0,/*码眼样式*/
						inptColor: null,/*码眼内框颜色*/
						ptColor: null,/*码眼外框颜色*/
						isWater: false,/*round>0=false round<0=true*/
						rotate: 0,/*二维码旋转角度*/
						round: 0, /*0：直角 -0.5~0液态 0~0.5圆点*/
						text: "",/*二维码内容http://www.wwei.cn*/
						codetype:'qr',//码制
					},
				},
				preset:{//预设
					presetTypeActive:0,//预设分类选中
					presetTypelists:[],//预设分类
					presetLists:[],//预设列表
					presetActive:-1,//预设选中
				},
				iconfont:{//文字图标
					lists:[],//图标列表
					listsActive:null,//图标选中
					text:'',//第一行
					text2:'',//第二行
					effect:'default',//特效
					font:'宋体',//字体
					fontsize:'22',//字号
					fontcolor:'#000000',//字色
					fontspace:0,//字间距
					baseiconurl:'',//本地logo地址
					baseicon:null,//图片文件
					logoType: "default",
					logoPosition:'center'
				},
				frontcolor:{//前景色
					colorType:0,//1纯色，2渐变，3图片填充
					gradientType:'0',//渐变方式
					color_pure:'',//纯色
					color_pure1:'',//颜色选择器使用
					purecolor:{//纯色
						colorlist:['#000000','#365f93','#1477d1','#0088d1','#7ab6e3','#087f23','#009760','#4caf50','#292d33','#962d5a','#b71c1c','#dc6094','#de8270','#ea9118','#eeb420'],
						colorlistActive:null
					},
					gradient:{//渐变色
						color1:'#000000',
						color2:'#09bd71'
					},
					imgcolor:{//图片取色
						img:'',//前景图片地址
						colors:[],
						active:null,
						uploading:false,//上传状态
					},
					imglists:{//图片
						list:[],
						listactive:null,
						uploadimg:'',
						uploadimgfile:null
					},
					colorTocodeeye:false,//颜色应用至码眼
					codeEye:{
						colorOut:'#000000',
						colorInside:'#000000'
					},
					colorpickeractive:false
				},
				backcolor:{//背景色
					colorType:0,//1纯色，2图片，
					gradientType:0,//渐变方式
					color_pure:'',//纯色
					color_pure1:'',//颜色选择器使用
					purecolor:{//纯色
						colorlist:['#ffffff','#fff3e0','#fff9c4','#f0f4c3','#e1f5fe','#f0f2f1','#fedfe1','#dfdbed','#ffdf22'],
						colorlistActive:null
					},
					
					imgcolor:{//图片取色
						img:'',//图片地址
						colors:[],
						active:null,
						uploading:false
					},
					imglists:{
						list:[],
						listactive:null,
						uploadimg:'',
						uploadimgfile:null
					},
					colorpickeractive:false
				},
				codedotAndEye:{
					faultNum:'M',//容错率
					codeeyelists:{//码眼
						list:[],
						listactive:0,
					},
					codedotlists:{//码点
						list:[],
						listactive:0
					},
					roundness:{
						marks:{
							'-0.5':'液态',
							'0':'直角',
							'0.5':'圆点'
						},
						value:0
					},
					padding:10,//外边距
					rotate:0,//旋转
				},
				defaultstyle: {
				  transparent:false,/*透明度 false:不透明 true:透明*/
				  background: null,/*背景图片*/
				  backgroundkey: "",/*背景图片请求秘钥*/
				  backgroundold: null, /*背景图片 本地上传或者本地选择*/
				  bgColor: "#ffffff",/*二维码背景色*/
				  bgwidth: 400,/*背景图片尺寸*/
				  width: 400,/*二维码大小*/
				  point_type:0,/*码点样式*/
				  content: null,/*中心部分 文字内容*/
				  two_text:null,
				  ewmleft: 0,/*二维码放入框距离左边距离*/
				  ewmtop: 0,/*二维码放入框距离顶部距离*/
				  fgColor: "#000000",/*二维码颜色*/
				  fontEffect: "default",/*文字特效*/
				  fontFamily: "KaiTi_GB2312",/*文字字体*/
				  fontSize: 22,/*文字大小*/
				  fontleft: 0,/*文字坐标 距离左边的位置*/
				  fonttop: 0,/*文字左边 距离顶部的位置*/
				  foreground: null,/*前景图片*/
				  foregroundold: null,/*前景图片 本地上传logo*/
				  ftColor: '#000000',/*文字颜色*/
				  gcColor: '#09bd71',/*二维码渐变色*/
				  gradientWay: "0",/*二维码渐变方式*/
				  level: "M",/*容错率*/
				  logoType: "default",/*logo效果*/
				  logoPlace:"center",/*logo位置 center:居中 rb：右下*/
				  logoimgold: null,/*本地上传logo Or 选择本地*/
				  logoimg: null,/*logo图片地址*/
				  logoleft: -100,/*logo位置 距离左边*/
				  logotop: -100,/*logo位置 距离右边*/
				  logowidth: 0, /*logo宽度*/
				  logoheight: 0,/*logo高度*/
				  margin: 10, /*二维码外边距*/
				  max: 2000,
				  maxwidth: 2000,
				  nowwidth: 0,
				  paintColor: null,
				  eys_use_color:true,/*是否图片填充码眼*/
				  pinType: 0,/*码眼样式*/
				  inptColor: null,/*码眼内框颜色*/
				  ptColor: null,/*码眼外框颜色*/
				  isWater: false,/*round>0=false round<0=true*/
				  rotate: 0,/*二维码旋转角度*/
				  round: 0, /*0：直角 -0.5~0液态 0~0.5圆点*/
				  text: "哈哈哈",/*二维码内容http://www.wwei.cn*/
				  codetype:'qr',//码制
			  },
			  bgimgitem:{},//背景图对象
			}
		},
		methods:{
			downloadBase64ToLocal(base64){/*下载图片到本地*/
				this.download.downloadFile(base64);
			},
			
			//传图取码
			getcodeFromimg(e){
				let load_key = uuidv4();
				message.loading({ content: '取码中，请稍后…', load_key });
				this.$apiRequest.uploadFile('/api/beautify/decodeQrcodeImgContent', e.target.files[0], {
				}, progress => {
					
				}).then(res => {
					this.setfinaldata('text',res.data.data,{})
					message.success({ content: res.data.message, load_key });
				}).catch(err => {
					message.error({ content: err.data.message, load_key });
				});
			},
			//传图取码
			uploadimgTogetcode(){
				this.$refs.uploadimggetcode.dispatchEvent(new MouseEvent('click'))
			},
			//设置旋转角度
			codedotAndEyeRotate(e){
				this.setfinaldata('rotate',e,{})
			},
			//设置外边距
			setcodedotAndEyePadding(e){
				this.setfinaldata('margin',e,{})
			},
			//设置液态圆点
			setcodedotAndEyeRoundness(e){
				this.setfinaldata('round',e,{})
			},
			//设置容错lv
			setcodedotAndEyeFaultnum(e){
				this.setfinaldata('level',e.target.value,{})
			},
			//设置码点样式
			setcodedotStyle(item,index){
				this.codedotAndEye.codedotlists.listactive=index;
				this.setfinaldata('point_type',item.path,{})
			},
			//设置码眼样式
			setcodeeyeStyle(item,index){
				this.codedotAndEye.codeeyelists.listactive=index;
				this.setfinaldata('pinType',item.path,{})
			},
			//单独设置码眼setcodeeyecolorInside
			setcodeeyecolorInside(e){
				this.frontcolor.codeEye.colorInside=e
				this.setfinaldata('inptColor',e,{})
			},
			setcodeeyecolorOut(e){
				this.frontcolor.codeEye.colorOut=e
				this.setfinaldata('ptColor',e,{})
			},
			//图片或主色填充码眼
			setEyeusecolor(e){
				this.setfinaldata('eys_use_color',!e.target.checked,{})
			},
			//前景图片取色选中
			chooseImgcolorF(e){
				this.frontcolor.imgcolor.active=e;
				this.frontcolorchecked(1)
				this.setfinaldata('fgColor',this.frontcolor.imgcolor.colors[e].hex,{'gradientWay':0})
			},
			//背景图片取色选中
			chooseImgcolorB(e){
				this.backcolor.imgcolor.active=e;
				this.backcolorchecked(1)
				this.setfinaldata('bgColor',this.backcolor.imgcolor.colors[e].hex,{})
			},
			//二维码文字
			seticonfonttext(e){
				this.setfinaldata('content',this.iconfont.text,{})
			},
			seticonfonttext2(e){
				this.setfinaldata('two_text',this.iconfont.text2,{})
			},
			//文字输入确认
			textareaconfirm(){
				this.textareashow=false;
				this.setfinaldata('text',this.qrcodeInfo.textareaValue,{})
			},
			//关闭文字输入
			hidetextarea(){
				this.textareashow=false;
				this.qrcodeInfo.textareaValue='';
			},
			//显示文字输入
			showtextarea(){
				this.textareashow=true;
			},
			//删除图片（背景）
			delcolorFromImgB(){
				this.backcolor.imgcolor.img='';
				this.backcolor.imgcolor.colors=[];
				this.$refs.colorfromimgB.value='';
			},
			//设置渐变色（前景）
			setGradientColor1F(e){
				this.frontcolor.gradient.color1=e;
				this.frontcolorchecked(4)
				if(!this.frontcolor.gradientType || parseInt(this.frontcolor.gradientType)==0)this.frontcolor.gradientType = "circular"
				this.setfinaldata('fgColor',e,{gradientWay:this.frontcolor.gradientType})
			},
			setGradientColor2F(e){
				this.frontcolor.gradient.color2=e;
				this.frontcolorchecked(4)
				if(!this.frontcolor.gradientType || parseInt(this.frontcolor.gradientType)==0)this.frontcolor.gradientType = "circular"
				this.setfinaldata('gcColor',e,{gradientWay:this.frontcolor.gradientType})
			},
			//前景色渐变方式
			setfrontcolorGradientType(e){
				this.setfinaldata('gradientWay',e,{})
			},
			
			//设置码眼内框颜色
			setEodeEyeColorInside(e){
				this.frontcolor.codeEye.colorInside=e.target.value
			},
			//设置码眼外框颜色
			setEodeEyeColorOut(e){
				this.frontcolor.codeEye.colorOut=e.target.value
			},
			//设置前景色（选择）
			setcolorF(index,val){
				this.frontcolor.purecolor.colorlistActive=index;
				this.frontcolorchecked(3)
				this.setfinaldata('fgColor',val,{'gradientWay':0})
				// this.setfinaldata('gradientWay',null,{})
			},
			//设置背景色（选择）
			setcolorB(index,val){
				this.backcolor.purecolor.colorlistActive=index;
				this.backcolorchecked(3);
				if(index==-1){
					this.setfinaldata('transparent',true,{})
				}else{
					this.setfinaldata('bgColor',val,{})
					this.setfinaldata('transparent',false,{})
				}
			},
			//设置前景色（调色板）
			setcolorpickerF(e){
				this.frontcolor.color_pure=e;
				this.frontcolor.color_pure1=e;
				this.frontcolorchecked(2)
				this.setfinaldata('fgColor',e,{'gradientWay':0})
			},
			
			//设置前景色类型
			setfrontcolorType(e){
				this.frontcolor.colorType = e
				if(e==1){
					this.frontcolor.purecolor.colorlistActive=0;
					this.frontcolor.colorpickeractive=false;
					this.frontcolor.imgcolor.active=null;
					this.frontcolor.imglists.listactive=null;
					this.setfinaldata('fgColor',this.frontcolor.purecolor.colorlist[this.frontcolor.purecolor.colorlistActive],{
						'gradientWay':0
					})
				}else if(e==3){
					this.frontcolor.imglists.listactive=0;
					this.frontcolor.purecolor.colorlistActive=null;
					this.frontcolor.colorpickeractive=false;
					this.frontcolor.imgcolor.active=null;
					this.setfinaldata('foreground',this.frontcolor.imglists.list[this.frontcolor.imglists.listactive].path,{})
				}else if(e==2){
					this.frontcolor.imglists.listactive=null;
					this.frontcolor.purecolor.colorlistActive=null;
					this.frontcolor.colorpickeractive=false;
					this.frontcolor.imgcolor.active=null;
					if(!this.frontcolor.gradientType || parseInt(this.frontcolor.gradientType)==0)this.frontcolor.gradientType = "circular";
					this.setfinaldata('gradual',null,{
						fgColor:this.frontcolor.gradient.color1,
						gcColor:this.frontcolor.gradient.color2,
						gradientWay:this.frontcolor.gradientType,
					})
				}
			},
			//删除图片（前景）
			delcolorFromImgF(){
				this.frontcolor.imgcolor.img='';
				this.frontcolor.imgcolor.colors=[];
				this.$refs.colorfromimgF.value='';
			},
			//选择前景图
			setfrontcolorImg(item,index){
				if(index==-1){
					if(index==-1&&!this.frontcolor.imglists.uploadimg){
						this.$refs.frontcolorImg.dispatchEvent(new MouseEvent('click'))
					}else{
						
						if(typeof this.frontcolor.imglists.uploadimgfile.config_data != 'undefined' && this.frontcolor.imglists.uploadimgfile.config_data == -1){
							this.setfinaldata('foreground',this.frontcolor.imglists.uploadimgfile.url,{})
						}else{
							this.setfinaldata('foreground',"",{url:this.frontcolor.imglists.uploadimgfile})
						}
					}
				}else{
					this.setfinaldata('foreground',item.path,{})
				}
				
				this.frontcolor.imglists.listactive=index;
				this.frontcolorchecked(5);
			},
			//上传前景图
			addfrontimg(e){
				this.frontcolor.imglists.uploadimg=URL.createObjectURL(e.target.files[0]);
				this.frontcolor.imglists.uploadimgfile=e;
				this.frontcolor.imglists.listactive=-1;
				this.frontcolorchecked(5);
				this.setfinaldata('foreground',"",{url:e})
			},
			
			//设置背景色（调色板）
			setcolorpickerB(e){
				this.backcolor.color_pure=e;
				this.backcolor.color_pure1=e;
				this.backcolorchecked(2)
				this.setfinaldata('bgColor',e,{})
			},
			//设置背景色类型
			setbackcolorType(e){
				this.backcolor.colorType=e;
				if(e==1){
					this.backcolor.purecolor.colorlistActive=0;
					this.backcolor.imglists.listactive=null;
					this.backcolor.colorpickeractive=false;
					this.backcolor.imgcolor.active=null;
					this.setfinaldata('bgColor',this.backcolor.purecolor.colorlist[this.backcolor.purecolor.colorlistActive],{})
				}else if(e==2){
					this.backcolor.purecolor.colorlistActive=null;
					this.backcolor.imglists.listactive=0;
					this.backcolor.colorpickeractive=false;
					this.backcolor.imgcolor.active=null;
					this.setfinaldata('background',this.backcolor.imglists.list[this.backcolor.imglists.listactive].path,this.backcolor.imglists.list[this.backcolor.imglists.listactive])
				
				}
			},
			//选择bei景图
			setbackcolorImg(item,index){
				this.backcolor.imglists.listactive=index;
				this.backcolorchecked(4);
				if(index==-1){
					if(index==-1&&!this.backcolor.imglists.uploadimg){
						this.$refs.backcolorImg.dispatchEvent(new MouseEvent('click'))
					}else{
						if(this.backcolor.imglists.uploadimgfile){
							this.setfinaldata('background','',{url:this.backcolor.imglists.uploadimgfile});
						}else{
							this.setfinaldata('background',this.bgimgitem.path,this.bgimgitem);
						}
					}
				}else{
					this.setfinaldata('background',item.path,item);
				}
				
			},
			
			//上传背景图
			addbackimg(e){
				this.backcolor.imglists.uploadimg=URL.createObjectURL(e.target.files[0]);
				this.backcolor.imglists.uploadimgfile=e;
				this.backcolor.imglists.listactive=-1;
				this.backcolorchecked(4);
				this.setfinaldata('background',"",{url:e})
			},
			//删除前景图
			delfrontcolorImg(){
				this.frontcolor.imglists.uploadimg='';
				this.frontcolor.imglists.uploadimgfile={};
				this.$refs.frontcolorImg.value='';
			},
			//删除背景图
			delbackcolorImg(){
				this.backcolor.imglists.uploadimg='';
				this.backcolor.imglists.uploadimgfile={};
				this.$refs.backcolorImg.value='';
			},
			//设置logo位置
			seticonfontLogoposition(e){
				this.setfinaldata('logoPlace',e.target.value,{})
			},
			//设置logo样式
			seticonfontLogotype(e){
				this.setfinaldata('logoType',e.target.value,{})
			},
			//上传logo
			setuploadicon(e){
				this.iconfont.baseiconurl=URL.createObjectURL(e.target.files[0]);
				this.iconfont.listsActive=-2;
				this.iconfont.baseicon=e;
				this.setfinaldata('logoimg',"",{url:e})
			},
			/*删除用户自己选中的logo*/
			delicon(){
				this.iconfont.baseiconurl='';
				this.iconfont.baseicon=null;
				this.$refs.iconfontIcon.value='';
				if(this.iconfont.listsActive==-2){
					this.seticon(-3)
				}
			},
			//设置图标选中
			seticon(e){
				if(e==-1 || e==-3){//无图标
					this.setfinaldata('logoimg','',{});
				}else if(e==-2){
					if(this.iconfont.baseicon){
						if(typeof this.iconfont.baseicon.config_data != 'undefined' && this.iconfont.baseicon.config_data == -1){
							this.setfinaldata('logoimg',this.iconfont.baseicon.url,{})
						}else{
							this.setfinaldata('logoimg',"",{url:this.iconfont.baseicon})
						}
					}
					this.iconfont.listsActive=e
				}else{
					this.setfinaldata('logoimg',this.iconfont.lists[e].path,{})
				}
				if(e!=-2)this.iconfont.listsActive=e
			},
			//从图片取色=>选图(前景)
			getcolorfromImgF(e){
				var that=this;
				var file=e.target.files[0];
				var img=new FileReader();
				that.frontcolor.imgcolor.uploading=true;
				that.$apiRequest.uploadFile('/api/beautify/getImageColorLists', file, {
				}, progress => {
					
				}).then(res => {
					if(res.data.code==200){
						that.frontcolor.imgcolor.colors=res.data.data
					}else{
						message.error(res.data.message);
					}
					that.frontcolor.imgcolor.uploading=false;
				}).catch(err => {
					
				});
				img.readAsDataURL(file);
				img.onload=function(e){
					that.frontcolor.imgcolor.img=this.result;
				}
			},
			//从图片取色=>选图(背景)
			getcolorfromImgB(e){
				var that=this;
				var file=e.target.files[0];
				var img=new FileReader();
				that.backcolor.imgcolor.uploading=true;
				that.$apiRequest.uploadFile('/api/beautify/getImageColorLists', file, {
				}, progress => {
					
				}).then(res => {
					if(res.data.code==200){
						that.backcolor.imgcolor.colors=res.data.data
					}else{
						message.error(res.data.message);
					}
					that.backcolor.imgcolor.uploading=false;
				}).catch(err => {
					
				});
				img.readAsDataURL(file);
				img.onload=function(e){
					that.backcolor.imgcolor.img=this.result;
				}
			},
			//图标文字-》设置文字颜色
			seticonfontFontcolor(e){
				this.setfinaldata('ftColor',e,{})
			},
			seticonfontEffect(e){
				this.setfinaldata('fontEffect',e,{})
			},
			seticonfontFont(e){
				this.setfinaldata('fontFamily',e,{})
			},
			seticonfontFontsize(e){
				this.setfinaldata('fontSize',e,{})
			},
			//取消前景选项选中
			frontcolorchecked(num){
				//1图片取色选中2调色板选中3纯色选中4渐变5图片选中
				//colorType:0,1纯色，2渐变，3图片填充
				if(num==1){
					this.frontcolor.colorpickeractive=false;
					this.frontcolor.purecolor.colorlistActive=null;
					this.frontcolor.colorType=1;
					this.frontcolor.imglists.listactive=null;
				}else if(num==2){
					this.frontcolor.imgcolor.active=null;
					this.frontcolor.colorpickeractive=true;
					this.frontcolor.purecolor.colorlistActive=null;
					this.frontcolor.colorType=1;
					this.frontcolor.imglists.listactive=null;
				}else if(num==3){
					this.frontcolor.imgcolor.active=null;
					this.frontcolor.colorpickeractive=false;
					this.frontcolor.colorType=1;
					this.frontcolor.imglists.listactive=null;
				}else if(num==4){
					this.frontcolor.imgcolor.active=null;
					this.frontcolor.colorpickeractive=false;
					this.frontcolor.purecolor.colorlistActive=null;
					this.frontcolor.colorType=2;
					this.frontcolor.imglists.listactive=null;
				}else if(num==5){
					this.frontcolor.imgcolor.active=null;
					this.frontcolor.colorpickeractive=false;
					this.frontcolor.purecolor.colorlistActive=null;
					this.frontcolor.colorType=3;
				}
			},
			//取消背景选项选中
			backcolorchecked(num){
				//1图片取色选中2调色板选中3纯色选中4图片选中
				//colorType:0,1纯色，2图片填充
				if(num==1){
					this.backcolor.colorpickeractive=false;
					this.backcolor.colorType=1;
					this.backcolor.imglists.listactive=null;
					this.backcolor.purecolor.colorlistActive=null;
				}else if(num==2){
					this.backcolor.imgcolor.active=null;
					this.backcolor.colorpickeractive=true;
					this.backcolor.colorType=1;
					this.backcolor.imglists.listactive=null;
					this.backcolor.purecolor.colorlistActive=null;
				}else if(num==3){
					this.backcolor.imgcolor.active=null;
					this.backcolor.colorpickeractive=false;
					this.backcolor.colorType=1;
					this.backcolor.imglists.listactive=null;
				}else if(num==4){
					this.backcolor.imgcolor.active=null;
					this.backcolor.colorpickeractive=false;
					this.backcolor.purecolor.colorlistActive=null;
					this.backcolor.colorType=2;
				}
			},
			//选择美化步骤
			setbeautystep(e){
				this.beautystep=e
			},
			//获取预设列表
			getpresetList(){
				this.$apiRequest.post('/api/beautify/getBeautifyTpls',{
					cat_id:-1,
					page:1
				}).then(res=>{
					this.preset.presetTypelists=res.data.data
					this.preset.presetLists=res.data.data[this.preset.presetTypeActive].childs.data
				}).catch(err=>{
					baseFunc.checkState(err.data).then(res=>{}).catch(err=>{})
				})
			},
			//获取logo和图片
			getlogoAndImg(){
				this.$apiRequest.post('/api/beautify/getImageLists',{
					
				}).then(res=>{
					this.iconfont.lists=res.data.data.icons;
					this.frontcolor.imglists.list=res.data.data.foregrounds;
					this.backcolor.imglists.list=res.data.data.backgrounds;
					this.codedotAndEye.codeeyelists=res.data.data.eyes;
					this.codedotAndEye.codedotlists=res.data.data.code_points;
				}).catch(err=>{
					baseFunc.checkState(err.data).then(res=>{}).catch(err=>{})
				})
			},
			//选中预设
			setpreset(e,index){
				this.preset.presetActive=e;
				// var bgimgitem={
				// 	left:this.preset.presetLists[index].config.ewmleft,
				// 	top:this.preset.presetLists[index].config.ewmtop,
				// 	width:this.preset.presetLists[index].config.bgwidth,
				// 	path:this.preset.presetLists[index].config.backgroundold,
				// 	url:this.preset.presetLists[index].config.backgroundold,
				// }
				// this.bgimgitem=bgimgitem;
				// this.frontcolor.imglists.uploadimgfile=null;
				// this.backcolor.imglists.uploadimgfile=null;
				// this.iconfont.baseiconurl='';
				// this.iconfont.baseicon=null;
				this.setDefaultStyle(this.preset.presetLists[index].config);
			},
			//选择预设分类
			setpresetType(e){
				this.preset.presetTypeActive=e
				this.preset.presetLists=this.preset.presetTypelists[this.preset.presetTypeActive].childs.data
			},
			/* 清除样式*/
			clearBeautyStyle(){
				this.setDefaultStyle(null)
				this.preset.presetActive=null;
			},
			setDefaultStyle(config){
				if(!config){
					config=JSON.parse(JSON.stringify(this.defaultstyle))
				}else{
					config=JSON.parse(JSON.stringify(config))
				}
				//step2
				this.iconfont.text=config.content;
				this.iconfont.text2=config.two_text;
				this.iconfont.effect=config.fontEffect;
				this.iconfont.font=config.fontFamily;
				this.iconfont.fontsize=config.fontSize;
				this.iconfont.fontcolor=config.ftColor;
				this.iconfont.logoPosition=config.logoPlace;
				this.iconfont.logoType=config.logoType;
				
				/*设置默认logo选中*/
				if(config.logoimg){
					this.iconfont.baseiconurl=baseFunc.getAliOssAuthKeyLocal(config.logoimg);
					this.iconfont.listsActive=-2;
					this.iconfont.baseicon={
						config_data:-1,
						url:config.logoimg,
					};
				}else{
					this.iconfont.baseiconurl="";
					this.iconfont.listsActive=null;
					this.iconfont.baseicon=null;
				}
				
				//step3
				this.frontcolor.color_pure1=config.fgColor;
				this.frontcolor.color_pure=config.fgColor;
				this.frontcolor.gradient.color1=config.fgColor;
				this.frontcolor.gradient.color2=config.gcColor;
				this.frontcolor.gradientType=config.gradientWay;
				this.frontcolor.colorTocodeeye=config.eys_use_color?true:false;
				this.frontcolor.codeEye.colorOut=config.ptColor;
				this.frontcolor.codeEye.colorInside=config.inptColor;
				this.frontcolor.imgcolor.active=null;
				this.frontcolor.colorpickeractive=false;
				this.frontcolor.imglists.listactive=null;
				this.setforegroundImg(config);/*初始化前景图*/
				//step4
				
				
				this.backcolor.imgcolor.active=null;
				this.backcolor.colorpickeractive=false;
				this.backcolor.imglists.listactive=null;
				this.backcolor.purecolor.colorlistActive=null;//纯色选中
				// this.setbackgroundImg(config)
				
				//step5
				this.codedotAndEye.faultNum=config.level;
				this.codedotAndEye.roundness.value=config.round;
				this.codedotAndEye.padding=config.margin;
				this.codedotAndEye.rotate=config.rotate;
				this.setcodeeyeActive(config);
				this.setcodedotActive(config);
				config.text = this.qrcodeInfo.textareaValue
				this.setfinaldata('moban',config,{});
			},
			//设置前景图选中
			setforegroundImg(config){
				if(config.foreground){//有前景图
					this.frontcolor.imglists.uploadimg=baseFunc.getAliOssAuthKeyLocal(config.foreground);
					this.frontcolor.colorType=3;
					this.frontcolor.imglists.listactive=-1;
					this.frontcolor.imglists.uploadimgfile={
						config_data:-1,
						url:config.foreground,
					};
				}else{//无前景图
					this.frontcolor.imglists.uploadimg=null;
					if(config.fgColor&&config.gcColor){//有渐变
						this.frontcolor.colorType=2;
					}else{//无渐变
						this.frontcolor.colorType=null;
						this.backcolor.colorpickeractive=true;
					}
				}
			},
			//设置背景图片选中
			setbackgroundImg(config){
				if(config.backgroundold){//有背景图
					this.backcolor.colorType=2;
					this.backcolor.imglists.uploadimg=config.backgroundold;
				}else{//无背景图
					this.backcolor.imglists.uploadimg=null;
					if(config.bgColor){//有背景色
						this.backcolor.color_pure1=config.bgColor;
						this.backcolor.color_pure=config.bgColor;
						this.backcolor.colorType=null;
						this.backcolor.colorpickeractive=true;
					}else{//无背景色
						this.backcolor.colorType=1;
						this.backcolor.purecolor.colorlistActive=-1;
					}
				}
			},
			
			//设置码点选中
			setcodeeyeActive(config){
				// this.codedotAndEye.codeeyelists.listactive
				var list=this.codedotAndEye.codeeyelists;
				if(config){
					for(var i=0;i<list.length;i++){
						if(list[i].path==config.pinType){
							this.codedotAndEye.codeeyelists.listactive=i;
						}
					}
				}else{
					this.codedotAndEye.codeeyelists.listactive=0;
				}
			},
			//设置码眼选中
			setcodedotActive(config){
				var dotlist=this.codedotAndEye.codedotlists;
				if(config){
					if(config.point_type){
						for(var j=0;j<dotlist.length;j++){
							if(dotlist[j].path==config.point_type){
								this.codedotAndEye.codedotlists.listactive=j;
							}
						}
					}else{
						this.codedotAndEye.codedotlists.listactive=0;
					}
					
				}else{
					this.codedotAndEye.codedotlists.listactive=0;
				}
			},
			//生成数据
			setfinaldata(type,val,extra){
				if(type != 'text')this.qrcodeInfo.config.text = this.qrcodeInfo.textareaValue
				if(!this.qrcodeBeautifyFunc){
					if(type=="text"){
						this.qrcodeInfo.config.text = val
						this.qrcodeInfo.contentInput = true;
						this.qrcodeInfo.textareaValue = val;
						this.beautifyQrcodeFunc()
					}else{
						this.qrcodeInfo.config.text = "https://www.wwei.cn"
						this.qrcodeInfo.contentInput = false;
						this.beautifyQrcodeFunc()
					}
				}else{
					switch(type){
						case "text":
							this.qrcodeInfo.contentInput = true;
							this.qrcodeInfo.config.text = val
							this.qrcodeInfo.textareaValue = val;
							this.qrcodeBeautifyFunc.changeText(val)
							break;
						case "logoimg":
							if(val){
								this.qrcodeBeautifyFunc.changeLogoimg(1,baseFunc.getAliOssAuthKeyLocal(val))
							}else if(typeof extra.url!="undefined" && extra.url){
								this.qrcodeBeautifyFunc.changeLogoimg(extra.url,null)
							}else{
								this.qrcodeBeautifyFunc.changeLogoimg(null,"",true)
							}
							break;
						case "logoType":
							this.qrcodeBeautifyFunc.changeLogotype(val)
							break;
						case "logoPlace":
							this.qrcodeBeautifyFunc.changelogoPlace(val)
							break;
						case "fgColor":
							this.qrcodeBeautifyFunc.changeForeground(null,"",true)
							if(typeof extra.gradientWay!="undefined"){
								this.qrcodeBeautifyFunc.changeFgColor(val,extra.gradientWay)
							}else{
								this.qrcodeBeautifyFunc.changeFgColor(val)
							}
							break;
						case "gcColor":
							this.qrcodeBeautifyFunc.changeForeground(null,"",true)
							if(typeof extra.gradientWay!="undefined"){
								this.qrcodeBeautifyFunc.changeGcColor(val,extra.gradientWay)
							}else{
								this.qrcodeBeautifyFunc.changeGcColor(val)
							}
							break;
						case "gradual":
							this.qrcodeBeautifyFunc.changeForeground(null,"",true)
							this.qrcodeBeautifyFunc.changeGradualval(extra.fgColor,extra.gcColor,extra.gradientWay)
							break;
						case "gradientWay":
							this.qrcodeBeautifyFunc.changeForeground(null,"",true)
							this.qrcodeBeautifyFunc.changeGradientWay(val)
							break;
						case "foreground":
							if(val){
								this.qrcodeBeautifyFunc.changeForeground(1,baseFunc.getAliOssAuthKeyLocal(val))
							}else if(typeof extra.url!="undefined" && extra.url){
								this.qrcodeBeautifyFunc.changeForeground(extra.url,null)
							}else{
								this.qrcodeBeautifyFunc.changeForeground(null,"",true)
							}
							break;
						case "eys_use_color":
							this.qrcodeBeautifyFunc.changeEysusecolor(val,this.frontcolor.codeEye.colorInside,this.frontcolor.codeEye.colorOut)
							break;
						case "inptColor":
							this.qrcodeBeautifyFunc.changeInPtColor(val)
							break;
						case "ptColor":
							this.qrcodeBeautifyFunc.changePtColor(val)
							break;
						case "bgColor":
							this.qrcodeBeautifyFunc.changeBackground(null,"",true,{w:400})
							this.qrcodeBeautifyFunc.changeBgColor(false)
							this.qrcodeBeautifyFunc.changeBgColor(val)
							break;
						case "transparent":
							this.qrcodeBeautifyFunc.changeBackground(null,"",true,{w:400})
							this.qrcodeBeautifyFunc.changeBgColor(val)
							break;
						case "background":
							if(val){
								this.qrcodeBeautifyFunc.changeBackground(1,baseFunc.getAliOssAuthKeyLocal(val),null,extra)
							}else if(typeof extra.url!="undefined" && extra.url){
								this.qrcodeBeautifyFunc.changeBackground(extra.url,null)
							}else{
								this.qrcodeBeautifyFunc.changeBackground(null,"",true,{w:400})
							}
							break;
						case "pinType":
							this.qrcodeBeautifyFunc.changePtImage(val)
							break;
						case "level":
							this.qrcodeBeautifyFunc.changeLevel(val)
							break;
						case "round":
							this.qrcodeBeautifyFunc.changeRound(val)
							break;
						case "margin":
							this.qrcodeBeautifyFunc.changeMargin(val)
							break;
						case "rotate":
							this.qrcodeBeautifyFunc.changeRotate(val)
							break;
						case "point_type":
							this.qrcodeBeautifyFunc.changePointType(val)
							break;
						case "content":
							this.qrcodeBeautifyFunc.changeContent(val)
							break;
						case "two_text":
							this.qrcodeBeautifyFunc.changeTwoContent(val)
							break;
						case "fontEffect":
							this.qrcodeBeautifyFunc.changeFontEffect(val)
							break;
						case "fontFamily":
							this.qrcodeBeautifyFunc.changeFontFamily(val)
							break;
						case "fontSize":
							this.qrcodeBeautifyFunc.changeFontSize(val)
							break;
						case "ftColor":
							this.qrcodeBeautifyFunc.changeFtColor(val)
							break;
						case "moban":
							val = JSON.parse(JSON.stringify(val));
							if(val.background && !baseFunc.isBase64(val.background))val.background=baseFunc.getAliOssAuthKeyLocal(val.background)
							if(val.foreground && !baseFunc.isBase64(val.background))val.foreground=baseFunc.getAliOssAuthKeyLocal(val.foreground)
							if(val.logoimg && !baseFunc.isBase64(val.background))val.logoimg=baseFunc.getAliOssAuthKeyLocal(val.logoimg)
						
							this.qrcodeBeautifyFunc.tempInit(val)
							break;
					}
				}
			},
			/*美化初始化数据*/
			beautifyDataInit(){
				try{
					if(this.qrcodeContentData){
						if(this.qrcodeContentData.config)this.qrcodeInfo.config = JSON.parse(JSON.stringify(this.qrcodeContentData.config));
						if(this.qrcodeContentData.content)this.setfinaldata("text",this.qrcodeContentData.content)
					}
				}catch(err){}
			},
			/*调用二维码美化接口*/
			beautifyQrcodeFunc(){
				const _this = this
				this.qrcodeBeautifyFunc = new qrcodeBeautifyFuncPackage({
					info:this.qrcodeInfo.config,
					success:function (res) {
						if(_this.qrcodeInfo.contentInput){
							_this.qrcodeInfo.path = res.base64;
						}
						_this.qrcodeInfo.config = JSON.parse(JSON.stringify(res.info));
						// _this.qrcodeInfo.createLoad = false;
						_this.beautifyComplete("change");
					},error:function (err) {
						// _this.qrcodeInfo.createLoad = false;
					},load:function(load){
						/*数据生成中*/
						// _this.qrcodeInfo.createLoad = true;
					}
				});
			},
			beautifyComplete(emit){
				var qrcodeContentData = JSON.parse(JSON.stringify(this.qrcodeContentData));
				qrcodeContentData.config = this.qrcodeInfo.config;
				qrcodeContentData.base64 = this.qrcodeInfo.path;
				this.$emit(emit,qrcodeContentData)
			},
			// 存为模板
			saveAemplate(){
				if(this.tplSaveLoad){
					return false;
					message.warning("正在保存中，请稍后"); 
				}
				this.tplSaveLoad = true;
				let load_key = uuidv4();
				message.loading({ content: '保存中…', load_key });
				this.$apiRequest.post('/api/beautify/saveUserTpl',{
					config:JSON.stringify(this.qrcodeInfo.config),
					base64:this.qrcodeInfo.path,
					title:'',
					is_def:0,
					moban_id:this.qrcodeContentData.moban_id,
				}).then(res=>{
					this.tplSaveLoad = false;
					message.success({ content: res.data.message, load_key });
				}).catch(err=>{
					this.tplSaveLoad = false;
					message.error({ content: err.data.message, load_key });
				})
			},
			/*跳转后台模板设置*/
			templateSet(){
				this.$apiRequest.post('/qrcode/create/getUserDefultSet',{
					
				}).then(res=>{
					if(res.data.data.login_status==1){
						window.open(config.dengluDomain+"/user/beautify")
					}else{
						baseFunc.checkState({code:11000}).then(()=>{}).catch(()=>{})
					}
				}).catch(()=>{
					window.open(config.dengluDomain+"/user/beautify")
				})
			},
		},
		created() {
			this.getpresetList();
			this.getlogoAndImg();
			this.beautifyDataInit();
			
		}
	}
</script>

<style scoped>
	.qrcode_beautify_step21_left /deep/ span{
		padding-right:0 !important;
	}
	.ant-radio-wrapper{
		margin-right: 0 !important;
	}
	.qrcode_beautify_containerR22 img{
		max-width:100%;
		max-height:100%;
		/* object-fit: contain; */
		box-shadow: 0 0 6px rgba(0,0,0,.05);
	/* 	-ms-interpolation-mode: nearest-neighbor;
		image-rendering: -moz-crisp-edges;
		image-rendering: pixelated; */
	}
	.qrcode_beautify_containerR1_left_uploadimg{
		width:0;
		height:0;
		position: absolute;
		left:0;
		top:0;
		opacity: 0;
	}
	.backgroundTm{
		font-size:12px;
		line-height:30px;
		display:block;
		width:100%;
		height:100;
		text-align:center;
	}
	.addfrontimg{
		width:0;
		height:0;
		opacity: 0;
		position: absolute;
		left: 0;
		top: 0;
	}
	.frontcolor_colorinputContainer img{
		width: 100%;
		height: 100%;
		border-radius: 2px;
	}
	.colorpickerstyle /deep/ .box{
		opacity: 0;
	}
	.colorpickerstyle{
		width: 100%;
		height: 100%;
		/* opacity: 0; */
		position: absolute;
		left: 0;
		top: 0;
	}
	.frontcolor_colorinputContainer_color{
		width:100%;
		height: 100%;
		position: absolute;
		left: 0;
		border-radius: 2px;
		top: 0;
	}
	.frontcolor_colorinputContainer /deep/ .color-input .box.active .inner{
		transform: scale(1);
	}
	.frontcolor_colorinputContainer /deep/ .color-input .box{
		width: 30px;
		height: 30px;
		
		border-radius: 2px;
	}
	.colorpickerContainer{
		display:inline-flex;
		align-items: center;
		margin-left: 30px;
	}
	.frontcolor_colorinputContainer{
		width: 32px;
		height: 32px;
		border: 1px solid #f7f7f7;
		border-radius: 2px;
		position: relative;
		display: inline-block;
		transition: all .3s;
	}
	.frontcolor_colorinputContainer:hover{
		box-shadow: 0	0	3px	1px #09bd71;;
	}
	.fontcolorColorpicker{
		position: absolute;
		left:50%;
		top:0;
	}
	.fontcolorColorpicker /deep/ .box{
		position: absolute;
		width:120px;
		height:32px;
		left:-60px;
		top:0;
		opacity: 0;
	}
	
	.uploadlogoinput{
		width:100%;
		height:100%;
		opacity:0;
		position: absolute;
		left:0;
		cursor: pointer;
		top:0;
		z-index:999
	}
	.getcolorloading{
		position: absolute;
		left:0;
		top:0;
		right:0;
		bottom:0;
		margin:auto;
		display:flex;
		justify-content: center;
		align-items: center;
		font-size:30px;
	}
	.staticTextBtn1{
		width: 120px;
	}
	.staticTextBtn{
		width: 100%;
		height: 70px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	.staticTextArea{
		width:100%;
		height: 310px !important;border: 0;
		resize: none;
		padding: 10px;
		outline: none;
	}
	.staticText{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #fff;
	}
	.qrcode_beautify_containerR22{
		width: 100%;
		height: 100%;
		padding: 0 13px;
		overflow:hidden;
		display:flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.qrcode_beautify_containerR1_left>div,.qrcode_beautify_containerR1_right>div{
		cursor: pointer;
	}
	.qrcodeEmpty>p{
		font-size: 16px;
		line-height: 16px;
	}
	.qrcodeEmpty>span{
		font-size: 110px;color: #d8d8d8;
	}
	.qrcodeEmpty{
		text-align: center;
		color: #999;
	}
	.qrcode_beautify_containerR21{
		width: 100%;
		height: 100%;
		background-color: #f3f3f3;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.qrcode_beautify_containerR2{
		width: 100%;
		height: 380px;
		/* background-color: #fff; */
		margin-top: 20px;
		position: relative;
	}
	.iconstyle{
		font-size: 16px;
		color: rgba(0,0,0,0.65);
	}
	.marginleft5{
		margin-left: 5px;
	}
	
	.qrcode_beautify_containerR1{
		display:flex;
		align-items: center;
		justify-content: space-between;
		font-size:16px;
		height: 35px;
	}
	.qrcode_beautify_containerR{
		padding:60px 45px;
	}
	.qrcode_beautify_step41_right1{
		display: flex;
		align-items: center;
	}
	.roundnessContainer /deep/ .ant-slider-mark>span:first-child{
		left: 5% !important;
	}
	.roundnessContainer /deep/ .ant-slider-mark>span:last-child{
		left: 95% !important;
	}
	
	.roundnessContainer /deep/ .ant-slider-mark{
		margin-top: 5px;
	}
	.roundnessContainer /deep/ .ant-slider-mark-text{
		color: rgba(0, 0, 0, .85);
	}
	.roundnessContainer /deep/ .ant-slider-handle{
		margin-top: -4px;
		width: 18px;
		height: 18px;
	}
	.roundnessContainer /deep/ .ant-slider-track{
		height: 10px;
		border-radius: 4px;
	}
	.roundnessContainer /deep/ .ant-slider-step{
		height: 10px;
		border-radius: 4px;
	}
	.roundnessContainer /deep/ .ant-slider-dot{
		display: none;
	}
	.roundnessContainer /deep/ .ant-slider-rail{
		height: 10px;
		border-radius: 4px;
	}
	.roundnessContainer{
		width:225px;height: 0;
		padding: 0;
		margin-left: -5px;
	}
	.faultNumtips{
		font-size: 12px;
		color: #999;
		margin-top:7px;
	}
	.qrcode_beautify_step41_right_faultNum{
		height: 32px;
		display: flex;
		align-items: center;
	}
	.qrcode_beautify_step41{
		margin-bottom: 30px;
	}
	.codedotContainer1 img,.codeeyeContainer1 img{
		width: 100%;
		height: 100%;
		border-radius: 2px;
	}
	.codedotContainer{
		/* width: 580px; */
	}
	.codedotContainer1{
		width: 55px;
		height: 55px;
		margin-right: 13px;
		margin-bottom: 13px;
		float: left;
		border: 1px solid rgba(0, 0, 0, .1);
	}
	.codedotContainer li{
		list-style: none;
		transition: all .3s;
	}
	.codeeyeContainer1:hover,.codedotContainer1:hover{
		box-shadow: 0	0	3px	1px #09bd71;
	}
	.codeeyeContainer1{
		width: 34px;
		height: 34px;
		list-style: none;
		transition: all .3s;
		cursor: pointer;
		float: left;
		margin-right: 8px;
		margin-bottom: 8px;
		border-radius: 2px;
		border: 1px solid rgba(0, 0, 0, .1);
	}
	.getimgcolor21delImg{
		position: absolute;
		top: -9px;
		right: -9px;
		font-size: 12px;
	}
	.getimgcolor21>div{
		width:100%;
		height:100%;
		border-radius: 2px;
	}
	.getimgcolor21 img{
		width:100%;
		height:100%;
	}
	.getimgcolor2{
		display:inline-block;
	}
	.getimgcolor2 ul{
		display: inline-flex;
		align-items: flex-end;
	}
	.getimgcolor2 li:not(:first-child):hover{
		box-shadow: 0 0 3px 1px #09bd71;
	}
	.getimgcolor2 li{
		width:32px;
		height:32px;
		border: 1px solid rgba(0, 0, 0, .1);
		border-radius: 2px;
		transition: all .3s;
		cursor: pointer;
		list-style: none;
		margin-right: 8px;
	}
	.getimgcolor2 li:first-child{
		width: 54px;
		height: 54px;
	}
	.getimgcolor2 li:hover{
		
	}
	.setfontcolor input{
		width:100%;
		height:100%;
		position:absolute;
		opacity: 0;
		left:0;
		top:0;
	}
	.setfontcolor:hover{
		border: 1px solid #40a9ff;
	}
	.setfontcolor{
		width: 120px;
		margin-right: 4px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		transition: all .3s;
		cursor: pointer;
		font-size: 14px;
		color: rgba(0, 0, 0, .85);
		padding:0 11px;
		position:relative;
		height:32px;
		line-height:30px;
	}
	.imgContainer{
		/* width:510px; */
		width:100%;
	}
	.beautystyle1 img{
		width: 100%;
		height: 100%;
		border-radius: 2px;
		object-fit:contain;
	}
	.beautystyle1avtive,.beautystyle1:hover{
		box-shadow: 0 0 3px 1px #09bd71;
	}
	.beautystyle1{
		width: 38px;
		height: 38px;
		transition: all .3s;
		cursor: pointer;
		border: 1px solid rgba(0, 0, 0, .1);
		border-radius: 2px;
		box-sizing: border-box;
		position: relative;
		margin-right: 5.5px;
		list-style: none;
		float: left;
		margin-bottom:5.5px;
	}
	.bgcolorImg li:hover{
		box-shadow: 0 0 3px 1px #09bd71;
	}
	.bgcolorImg li{
		transition: all .3s;
		cursor: pointer;
		width: 32px;
		height: 32px;
		border: 1px solid rgba(0, 0, 0, .1);
		border-radius: 2px;
		box-sizing: border-box;
		position: relative;
		margin-right: 10px;
		list-style: none;
		float: left;
	}
	.bgcolorImg img{
		width: 100%;
		height: 100%;
	}
	.getcolorformImg{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	}
	.colorcontainer1 div{
		width:100%;
		height:100%;
		position: absolute;
		left:0;
		top:0;
		border-radius: 2px;
	}
	.colorpicker input,.colorcontainer1 input{
		width: 100%;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		opacity: 0;
	}
	.colorpicker img,.colorcontainer1 img{
		width: 100%;
		height: 100%;
		border-radius: 2px;
	}
	.colorpicker{
		width:32px;
		height:32px;
		position:relative;
		border:1px solid #fff;
	}
	.purecolorlist li:hover,.colorpickActive{
		box-shadow: 0 0 3px 1px #09bd71;
	}
	.purecolorlist{
		/* width: 510px; */
	}
	.colorcontainer1:hover{
		box-shadow: 0 0 3px 1px #09bd71;
	}
	.colorcontainer{
		display: inline-flex;
		align-items: center;
		margin-right: 30px;
	}
	.colorcontainer1{
		transition: all .3s;
		cursor: pointer;
		width: 32px;
		height: 32px;
		border: 1px solid rgba(0, 0, 0, .1);
		border-radius: 2px;
		box-sizing: border-box;
		position: relative;
		margin-left: 10px;
	}
	.purecolorlist li{
		list-style:none;
		transition: all .3s;
		cursor: pointer;
		width: 32px;
		height: 32px;
		float: left;
		margin-right: 8px;
		margin-bottom: 8px;
		border: 1px solid rgba(0, 0, 0, .1);
		border-radius: 2px;
		box-sizing: border-box;
		position: relative;
	}
	.purecolorlist li div{
		width: 30px;
		height: 30px;
		position: absolute;
		left: 0px;
		top: 0px;
		border-radius: 2px;
	}
	.getimgcolor{
		width: 145px;
		height: 28px;
		border: 1px dashed rgb(226, 226, 226);
		transition: all .3s; 
		font-size: 14px;
		border-radius: 2px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position:relative;
	}
	.getimgcolor:hover{
		border: 1px dashed #09bd71;
		color: #09bd71;
	}
	.qrcode_beautify_step21_right{
		margin-left: 130px;
		position: relative;
	}
	.qrcode_beautify_step41_right{
		margin-left: 130px;
		position: relative;
	}
	.qrcode_beautify_step41_left{
		width: 100px;
		text-align: right;
		float: left;
		height: 32px;display: flex;justify-content:flex-end;align-items: center;
	}
	.qrcode_beautify_step21_left{
		width: 100px;
		text-align: right;
		float: left;
		height: 32px;display: flex;justify-content:flex-end;align-items: center;
	}
	.qrcode_beautify_step21{
		/* padding-left: 20px; */
		margin-bottom: 30px;
	}
	
	.qrcode_beautify__upu_box * {
		box-sizing: border-box;
		padding: 0;
		margin: 0;
		list-style: none;
	}
	.qrcode_beautify__upu_box {
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 999;
		background-color: rgba(0, 0, 0, 0.5);
	}
	.qrcode_beautify_container {
		width: 100%;
		min-height: 625px;
		background-color: #fff;
		border-radius: 5px;
		position: relative;
		display: flex;
		display: -webkit-flex;
	}
	.qrcode_beautify_jijs {
		position: absolute;
		right: -41px;
		top: -21px;
	}
	.qrcode_beautify_containerL {
		width: calc(100% - 400px);
		min-width: 700px;
		height: 100%;
		padding: 60px 35px 0 70px;
		/* background-color: rgb(243, 243, 243); */
		background-color: #fff;
	}
	.qrcode_beautify_containerR {
		flex: 1;
		min-height: 100%;
		background-color: rgb(243, 243, 243);
	}
	.qrcode_beautify_containerL_top::after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		clear: both;
	}
	.qrcode_beautify_containerL_top {
		margin-bottom: 30px;
	}
	.qrcode_beautify_containerL_top>div:nth-of-type(5).qrcode_beautify_containerL_top_active::after {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		border-left: 12px solid #09bd71;
		border-bottom: 17.5px solid #fff !important;
		border-top: 17.5px solid #fff !important;
		right: 0;
		top: 0;
	}
	.qrcode_beautify_containerL_top_active::after {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		border-left: 12px solid #09bd71;
		border-bottom: 17.5px solid rgb(226, 226, 226);
		border-top: 17.5px solid rgb(226, 226, 226);
		right: 0;
		top: 0;
	}
	.qrcode_beautify_containerL_top_active:before {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		border-left: 12px solid rgb(226, 226, 226);
		border-bottom: 17.5px solid #09bd71;
		border-top: 17.5px solid #09bd71;
		left: -12px;
		top: 0;
	}
	.qrcode_beautify_containerL_top>div:last-child {
		position: relative;
	}
	.qrcode_beautify_containerL_top>div:last-child::after {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		border-left: 12px solid rgb(226, 226, 226);
		border-bottom: 17.5px solid #fff;
		border-top: 17.5px solid #fff;
		right: 0;
		top: 0;
	}
	.qrcode_beautify_containerL_top_active {
		background-color: #09bd71 !important;
		color: #fff !important;
	}
	.qrcode_beautify_containerL_top1 {
		width: 20%;
		height: 34px;
		line-height: 34px;
		text-align: center;
		position: relative;
		font-size: 16px;
		background-color: rgb(226, 226, 226);
		float: left;
		cursor: pointer;
	}
	.presetLists {
		width: calc(100% + 35px);
		height: 347px;
		overflow-y: scroll;
	}
	.presetLists::-webkit-scrollbar {
		display: none;
	}
	.presetList {
		/* width: 13%;
		padding-top: 13%;
		float: left;
		margin: 0 1.5% 1.5% 0; */
		border: 1px solid rgb(226, 226, 226);
		position: relative;
		width: 100px;
		height: 100px;
		float: left;
		margin: 0 12.5px 12.5px 0;
		cursor: pointer;
		transition: all .3s;
	}
	.presetList:hover {
		border: 1px solid #09bd71;
	}
	/* .qrcode_beautify_containerL_center div:nth-child(7n) {
		margin-right: 0;
	} */
	.qrcode_beautify_containerL_top {
		width: 100%;
		overflow: hidden;
	}
	.presetList img {
		position: absolute;
		width: 92px;
		height: 92px;
		top: 3px;
		left: 3px;
	}
	.presetLists::after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		clear: both;
	}
	.presetType {
		list-style: none;
		width: 118px;
		height: 35px;
		border: 1px solid rgb(226, 226, 226);
		margin: 0 8px;
		line-height: 32px;
		text-align: center;
		cursor: pointer;
		border-radius: 3px;
	}
	.presetTypes {
		margin-top: 30px;
	}
	.presetTypes ul {
		display: flex;
		justify-content: center;
	}
	.presetTypeActive {
		background-color: #09bd71;
		color: #fff;
	}
	.presetListActive,.iconContainer11Active,.iconContainer12Active {
		border: 1px solid #09bd71 !important;
		position: relative;
	}
	.presetListActive::after,.iconContainer11Active::after,.iconContainer12Active::after {
		content: '';
		width: 30px;
		height: 30px;
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		background-image: url("/img/checked.png?t=1.0.0");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	.iconAndfont_title {
		font-size: 15px;
		color: #333;
		line-height: 15px;
		margin-bottom: 15px;
	}
	.qrcode_beautify_step1 {
		padding-left: 10px;
	}
	
	.iconContainer11,
	.iconContainer12 {
		width: 55px;
		height: 55px;
		border: 1px solid rgb(226, 226, 226);
		list-style: none;
		float: left;
		margin-right: 8px;
		margin-bottom: 8px;
		cursor: pointer;
		transition: all .3s;
		position:relative;
	}
	.iconContainer11:hover,.iconContainer12:hover {
		border: 1px solid #09bd71;
	}
	.logo-custom-jxiuhauh{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		height: fit-content;
	}
	.iconContainer11 img {
		width: 20px;
		height: 20px;
		display: block;
		object-fit: contain;
		margin: auto;
	}
	.iconContainer12 img {
		width: 40px;
		height: 40px;
		border-radius: 0px;
	}
	.iconContainer12 {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.clearfloat::after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		clear: both;
	}
	.iconContainer1 {
		/* margin-bottom: 8px; */
		padding: 0;
	}
	/* .iconContainer1>li:nth-of-type(9n) {
		margin-right: 0;
	} */
	.iconContainer11 p {
		font-size: 12px;
		text-align: center;
		line-height: 12px;
		margin-top: 7px;
		color: #999;
	}
	.iconAndfont_text {
		display: inline-flex;
		flex: 1;
	}
	.iconAndfont_text1 {
		width: calc(100% - 55px);
		max-width: 250px;
		height: 32px;
		border: 1px solid rgb(226, 226, 226);
		border-radius: 5px 0 0 5px;
		padding: 0 5px;
		outline: none;
		font-size: 14px;
	}
	.iconAndfont_confirm {
		display: inline-block;
		width: 55px;
		height: 32px;
		border: 1px solid rgb(226, 226, 226);
		border-left: 0;
		text-align: center;
		line-height: 31px;
		background-color: #fff;
		margin-right: 6px;
		border-radius: 0 5px 5px 0;
		font-size: 14px;
		cursor: pointer;
		transition: all .3s;
	}
	.iconAndfont_text_container {
		display: flex;
	}
	.iconAndfont_confirm:hover {
		background-color: #fdfdfd;
	}
	.iconAndfont_confirm:active {
		background-color: #f1f1f1;
	}
	.iconAndfontselect {
		width: 119px;
		height: 35px;
		border: 1px solid rgb(226, 226, 226);
		border-radius: 5px;
		margin-right: 5px;
		outline: none;
		background-color: #fff;
		position: relative;
		cursor: pointer;
		line-height: 32px;
		padding: 0 15px;
		font-size: 14px;
	}
	.iconAndfont_text_container select:nth-of-type(5) {
		margin-right: 0;
	}
	.iconAndfont_text_container1 {
		margin-top: 15px;
	}
	.iconDown {
		width: 13px;
		position: absolute;
		right: 6px;
		top: 11.5px;
	}
	.iconAndfontselect li {
		width: 100%;
		height: 35px;
		cursor: pointer;
		padding: 0 15px;
		line-height: 35px;
		background-color: #fff;
	}
	.iconAndfontselect ul {
		width: 100%;
		position: absolute;
		left: 0;
		top: 35px;
		display: none;
		border: 1px solid rgb(226, 226, 226);
		max-height: 140px;
		overflow-y: scroll;
		overflow-x: hidden;
	}
	.iconAndfontselect ul::-webkit-scrollbar {
		display: none;
	}
	.iconAndfontselect li:hover {
		background-color: #e2e2e2;
	}
	*{
		margin: 0;
		padding:0;
	}
	.qrcode_beautify_containerR33{
		width: 100%;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}
	.download-orther-btn-warp{
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		bottom: -36px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.download-orther-ext-jxuhs{color: rgba(0,0,0,0.65);font-size: 14px;text-align: center;width: fit-content;cursor: pointer;transition: color 0.2s;}
	.download-orther-ext-jxuhs:hover{color:#09bd71;}
	
	.saveQrcode{
		display: block;
		height: 40px;
		width: 140px;
		margin: 30px auto 0;
	}
</style>
