function operation(analyze, item) {
	switch (analyze.toString()) {
		case '14': //下载
			layer.open({
				type: 2,
				title: '',
				anim: -1,
				shift: -1,
				closeBtn: 0,
				area: ['1000px', '500px'],
				scrollbar: true,
				fixed: false, //不固定
				maxmin: false,
				content: "/qrcode/qrcodeDownload?qrcode_type=" + item.qrcode_type + "&id=" + item.qrcode_id,
			});
			break;
		case '7': //详情-设置
			window.open('/user/activecode/viewcode/' + item.qrcode_id + '?pageFrom=' + item.qrcode_type + '&gedit_id=' +
				item.gedit_id);
			break;
		case '3': //编辑
			if (typeof editLiveCode == "function") {
				editLiveCode(item.qrcode_type, item.qrcode_id, item.id_key, item.gedit_id, item.genre);
			}
			break;
		case '9': //新建活码
			if (typeof createNewLiveCode == "function") {
				createNewLiveCode();
			}
			break;
		case '2': //复制
			copy_html_2020KN_init('', {
				id: item.qrcode_id,
				code_qrcode_type: item.qrcode_type,
				title: item.title + '-副本',
				_this: $(this),
			});
			break;
		case '8': //删除
			handle_ids = [];
			if (typeof item.length == 'undefined') {
				handle_ids.push({
					id: item.qrcode_id,
					type: item.qrcode_type,
				});
			} else if (item.length > 0) {
				for (let i = 0; i < item.length; i++) {
					handle_ids.push({
						id: item[i].qrcode_id,
						type: item[i].qrcode_type,
					});
				}
			}
			if (typeof delete_html_2020KN_init == "function") {
				delete_html_2020KN_init('', $(this));
			}
			break;
		case '11': //批量下载
			var selected_data = [];
			if (typeof item.length == 'undefined') {
				selected_data.push({
					qrcode_id: item.qrcode_id,
					qrcode_type: item.qrcode_type,
				});
			} else if (item.length > 0) {
				for (let i = 0; i < item.length; i++) {
					selected_data.push({
						qrcode_id: item[i].qrcode_id,
						qrcode_type: item[i].qrcode_type,
					});
				}
			}
			var eleLink = document.createElement('a');
			eleLink.style.display = 'none';
			// eleLink.rel = "noreferrer noopener nofollow"
			// eleLink.target = "_blank"
			eleLink.href = '/qrcode/batchDownQrcodeCode?selected_data=' + JSON.stringify(selected_data);
			eleLink.click();
			// 然后移除
			eleLink.remove();
			break;
		case '20': //统计
			if (typeof showOneScanAnalysis == "function") {
				showOneScanAnalysis(item.id_key);
			}
			break;
		case '30': //查询打开方式
			if (typeof open_validity_module_init == "function") {
				open_validity_module_init('', $(this), item.qrcode_id, item.qrcode_type);
			}
			break;
		case "1000": //预览
			if (typeof handle_see__erweima == "function") {
				handle_see__erweima(item.qrcode_id, item.qrcode_type, item.preview_url, item.path);
			}
			break;
	};
}

export {
	operation
}
