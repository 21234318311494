var recentlyRecordColors=[],editColor='#333333';
module.exports = {
	handleLatestUseColorData(colorItem){
		colorItem = colorItem.toLowerCase();
		var tmpArrayData=[];
		for(var i=0;i<recentlyRecordColors.length;i++){
			if(recentlyRecordColors[i]!=colorItem){
				tmpArrayData.push(recentlyRecordColors[i]);
			}
		}
		if(colorItem){tmpArrayData.unshift(colorItem);}
		recentlyRecordColors = tmpArrayData.slice(0,9);
	},
	getLatestUseColorData(){
		return JSON.parse(JSON.stringify(recentlyRecordColors));
	},
	getEditorColor(){
		return editColor;
	},
	setEditorColor(color){
		editColor = color;
	}
}