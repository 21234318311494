<template>
	<div class="container">
		<div class="left">
			<div class="downloadTo">
				<div @click="setdownloadType(1)" :class="downloadType==1?'toComputer downloadTypeActive':'toComputer'"><DesktopOutlined style="margin-right: 5px;font-size: 17px;" />下载到电脑</div>
				<div @click="setdownloadType(2)" :class="downloadType==2?'toPhone downloadTypeActive':'toPhone'"><MobileOutlined style="margin-right: 5px;font-size: 17px;" />下载到手机</div>
			</div>
			<div class="alltypeContainer" v-if="downloadType==1">
				<div class="imgtypeContainer">
					<div @click="setdownloadImgType('png')" :class="downloadimgType=='png'?'imgtype imgtypeActive':'imgtype'">
						<p class="imgname">PNG图片</p>
						<p class="imgdesc">图片底部透明效果</p>
					</div>
					<div @click="setdownloadImgType('jpg')" :class="downloadimgType=='jpg'?'imgtype imgtypeActive':'imgtype'">
						<p class="imgname">JPG图片</p>
						<p class="imgdesc">最常用的图片格式</p>
					</div>
					<div @click="setdownloadImgType('svg')" :class="downloadimgType=='svg'?'imgtype imgtypeActive':'imgtype'">
						<p class="imgname">SVG图片</p>
						<p class="imgdesc">不支持美化样式</p>
					</div>
					<div @click="setdownloadImgType('pdf')" :class="downloadimgType=='pdf'?'imgtype imgtypeActive':'imgtype'">
						<p class="imgname">PDF图片</p>
						<p class="imgdesc">不支持美化样式</p>
					</div>
					<div @click="setdownloadImgType('eps')" :class="downloadimgType=='eps'?'imgtype imgtypeActive':'imgtype'">
						<p class="imgname">EPS矢量格式</p>
						<p class="imgdesc">不支持美化样式</p>
					</div>
					<div @click="setdownloadImgType('all')" :class="downloadimgType=='all'?'imgtype imgtypeActive':'imgtype'">
						<p class="imgname">打包下载</p>
						<p class="imgdesc">包含所有尺寸和格式</p>
					</div>
				</div>
				<div class="imgsizeContainer">
					<span style="margin-right: 10px;">&nbsp;&nbsp;&nbsp;&nbsp;分辨率：</span>
					<a-select v-model:value="imgSize" style="width: 150px" @change="changeSize">
						<template #suffixIcon>
							<Icon>
								<template #component>
									<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10">
										<path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path>
									</svg>
								</template>
						    </Icon>
						</template>
						<a-select-option value="300">300*300</a-select-option>
						<a-select-option value="400">400*400</a-select-option>
						<a-select-option value="500">500*500</a-select-option>
						<a-select-option value="1000">1000*1000</a-select-option>
						<a-select-option value="-1">自定义</a-select-option>
					</a-select>
					<a-input-number :min="200" :max="2000" v-if="imgSize==-1" @change="changeSize" v-model:value="customize" placeholder="请输入自定义尺寸" style="width: 150px;margin-left: 10px;" />
				</div>
				<div class="imgnameContainer">
					<span style="margin-right: 10px;">图片名称：</span>
					<a-input size="default" class="img--title-nameji" @change="setCustomize" v-model:value="qrcodeDownloadData.name" placeholder="请输入图片名称" style="width: 310px;" />
				</div>
				<div class="btnContainer">
					<a-button size="large" :loading="createLoad" @click="downloadQrcodeImg" style="width: 120px;margin: 0 10px;" shape="round" type="primary">确认下载</a-button>
					<a-button size="large"  @click="closeModal" style="width: 120px;margin: 0 10px;" shape="round">关闭</a-button>
				</div>
			</div>
			<div v-else class="wxDownload">
				<div class="downloadQrcodeContainer">
					<div class="downloadQrcodeContainer__jixja2" v-if="createLoad">
						<a-spin class="spin-main-color"/>
					</div>
					<img v-else :src="phoneCodeImg" alt="">
					<p>请用手机浏览器扫一扫获取图片</p>
					<a-button size="large" @click="closeModal()" style="width: 120px;margin: 0 10px;" shape="round">关闭</a-button>
				</div>
			</div>
		</div>	
		<div class="right">
			<div class="rightTop"><EyeOutlined style="margin-right: 5px;font-size: 17px;" />图片预览</div>
			<div class="imgContainer">
				<div class="imgContainerIhsu">
					<canvas class="imgContainerItemIhsu" id="canvas__kjxjia10x52as4"></canvas>
					<!-- <img class="imgContainerItemIhsu" :src="qrcodeDownloadData.base64" alt=""> -->
				</div>
				<!-- <p style="width: 100%;text-align: center;margin-top: 5px;">{{qrcodeDownloadData.name}}</p> -->
			</div>
		</div>
	</div>
</template>

<script>
	import { EyeOutlined,DesktopOutlined,MobileOutlined } from '@ant-design/icons-vue';
	import baseFunc from '../config/base_func.js';
	import apiAxios from '../config/request.js';
	import Icon from '@ant-design/icons-vue';
	export default{
		name: 'downloadImg',
		components:{
			EyeOutlined,DesktopOutlined,MobileOutlined,Icon
		},
		props:{
			qrcodeInfo:[String,Object]
		},
		data(){
			return{
				qrcodeDownloadData:JSON.parse(JSON.stringify(this.qrcodeInfo)),
				downloadType:1,//1下载到电脑2下载到手机
				downloadimgType:'png',//1png，2jpg，3svg，4pdf，5eps，6打包
				imgSize:'-1',
				size:400,
				customize:null,//自定义尺寸
				qrcodeBeautifyFunc:null,
				createLoad:false,
				phoneCodeImg:"",
			}
		},
		created() {
		},
		mounted() {
			this.beautifyQrcodeFunc()
			
		},
		methods:{
			//下载到哪里
			setdownloadType(e){
				if(this.createLoad)return false;
				this.downloadType=e
				if(parseInt(e)==2){
					/*下载到手机*/
					this.startDownloadQrcodeImg();
				}
			},
			changeSize(size){
				size = parseInt(size);
				if(size<200)size = 200;
				if(size>2000)size = 2000;
				this.size = size;
			},
			//设置图片类型
			setdownloadImgType(e){
				this.downloadimgType=e;
			},
			downloadQrcodeImg(){
				this.startDownloadQrcodeImg()
			},
			startDownloadQrcodeImg(status){
				var level = "L";
				var size = 400;
				this.createLoad = true;
				if(this.qrcodeDownloadData.config && typeof this.qrcodeDownloadData.config.level != 'undefined' && this.qrcodeDownloadData.config.level)level = this.qrcodeDownloadData.config.level
				if(this.qrcodeDownloadData.config && typeof this.qrcodeDownloadData.config.bgwidth != 'undefined' && this.qrcodeDownloadData.config.bgwidth)size = this.qrcodeDownloadData.config.bgwidth
				if(status!=1 && size!=this.size){
					if(this.qrcodeBeautifyFunc){
						this.qrcodeBeautifyFunc.changeWidth(this.size)
						return false;
					}
				}
				apiAxios.post('/attach/saveBeautifyQRcode',{
					base64:this.qrcodeDownloadData.base64,
					format:this.downloadimgType,
					content:this.qrcodeDownloadData.content,
					size:this.size,
					level:level,
					filename:this.qrcodeDownloadData.name,
				}).then(res=>{
					this.createLoad = false;
					this.phoneCodeImg = res.data.phone_code;
					if(parseInt(this.downloadType)==1){
						window.location.href= res.data.data
					}
				}).catch(err => {
					this.createLoad = false;
				});
			},
			closeModal(){
				this.$emit('closedownloadimg')
			},
			beautifyQrcodeFunc(){/*二维码美化*/
				const _this = this
				if(this.qrcodeDownloadData.config && this.qrcodeDownloadData.config.background && !baseFunc.isBase64(this.qrcodeDownloadData.config.background))this.qrcodeDownloadData.config.background=baseFunc.getAliOssAuthKeyLocal(this.qrcodeDownloadData.config.background)
				if(this.qrcodeDownloadData.config && this.qrcodeDownloadData.config.foreground && !baseFunc.isBase64(this.qrcodeDownloadData.config.foreground))this.qrcodeDownloadData.config.foreground=baseFunc.getAliOssAuthKeyLocal(this.qrcodeDownloadData.config.foreground)
				if(this.qrcodeDownloadData.config && this.qrcodeDownloadData.config.logoimg && !baseFunc.isBase64(this.qrcodeDownloadData.config.logoimg))this.qrcodeDownloadData.config.logoimg=baseFunc.getAliOssAuthKeyLocal(this.qrcodeDownloadData.config.logoimg)
				if(this.qrcodeDownloadData.config)this.qrcodeDownloadData.config.text = this.qrcodeDownloadData.content;
				if(this.qrcodeDownloadData.config){
					if([300,400,500,1000].includes(this.qrcodeDownloadData.config.bgwidth)){
						this.imgSize = this.qrcodeDownloadData.config.bgwidth+""
						this.size = this.qrcodeDownloadData.config.bgwidth;
					}else{
						this.imgSize = "-1";
						this.customize = this.qrcodeDownloadData.config.bgwidth;
						this.size = this.qrcodeDownloadData.config.bgwidth;
					}
				}
				this.qrcodeBeautifyFunc = new qrcodeBeautifyFuncPackage({
					info:this.qrcodeDownloadData.config,
					canvas_id:"canvas__kjxjia10x52as4",
					success:function (res) {
						_this.qrcodeDownloadData.base64 = res.base64
						if(_this.createLoad){
							_this.startDownloadQrcodeImg(1);
						}
					},error:function (err) {
					}
				});
			},
		}
	}
</script>
<style>
	.downloadImgModal .ant-modal-body{
		padding: 0;
	}
	.spin-main-color .ant-spin-dot-item{background-color:#09bd71;}
</style>
<style scoped>
	.downloadQrcodeContainer p{
		width: 100%;
		text-align: center;
		color: #888888;
		margin-top: 20px;
		margin-bottom: 25px;
	}
	.downloadQrcodeContainer img{
		width: 200px;
		height: 200px;
		object-fit: contain;
	}
	.downloadQrcodeContainer .downloadQrcodeContainer__jixja2{
		width: 200px;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}
	.downloadQrcodeContainer{
		display:flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.wxDownload{
		padding-top: 40px;
	}
	.imgContainer{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 20px 40px;
		flex-wrap: wrap;
		height: 450px;
		border-left: 1px solid #eeeeee;
	}
	.imgContainer .imgContainerIhsu{
		height:100%;
		width: 100%;
		position: relative;
	}
	.imgContainer .imgContainerItemIhsu{
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}
	.rightTop{
		height: 50px;
		border-bottom: 2px solid #eeeeee;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.alltypeContainer{
		border-right: 1px solid #eeeeee;
		height: 450px;
	}
	.btnContainer{
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}
	.imgnameContainer{
		margin-top: 15px;
	}
	.imgsizeContainer,.imgnameContainer{
		padding: 0 25px;
	}
	.imgnameContainer .img--title-nameji{
		padding: 4px 11px;
	}
	.imgsizeContainer{
		margin-top: 5px;
	}
	.imgdesc{
		width: 100%;
		font-size: 12px;
		color: #888888;
	}
	.imgname{
		width: 100%;
		font-size: 16px;
		margin-bottom: 4px;
	}
	.imgtypeActive p{
		color: #ffffff;
	}
	.imgtypeActive{
		background-color: #09bd71 !important;
		border: 1px solid #09bd71 !important;
	}
	.downloadTypeActive{
		color:#09bd71;
		border-bottom: 2px solid #09bd71 !important;
	}
	.toComputer:hover,.toPhone:hover{
		color: #09bd71;
	}
	.toComputer,.toPhone{
		width: 50%;
		border-bottom: 2px solid #eeeeee;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
	}
	.downloadTo{
		display: flex;
	}
	.imgtypeContainer{
		display: flex;
		flex-wrap: wrap;justify-content: space-between;
		padding: 15px 25px 0;
	}
	.imgtype:hover{
		border: 1px solid #09bd71;
	}
	.imgtype{
		width: 215px;
		height: 75px;
		background-color: #f5f5f5;
		margin-bottom: 15px;
		cursor:pointer;
		border-radius: 5px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		text-align: center;
		border: 1px solid #f5f5f5;
	}
	.right{
		width: 50%;
		height: 500px;
		float: right;
		border-right: 1px solid #eeeeee;
	}
	.left{
		width: 50%;
		height: 500px;
		float: left;
	}
	.container{
		width: 1000px;
		height: 500px;
	}
	*{
		margin: 0;
		padding: 0;
	}
</style>