<template>
	<div class="preview-warp" :class="visible?'show':''">
		<div class="preview-warp__main">
			<div class="preview-warp__main__container" v-if="visible">
				<div class="preview-warp__main__container__code">
					<div class="preview-warp__main__container__code__box">
						<div  class="preview-warp__main__container__code__box__img"><img width="152" :src="info.qrcode_path?info.qrcode_path:''" /></div>
					</div>
					<span class="preview-warp__main__container__code__text">扫码预览</span>
				</div>
				<div class="preview-warp__main__container__content">
					<div class="preview-warp__main__container__content__img"><img src="/qrcode/image/top_black2@2x.png"></div>
					<iframe class="preview-warp__main__container__content__iframe" :src="info.qrcode_url?info.qrcode_url:'about:blank'"></iframe>
				</div>
			</div>
		</div>
		<div class="preview-warp__close" @click="close">
			<svg viewBox="0 0 48 48"><path d="M28 24L47 5a3 3 0 1 0-4-4L24 20 5 1a3 3 0 1 0-4 4l19 19L1 43a3 3 0 1 0 4 4l19-19 19 19a3 3 0 0 0 4 0v-4L28 24z"></path></svg>
		</div>
	</div>
</template>

<script>
export default {
		name:'previewCode',
		props:{
			info:Object,
		},
		created(){
		},
		mounted(){
		},
		data(){
			return {
				visible:false,
			};
		},
		methods:{
			open(){
				this.visible=true;
				this.keyDownListener() // 监听键盘
			},
			close(){
				this.$emit("close",{})
				this.visible=false;
				document.onkeydown = null
			},
			keyDownListener() {
				const that = this;
				document.onkeydown =  (e) => {
					//事件对象兼容
					let e1 = e || event || window.event || arguments.callee.caller.arguments[0];
					if(e1 && e1.keyCode == 27)that.close();//esc-关闭
					e.stopPropagation();
					return false;
				}
			}
		},
		watch:{}
	}
	
</script>

<style scoped lang="less">
	.preview-warp{
		bottom: 0;
		left: 0;
		right: 0;
		position: fixed;
		opacity: 0;
		top: 0;
		z-index: 2000;
		background: rgba(0,0,0,.5);
		-webkit-transition: opacity .2s;
		-o-transition: opacity .2s;
		transition: opacity .2s;
		pointer-events: none;
		&__main{
			position: absolute;
			overflow: hidden;
			left: 0;
			right: 0;
			margin: 0 auto;
			top: 0;
			z-index: 10;
			min-height: 100vh;
			padding-top: 30px;
			padding-bottom: 100px;
			width: 334px;
			&__container{
				display: table-cell;
				vertical-align: middle;
				&__code{
					position: fixed;
					left: 50%;
					margin-left: -378px;
					padding: 20px;
					background: #fff;
					display: -ms-flexbox;
					display: flex;
					-ms-flex-direction: column;
					flex-direction: column;
					-ms-flex-align: center;
					align-items: center;
					&__box{
						position: relative;
						width: 152px;
						&__img{
							width: 152px;
							min-height: 152px;
						}
					}
					&__text{
						font-size: 16px;
						font-family: PingFangSC-Regular,sans-serif;
						line-height: 22px;
						margin-top: 10px;
						color: #2e2e2e;
					}
				}
				&__content{
					width: 334px;
					border-radius: 40px;
					overflow: hidden;
					box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
					position: relative;
					background: white;
					&__img{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						img{width: 100%;}
					}
					&__iframe{
						border: none;
						height: 800px;
						width: 320px;
						border-radius: 33px;
						margin: 7px 0 0 7px;
					}
				}
			}
		}
		&__close{
			position: absolute;
			border: 0;
			outline: 0;
			background: 0;
			cursor: pointer;
			padding: 0;
			height: 100px;
			width: 100px;
			border-radius: 100%;
			top: 8px;
			right: 14px;
			opacity: 1;
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			color:#ffffff;
			svg{
				height: 20px;
				width: 20px;
				fill: #ffffff;
				transition: all .1s;
				vertical-align: top;
			}
		}
	}
	.preview-warp.show{
	    opacity: 1;
	    pointer-events: auto;
	}
</style>