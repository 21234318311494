<template>
	<a-config-provider :locale="lang">
		<div class="mbm_jay_comtentStyle">
			<div class="mbm_jay_contentFlex">
				<span class="mbm_jay_statis">收藏数据统计</span>
			</div>
			<div class="mbm_jay_contentFlex mbm_jay_statisticsData">
				<div class="mbm_jay_marginStyle" v-for="(items,ids) in menu.titleMenu">
					<div class="mbm_jay_marginStyleDiv">
						<p class="mbm_jay_marginStyleDivFirstP">{{items.title}}</p>
						<p class="mbm_jay_marginStyleDivSecendP">
							<text v-if="items.number>=0">{{items.number}}</text>
							<a-spin v-else size="small" />
						</p>
					</div>
				</div>
			</div>
			<div class="mbm_jay_dataComtent">
				<div class="mbm_jay_dataComtentDiv">
					<div>
						<div class="mbm_jay_contentFlex">
							<span class="mbm_dataAlwes">日期范围：</span>
						</div>
					</div>
					<div v-for="(item,idx) in menu.list" class="mbm_jay_clickMenu">
						<div :class="idx==menu.index?'mbm_jay_active':''" class="mbm_jay_menu" @click="isActive(idx)">
							<span class="mbm_jay_sameSize" :style="idx==menu.index?'color:#09bd71':''">{{item.title}}</span>
						</div>
					</div>
					<!-- <div v-if="menu.index==4" class="mbm_jay_defult_time">
						<a-range-picker format="YYYY/MM/DD" :disabled-date="disabledDate" @change="onChange"/>
					</div> -->
					<div v-if="false" class="mbm_jay_scanning">
						<div class="mbm_jay_scanningDiv">
							<span class="mbm_jay_scanningDivSpan">扫描来源：</span>
						</div>
						<div class="mbm_jay_dropdown">
							<a-select
								  ref="select"
								  v-model:value="scenes.scenesIndex"
								  style="width: 120px"
								  @change="handleMenuClick"
								>
								  <a-select-option v-for="(item,index) in scenes.list" :value="index">{{item.title}}</a-select-option>
								</a-select>
						</div>
					</div>
				</div>
				<div id="myChart" class="mbm_jay_chart_warp">
					<div>
						<div class="mbm_jay_chart" ref="chart_2"></div>
						<div v-if="loading" class="mbm_jay_loading"><a-spin size="large" /></div>
					</div>
				</div>
			</div>
		</div>
	</a-config-provider>

</template>

<script>
	import * as echarts from 'echarts';
	import moment from 'moment';
	import zhCN from 'ant-design-vue/es/locale/zh_CN';
	export default {
		name: 'statisticModel',
		props: {
			info: Object,
		},
		data() {
			return {
				lang:zhCN,
				loading:true,
				chart: null,
				//扫码来源
				scenes:{
					scenesIndex:0,//默认选中
					list:[
						{
							id:0,
							title:'全部来源',
						}
					]
				},
				menu:{
					index:1,//默认选中
					titleMenu:[
						{
							title:'昨日',
							number:-1,
						},
						{
							title:'今日',
							number:-1,
						},
						{
							title:'近7日',
							number:-1,
						},
						{
							title:'近30日',
							number:-1,
						},
						{
							title:'累计扫描次数',
							number:-1,
						},
					],//扫码量统计
					list: [
						{
							title: "昨日",
							parameter: 'yesterday',//参数
						},
						{
							title: "今日",
							parameter: 'today',//参数
						},
						{
							title: "近7日",
							parameter: 'collect_7day',//参数
						},
						{
							title: "近30日",
							parameter: 'collect_30day',//参数
						},
						// {
						// 	title: "自定义",
						// 	parameter: '',//参数
						// }
					]
				},
			}
		},
		mounted() {
			// //初始化获取全局扫码量
			this.getStatisticsInfo();
			// //初始化获取全局扫码量图表
			this.getDetailChartInfo();
			const that = this
			window.addEventListener("resize", () => {
				try{
					if(that.chart_2)that.chart_2.resize();
				}catch (e) {}
				
			}); //让图表实现响应式布局
		},
		watch:{
			info:{
			    handler(newVal,oldVal){
					if(newVal!=oldVal){
						// //初始化获取全局扫码量
						this.getStatisticsInfo();
						// //初始化获取全局扫码量图表
						this.getDetailChartInfo();
					}
			    },
			    deep:true// 深度监听父组件传过来对象变化
			}
		},
		methods: {
			disabledDate(current) {
			    return current && current > moment().endOf('day');
			},
			//获取全局扫码量统计
			getStatisticsInfo() {
				const that = this
				var postStr='';
				that.scenes.list=[{id:0,title:'全部来源'}];
				this.$apiRequest.post("/api/collect/getQrCodeNumberStatistics", {
					id_key:that.info.id_key,
				}).then(res => {
					that.menu.titleMenu=[
						{
							title:'昨日',
							number:res.data.data.collect_yesterday,
						},
						{
							title:'今日',
							number:res.data.data.collect_today,
						},
						{
							title:'近7日',
							number:res.data.data.collect_7day,
						},
						{
							title:'近30日',
							number:res.data.data.collect_30day,
						},
						{
							title:'累计收藏量',
							number:res.data.data.total,
						},
					];
					// for(var i in res.data.data.scenes){
					// 	that.scenes.list.push({
					// 		id:i,
					// 		title:res.data.data.scenes[i]
					// 	})
					// }
				}).catch(err => {
				});
			},
			//获取全局扫码量图表
			getDetailChartInfo:function(){
				const that = this
				var postStr='';
				this.loading=true;
				this.$apiRequest.post("/api/collect/getCollectionQrCodeStatistics", {
					id_key:that.info.id_key,
					type:this.menu.list[this.menu.index].parameter
				}).then(res => {
					var p=0;
					var TimeArr=[];
					var CollecteArr=[];
					for(let i  in res.data.data){
					  TimeArr[p]=i; //时间段
					  CollecteArr[p]=res.data.data[i] //扫描人数
					  p++;
					}
					this.init(TimeArr,CollecteArr);
					this.loading=false;
				}).catch(err => {
				});
			},
			//初始化折线图
			init(TimeArr,CollecteArr) {
				var that =this;
				//2.初始化
				this.chart_2 = echarts.init(this.$refs.chart_2);
				//3.配置数据
				let option2 = {
					title: {
						text: "",
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					legend: {
						show: true,
						top: "8%",
						data: ["收藏数量"],
					},
					grid: {
						left: "3%",
						right: "4%",
						bottom: "3%",
						containLabel: true,
					},
					// toolbox: {//保存图片
					// 	feature: {
					// 		saveAsImage: {},
					// 	},
					// },
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: TimeArr,
					},
					yAxis: {
						type: "value",
					},
					series: [
						{
							name: '收藏数量',
							type: 'line',
							stack: 'Total',
							areaStyle: {
								color: '#e6ecfe',
							},
							emphasis: {
								focus: 'series'
							},
							data: CollecteArr,
						},
					],
				};
				// 4.传入数据
				this.chart_2.setOption(option2);
			},
			//切换日期
			isActive: function(idx) {
				var that = this;	
				that.menu.index = idx;
				if(Number(idx) != 4){
					this.getDetailChartInfo();
				}
			},
			//获取自定义日期
			onChange(date,dateString) {
				this.menu.list[this.menu.index].parameter=dateString[0]+'-'+dateString[1];
				this.getDetailChartInfo();
			},
			//获取来源
			handleMenuClick:function(index){
				this.getDetailChartInfo();
			}
		}
	}
</script>

<style scoped>
	.mbm_jay_marginStyleDivFirstP{
		font-size: 14px;color: #999999;margin-bottom: 0;
	}
	.mbm_jay_marginStyleDivSecendP{
		font-size: 24px;color: #333333;margin-bottom: 0;
	}
	.mbm_jay_marginStyle{
		flex: 1;
		padding-left: 20px;
		border-left: 1px solid #e7e7e7;
		box-sizing: border-box;
		display: flex;
		justify-content: flex-start;
	}
	.mbm_jay_statisticsData .mbm_jay_marginStyle:first-child{
		padding-left: 0;
		border-left: 0;
	}
	.mbm_jay_statis{
		margin-bottom: 35px;color: #000000;font-size: 18px;font-weight: bold;
	}
	.mbm_jay_contentFlex{
		display: flex;
	}
	.mbm_jay_dataComtentDiv{
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
	}
	.mbm_jay_dataComtentDiv>div{margin-bottom: 10px;}
	.mbm_jay_dataComtent{
		padding-top: 68px;
	}	
	.mbm_dataAlwes{
		margin-top: 5px;font-size: 14px;color: #999999;
	}
	.mbm_jay_dropdown{
		padding-left: 5px;
	}
	.mbm_jay_scanningDivSpan{
		font-size: 14px;color: #999999;margin-top: 5px;
	}
	.mbm_jay_defult_time{
		padding: 0 10px;
		width: 230px;
		box-sizing: border-box;
	}
	.mbm_jay_scanning{
		display: flex;
	}
	.mbm_jay_scanningDiv{
		display: flex;
	}
	.mbm_jay_selectStyle{
		border-radius: 4px!important;
		display: flex!important;
		width: 110px;
	}
	.mbm_jay_selectStyle_span{
		color: #333333;margin-left: -4px;
	}
	.mbm_jay_selectStyle_outl{
		margin-left: 6px;
	}
	.mbm_jay_chart{
		width: 100%; height: 300px;
	}
	.mbm_jay_chart_warp{
		position: relative;
	}
	.mbm_jay_comtentStyle {
		-webkit-touch-callout: auto;
		-webkit-user-select: auto;
		-khtml-user-select: auto;
		-moz-user-select: auto;
		-ms-user-select: auto;
		user-select: auto;
	}

	.mbm_jay_active {
		border-radius: 5px;
		border: 1px solid #09bd71 !important;
	}

	.mbm_jay_menu {
		border-radius: 5px;
		display: flex;
		cursor: pointer;
		border: 1px solid rgba(0, 0, 0, 0);
	}

	.ant-table-thead>tr>th {
		background: #f5f5f5 !important;
		padding: 5px 16px !important;
	}

	.ant-table-tbody>tr>td {
		padding: 10px 16px !important;
	}

	.mbm_jay_sameSize {
		margin: auto;
		font-size: 14px;
		color: #333333;
		padding: 4px 12px;
	}
	.mbm_jay_sameSize:hover{
		color: #09bd71;
	}
	.mbm_jay_loading {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(255,255,255,0.5);
		z-index: 10;
		left: 0;
		top: 0;
	}
</style>
