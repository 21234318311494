class Download {
    //对外暴露下载接口。
    downloadFile(content,fileName) {
		if(!content)return false;
		if(!fileName){
			fileName = (new Date()).Format("yyyyMMddhhmmss") + "_wwei_cn."+this.getBase64Ext(content);
		}
        let aLink = document.createElement('a');
        let blob = this.base64ToBlob(content); //new Blob([content]);

        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        aLink.download = fileName;
        aLink.href = URL.createObjectURL(blob);
        aLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));//兼容火狐
    }
 
    base64ToBlob(dataContent) {
		var arr = dataContent.split(","),mime = arr[0].match(/:(.*?);/)[1],raw = window.atob(arr[1]);
        let rawLength = raw.length;
        let uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: mime });
    }
	getBase64Ext(str){
		try{
			if(this.isBase64(str)){
				return str.split(';base64,')[0].split(':')[1].split('/')[1].toLowerCase();
			}else{
				var str1 = str.split('?')[0];
				var index1=str1.lastIndexOf(".");
				var index2=str1.length;
				return str1.substring(index1+1,index2).toLowerCase();
			}
		}catch(e){
			return "png";
		}
	}
	isBase64(str) {
	    if(str.indexOf('data:')!=-1 && str.indexOf('base64')!=-1 ){
	        return true;
	    }else{
	        return false;
	    }
	}
}
Date.prototype.Format = function (fmt) { //author: meizz
	var o = {
		"M+": this.getMonth() + 1, //月份
		"d+": this.getDate(), //日
		"h+": this.getHours(), //小时
		"m+": this.getMinutes(), //分
		"s+": this.getSeconds(), //秒
		"q+": Math.floor((this.getMonth() + 3) / 3), //季度
		"S": this.getMilliseconds() //毫秒
	};
	if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
	return fmt;
}
export {Download};