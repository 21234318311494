<template>
	<div v-for="item in menus" :key="item.id">
		<div class="ww-menu-box" :class="[currentId==item.id||moveId==item.id?'ww-menu-box-active':'']"
			@click="selectChlid(item)">
			<span v-if="item.child && item.child.length>0" 
				class="mbm-iconfont mbm-daosanjiao ico-item"
				:class="[item.openStatus?'ico-open':'ico-close']"
				@click.stop="chlidFunc(item.child.length,item)"></span>

			<div class="ww-m-list">
				<div class="ww-m-list-title">
					<span v-if="!checkable" class="mbm-iconfont ico-item" :class="[item.openStatus?'mbm-muludakai':'mbm-muluguanbi']"></span>
					<template v-else>
						<label class="ant-checkbox-wrapper" :class="[item.checked?'ant-checkbox-wrapper-checked':'']"><span class="ant-checkbox" :class="[item.checked?'ant-checkbox-checked':'']"><span class="ant-checkbox-inner"></span></span></label>
					</template>
					<div class="ww-m-list-title-i">{{item.title}}</div>
				</div>
				<span class="ww-m-list-count">{{item.total}}</span>
			</div>
		</div>
		<transition v-if="item.child" name="panel-fade" 
			@before-enter="tranInfo.beforeEnter" 
			@enter="tranInfo.enter($event,item.child.length)"
			@after-enter="tranInfo.afterEnter" 
			@before-leave="tranInfo.beforeLeave($event,item.child.length)" 
			@leave="tranInfo.leave"
			@after-leave="tranInfo.afterLeave">
			<div class="menu-child-wrap" v-show="item.openStatus" transition="panel-fade">
				<codeMenu :checkable="checkable" :menus="item.child" :currentId="currentId"  :moveId="moveId"></codeMenu>
			</div>
		</transition>
	</div>
</template>

<script>
	import codeMenu from '/src/components/qrcode/codeMenu.vue';
	import bus from '/src/assets/js/bus.js';
	export default {
		name: 'CodeMenu',
		props: {
			menus:{
				type:Object,
				default:()=>{
					return [];
				},
			},
			currentId:{
				type:Number,
				default:0,
			},
			moveId:{
				type:Number,
				default:-1,
			},
			title:{
				type:String,
				default:"",
			},
			checkable:{/*是否展示多选*/
				type:Boolean,
				default:false,
			}
		},
		components: {
			codeMenu
		},
		mounted() {},
		created() {
		},
		data() {
			return {
				tranInfo:{
					elTransition: '0.2s height ease-in-out, 0.2s padding-top ease-in-out, 0.2s padding-bottom ease-in-out',
					beforeEnter:(el)=>{
						el.style.transition = this.tranInfo.elTransition
						if (!el.dataset) el.dataset = {}
						el.dataset.oldPaddingTop = el.style.paddingTop
						el.dataset.oldPaddingBottom = el.style.paddingBottom
						el.style.height = 0
						el.style.paddingTop = 0
						el.style.paddingBottom = 0
					},
					enter:(el,length)=>{
						el.dataset.oldOverflow = el.style.overflow
						if (el.scrollHeight !== 0) {
							el.style.height = length * 36 + 'px'
							el.style.paddingTop = el.dataset.oldPaddingTop
							el.style.paddingBottom = el.dataset.oldPaddingBottom
						} else {
							el.style.height = ''
							el.style.paddingTop = el.dataset.oldPaddingTop
							el.style.paddingBottom = el.dataset.oldPaddingBottom
						}
						el.style.overflow = 'hidden'
					},
					afterEnter:(el)=>{
						el.style.transition = ''
						el.style.height = ''
						el.style.overflow = el.dataset.oldOverflow
					},
					beforeLeave:(el,length)=>{
						if (!el.dataset) el.dataset = {}
						el.dataset.oldPaddingTop = el.style.paddingTop
						el.dataset.oldPaddingBottom = el.style.paddingBottom
						el.dataset.oldOverflow = el.style.overflow
						el.style.height = length * 36 + 'px'
						el.style.overflow = 'hidden'
					},
					leave:(el)=>{
						if (el.scrollHeight !== 0) {
							el.style.transition = this.tranInfo.elTransition;
							el.style.height = 0;
							el.style.paddingTop = 0;
							el.style.paddingBottom = 0;
						}
					},
					afterLeave:(el)=>{
						el.style.transition = ''
						el.style.height = ''
						el.style.overflow = el.dataset.oldOverflow
						el.style.paddingTop = el.dataset.oldPaddingTop
						el.style.paddingBottom = el.dataset.oldPaddingBottom
					},
				}
			}
		},
		methods: {
			chlidFunc(total,item){
				if (total > 0) {item.openStatus = !item.openStatus}
			},
			selectChlid(item){
				item.openStatus = true;
				//选中目录改变
				if (this.currentId != item.id) {
					if(this.checkable){
						if(item.checked){item.checked = false;}else{item.checked = true;}
					}
					bus.$emit('catalogMenuChange', item);
				}
			},
		},
	}
</script>
<style lang="less" scoped>
	.ww-menu-box{
		padding: 0 16px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 14px;
		color: #333333;
		height: 36px;
		cursor: pointer;
		border-radius: 2px;
		min-width: 104px;
		&:hover{
			color: #09bd71;
		}
		&.ww-menu-box-active{
			background: #d0f7e6;
		}
		.ico-item{
			font-size: 10px;
			color: #333;
			margin-right: 4px;
			z-index: 2;
			&.ico-open{
				transform: rotate(0deg);
			}
			&.ico-close{
				transform: rotate(270deg);
			}
		}
		.ww-m-list{
			position: relative;
			flex: 1;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.ww-m-list-title{
				box-sizing: border-box;
				white-space: nowrap;
				-ms-flex: 1 1;
				-webkit-box-flex: 1;
				flex: 1 1;
				-webkit-line-clamp: 1;
				font-size: 14px;
				margin-right: 16px;
				z-index: 2;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				height: 100%;
				.ico-item{
					font-size: 10px;
   					color: #09bd71;
				}
				.ww-m-list-title-i{
					max-width: 120px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-left: 4px;
				}
			}
		}
	}
	.menu-child-wrap{
		padding-left: 20px;
	}
</style>
